/*
 * Created by Paul Engelke on 13 August 2021.
 */

import {DesktopWindowsOutlined as SetupIcon} from "@material-ui/icons";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality related to point of sale management and other operational
 * configuration.
 */
const SetupNode = ({goTo, t}) => ({
  id: "Setup",
  label: t("core:Term.Setup"),
  quickLink: {IconComponent: SetupIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [

    {
      id: RouteNames.RegisteredDevicePage,
      label: t("core:Term.Devices"),
      onClick: goTo(RouteNames.RegisteredDevicePage),
      security: {rights: [UserRight.ManageDevices]},
    },

    {
      id: RouteNames.PrinterPage,
      label: t("core:Term.PrintersAndAccessories"),
      onClick: goTo(RouteNames.PrinterPage),
      security: {rights: [UserRight.ManagePrinters]},
    },

    {
      id: RouteNames.ShiftPage,
      label: t("core:Term.Shifts"),
      onClick: goTo(RouteNames.ShiftPage),
      security: {rights: [UserRight.ManageShifts]},
    },

    {
      id: RouteNames.ProductGroupPage,
      label: t("core:Term.Groups"),
      onClick: goTo(RouteNames.ProductGroupPage),
      security: {rights: [UserRight.ManageGroups]},
    },

    {
      id: RouteNames.ProductCategoryPage,
      label: t("core:Term.Categories"),
      onClick: goTo(RouteNames.ProductCategoryPage),
      security: {rights: [UserRight.ManageCategories]},
    },

    {
      id: RouteNames.ProductPage,
      label: t("core:Term.Products"),
      onClick: goTo(RouteNames.ProductPage),
      security: {rights: [UserRight.ManageProducts]},
    },

    {
      id: RouteNames.ProductOptionMenuPage,
      label: t("core:Term.OptionMenus"),
      onClick: goTo(RouteNames.ProductOptionMenuPage),
      security: {rights: [UserRight.ManageOptionMenus]},
    },

    {
      id: RouteNames.CustomMenuPage,
      label: t("core:Term.CustomMenus"),
      onClick: goTo(RouteNames.CustomMenuPage),
      security: {rights: [UserRight.ManageCustomMenus]},
    },

    {
      id: RouteNames.SetMenuPage,
      label: t("core:Term.SetMenus"),
      onClick: goTo(RouteNames.SetMenuPage),
      security: {rights: [UserRight.ManageSetMenus]},
    },

    {
      id: RouteNames.DiscountPage,
      label: t("core:Term.Discounts"),
      onClick: goTo(RouteNames.DiscountPage),
      security: {rights: [UserRight.ManageDiscounts]},
    },

    {
      id: RouteNames.CampaignPage,
      label: t("core:Term.Campaigns"),
      onClick: goTo(RouteNames.CampaignPage),
      security: {rights: [UserRight.ManageCampaigns]},
    },

    {
      id: RouteNames.StockCostCentrePage,
      label: `${t("core:Term.CostCentres")} (${t("core:Term.Stock")})`,
      onClick: goTo(RouteNames.StockCostCentrePage),
      security: {rights: [UserRight.ManageStockCostCentres]},
    },

    {
      id: RouteNames.ReasonPage,
      label: t("core:Term.Transfer&VoidReasons"),
      onClick: goTo(RouteNames.ReasonPage),
      security: {rights: [UserRight.ManageReasons]},
    },

    {
      id: RouteNames.StockScalePage,
      label: t("core:Term.StockScales"),
      onClick: goTo(RouteNames.StockScalePage),
      security: {rights: [UserRight.ManageStockScales]},
    },

    {
      id: RouteNames.BarcodePage,
      label: t("glossary:Barcodes"),
      onClick: goTo(RouteNames.BarcodePage),
      security: {rights: [UserRight.ManageBarcodes]},
    },

  ],
});

export default SetupNode;
