/*
 * Created by Paul Engelke on 14 April 2021.
 */

import {
  CoreDate,
  DateFormat,
  Dates,
  NumberFormat,
  Numbers
} from "@hti-ui/js-core";
import BigNumber from "bignumber.js";
import NumberFormats from "../../constants/numberFormats";

/**
 * A utility for common data formatting in documents.
 */
export default class DataFormatter {

  /**
   * A formatter for decimal values.
   * @param {*} v The value to format.
   * @return {string}
   */
  static formatDecimal(v) {
    return v == null
        ? ''
        : Numbers.format(
            BigNumber.isBigNumber(v) ? v.toNumber() : v,
            NumberFormat.DecimalWithThousandSeparator);
  }

  /**
   * A formatter for decimal values.
   * @param {*} v The value to format.
   * @return {string}
   */
  static formatStockDecimal(v) {
    return v == null
        ? ''
        : Numbers.format(
            BigNumber.isBigNumber(v) ? v.toNumber() : v,
            NumberFormats.StockQuantity);
  }

  /**
   * A formatter for integer values.
   * @param {*} v The value to format.
   * @return {string}
   */
  static formatInteger(v) {
    return v == null
        ? ''
        : Numbers.format(
            BigNumber.isBigNumber(v) ? v.toNumber() : v,
            NumberFormat.IntegerWithThousandSeparator);
  }

  /**
   * A formatter for timestamps.
   *
   * @param {*} v The value to format.
   * @param {boolean} [localize = true] Should the given value be treated as
   * UTC time and converted to the local timezone?
   * @return {string}
   */
  static formatTimestamp(v, localize = true) {
    return v == null
        ? ''
        : localize
            ? new CoreDate(v, true).local().format(DateFormat.Iso8601DateTime)
            : Dates.format(v, DateFormat.Iso8601DateTime);
  }

  /**
   * A formatter for decimal values without thousands separator.
   * @param {*} v The value to format.
   * @return {string}
   */
  static formatDecimalWithoutThousandsSeparator(v) {
    return v == null
        ? ''
        : Numbers.format(
            BigNumber.isBigNumber(v) ? v.toNumber() : v,
            NumberFormat.Decimal);
  }

}
