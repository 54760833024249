/*
 * Created by Paul Engelke on 01 April 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ProductOptionMenuActionTypes
  from "../constants/action-types/productOptionMenuActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of product option menus.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ProductOptionMenuActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ProductOptionMenuActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case ProductOptionMenuActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ProductOptionMenuActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case ProductOptionMenuActionTypes.CREATE:
      return create(state, action);

    case ProductOptionMenuActionTypes.UPDATE:
      return update(state, action);

    case ProductOptionMenuActionTypes.DELETE:
      return _delete(state, action);

    case ProductOptionMenuActionTypes.DELETE_OPTION:
      return deleteOption(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new option menu to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing option menu in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted option menu from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted option from an option menu in the list.
 */
const deleteOption = (state, action) => {
  const {menuId, optionId} = action.data;
  const next = state.data.map(e => (
      e.id === menuId
      ? {...e, menuOptions: e.menuOptions?.filter(o => o.id !== optionId)}
      : e
  ));
  return StateUtility.defaultSet(state, next);
};
