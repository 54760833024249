/*
 * Created by Stanton J Francis on 13 September 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ArmProfileActionTypes
  from "../constants/action-types/armProfileActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A state reducer for managing a list of arm profiles.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ArmProfileActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ArmProfileActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case ArmProfileActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ArmProfileActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case ArmProfileActionTypes.CREATE:
      return create(state, action);

    case ArmProfileActionTypes.UPDATE:
      return update(state, action);

    case ArmProfileActionTypes.DELETE:
      return _delete(state, action);

    case ArmProfileActionTypes.UNLINK_LEVEL:
      return unlinkLevel(state, action);

    case ArmProfileActionTypes.UPDATE_PROFILE_IMAGE:
      return updateProfileImage(state, action)

    case ArmProfileActionTypes.DELETE_PROFILE_IMAGE:
      return deleteProfileImage(state, action)

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }
}

/**
 * Adds a new profile to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing profile in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted profile from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes an unlinked level from an arm profile in the list.
 */
const unlinkLevel = (state, action) => {
  const {id, profileId} = action.data;
  const next = state.data.map(e => {
    if (e.id === profileId) {
      const {levels} = e;
      const nextLevels = levels?.filter(l =>
          l.id !== id);
      return {...e, levels: nextLevels};
    }
    return e;
  });
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing profile in the list with the profile image.
 */
const updateProfileImage = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.profileId ? {
    ...e,
    base64image: updated?.base64image
  } : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Deletes an existing profile's image.
 */
const deleteProfileImage = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? {
    ...e,
    base64image: null
  } : e);
  return StateUtility.defaultSet(state, next);
};

