/*
 * Created by Paul Engelke on 06 April 2021.
 */

import {Dialog} from "@hti-ui/react-web-material";
import {StateUtility, useActions} from "@hti-ui/redux-core";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
  acceptTermsAndConditions,
  fetchActionableTermsAndConditionsForUser
} from "../../actions/sessionActions";
import useEnhancedHistory from "../../hooks/useEnhancedHistory";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A dialog for requesting user consent for terms and conditions for the app.
 */
const PolicyConsentDialog = () => {

  const {loading, userId, policies} = useSelector(state => ({
    loading: StateUtility.areBusy(state.auth, state.session),
    userId: state.session.user?.id,
    policies: state.session.actionableTermsAndConditions,
  }));

  const [
    fetchActionableTsAndCs,
    acceptTsAndCs,
  ] = useActions([
    fetchActionableTermsAndConditionsForUser,
    acceptTermsAndConditions,
  ]);

  const {t} = useTranslation("App");
  const {handleError, dispatchErrorMessage} = useGlobalMessenger();
  const history = useEnhancedHistory();

  /*
   * We fetch a list of actionable terms and conditions for the current user.
   *
   * If this cannot be done, then we cannot reliably determine if they have
   * consented to all our terms and conditions and thus should not be allowed
   * to use the app. Hence, they should be signed out.
   */
  useEffect(() => {
    if (userId) {
      fetchActionableTsAndCs({userId})
      .catch(() => {
        const m = t("App:PolicyConsentDialog.Error.FetchActionablePolicies");
        dispatchErrorMessage(m);
        history.signOut();
      });
    }
  }, [userId]);

  const currentPolicy = useMemo(() => {
    const {id, title, detail} = policies[0] ?? {};
    return {
      id,
      title: title ?? t("App:PolicyConsentDialog.PolicyTitle.Unknown"),
      description: detail ?? "",
    };
  }, [policies, t]);

  /**
   * Signs the user out of the app if they do not consent to some terms and
   * conditions.
   */
  const onDisagreeClicked = useCallback(() =>
      void history.signOut(), [history]);

  /**
   * Updates the user's consent for terms and conditions.
   */
  const onAgreeClicked = useCallback(() => {
    acceptTsAndCs({
      accepted: [{userID: userId, regulationID: currentPolicy?.id}],
    })
    .catch(e => {
      const m = t("App:PolicyConsentDialog.Error.UpdateConsent");
      handleError(e, m);
    });
  }, [userId, currentPolicy, acceptTsAndCs, handleError, t]);

  return (<Dialog open={!!currentPolicy?.id}>

    <DialogTitle>{t("App:PolicyConsentDialog.Dialog.Title")}</DialogTitle>

    <DialogContent>
      <Typography variant={'h6'}>{currentPolicy?.title}</Typography>
      <Typography>{currentPolicy?.description}</Typography>
    </DialogContent>

    <DialogActions>

      <Button
          variant={'outlined'}
          onClick={onDisagreeClicked}
          disabled={loading}
      >{t("App:PolicyConsentDialog.Dialog.Button.Decline")}</Button>

      <Button
          variant={'outlined'}
          color={'secondary'}
          onClick={onAgreeClicked}
          disabled={loading}
      >{t("App:PolicyConsentDialog.Dialog.Button.Accept")}</Button>

    </DialogActions>

  </Dialog>);

};

export default PolicyConsentDialog;
