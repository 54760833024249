/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the rounding type redux state.
 */
const RoundingTypeActionTypes = ActionTypeUtility
.createActionTypes('ROUNDING_TYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default RoundingTypeActionTypes;
