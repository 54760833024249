/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import SessionActionTypes from "../constants/action-types/sessionActionTypes";

const DEFAULT_STATE = StateUtility.State({
  user: {
    id: null,
    customerId: null,
    firstName: null,
    lastName: null,
    fullName: null,
    initials: null,
    admin: false,
  },
  userRole: {},
  propertyAccess: [],
  actionableTermsAndConditions: [],
}, {async: true});

/**
 * A reducer for managing the current user session.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case SessionActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case SessionActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case SessionActionTypes.SET_USER:
      return setUser(state, action);

    case SessionActionTypes.SET_USER_ROLE:
      return setUserRole(state, action);

    case SessionActionTypes.SET_PROPERTY_ACCESS:
      return setPropertyAccess(state, action);

    case SessionActionTypes.SET_ACTIONABLE_TERMS_AND_CONDITIONS:
      return setActionableTermsAndConditions(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Sets the current user.
 */
const setUser = (state, action) => {
  return StateUtility.defaultCompleteRequest({
    ...state,
    user: action.data,
  });
};

/**
 * Sets the current user's security role.
 */
const setUserRole = (state, action) => {
  return StateUtility.defaultCompleteRequest({
    ...state,
    userRole: action.data,
  });
};

/**
 * Sets the current user's property access rights.
 */
const setPropertyAccess = (state, action) => {
  return StateUtility.defaultCompleteRequest({
    ...state,
    propertyAccess: action.data,
  });
};

/**
 * Sets a list of actionable terms and conditions for the current user.
 */
const setActionableTermsAndConditions = (state, action) => {
  return StateUtility.defaultCompleteRequest({
    ...state,
    actionableTermsAndConditions: action.data,
  });
};
