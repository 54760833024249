/*
 * Created by Paul Engelke on 13 August 2021.
 */

import {SettingsOutlined as AccountSettingsIcon} from "@material-ui/icons";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality related to customer account management.
 */
const AccountNode = ({goTo, t}) => ({
  id: "account",
  label: t("core:Term.Account"),
  quickLink: {IconComponent: AccountSettingsIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [

    {
      id: RouteNames.CustomerDetailPage,
      label: t("core:Term.AccountDetails"),
      onClick: goTo(RouteNames.CustomerDetailPage),
      security: {rights: [UserRight.ManageAccount]},
    },

    {
      id: RouteNames.BrandPage,
      label: t("core:Term.Brands"),
      onClick: goTo(RouteNames.BrandPage),
      security: {rights: [UserRight.ManageBrands]},
    },

    {
      id: RouteNames.PropertyPage,
      label: t("core:Term.Properties"),
      onClick: goTo(RouteNames.PropertyPage),
      security: {rights: [UserRight.ManageProperties]},
    },

    {
      id: RouteNames.OutletPage,
      label: t("core:Term.Outlets"),
      onClick: goTo(RouteNames.OutletPage),
      security: {rights: [UserRight.ManageOutlets]},
    },

    {
      id: RouteNames.StockStorePage,
      label: t("core:Term.Stores"),
      onClick: goTo(RouteNames.StockStorePage),
      security: {rights: [UserRight.ManageStores]},
    },

    {
      id: RouteNames.UserPage,
      label: t("core:Term.Users"),
      onClick: goTo(RouteNames.UserPage),
      security: {rights: [UserRight.ManageUsers]},
    },

    {
      id: RouteNames.UserRolePage,
      label: t("core:Term.UserRoles"),
      onClick: goTo(RouteNames.UserRolePage),
      security: {rights: [UserRight.ManageUserRoles]},
    },

    {
      id: RouteNames.PaymentTypePage,
      label: t("core:Term.PaymentTypes"),
      onClick: goTo(RouteNames.PaymentTypePage),
      security: {rights: [UserRight.ManagePaymentTypes]},
    },

    {
      id: RouteNames.TaxRulePage,
      label: t("core:Term.TaxRules"),
      onClick: goTo(RouteNames.TaxRulePage),
      security: {rights: [UserRight.ManageTaxRules]},
    },

    {
      id: RouteNames.ForexRatePage,
      label: t("glossary:ForexRates"),
      onClick: goTo(RouteNames.ForexRatePage),
      security: {rights: [UserRight.ManageForexRates]},
    },

  ],
});

export default AccountNode;
