/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockRecipeReductionActionTypes
  from "../constants/action-types/stockRecipeReductionActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for stock recipe reductions.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockRecipeReductionActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockRecipeReductionActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case StockRecipeReductionActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockRecipeReductionActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case StockRecipeReductionActionTypes.CREATE:
      return create(state, action);

    case StockRecipeReductionActionTypes.UPDATE:
      return update(state, action);

    case StockRecipeReductionActionTypes.DELETE:
      return _delete(state, action);

    case StockRecipeReductionActionTypes.DELETE_LINE_ITEM:
      return deleteLineItem(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new stock recipe reduction to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock recipe reduction in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted stock recipe reduction from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted line item from an existing stock recipe reduction entity.
 */
const deleteLineItem = (state, action) => {
  const {id, lineItemId} = action.data;
  const next = state.data.map(e => (
      e.id === id
          ? {...e, items: e.items?.filter(i => i.id !== lineItemId)}
          : e
  ));
  return StateUtility.defaultSet(state, next);
};
