/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the stock store redux state.
 */
const StockStoreActionTypes = ActionTypeUtility
.createActionTypes('STOCK_STORES', {

  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',

  SET: 'SET',
  SET_PRODUCTS: 'SET_PRODUCTS',

  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',

});

export default StockStoreActionTypes;
