/*
 * Created by Paul Engelke on 17 March 2021.
 */

import ThemeActionTypes from "../constants/action-types/themeActionTypes";

const DEFAULT_STATE = {darkMode: false};

/**
 * A redux state reducer for managing the app theme preferences.
 *
 * @deprecated
 * @see appPreferenceReducer
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ThemeActionTypes.INIT:
      return {...state, ...action.theme};

    case ThemeActionTypes.SET_DARK_MODE:
      return {...state, darkMode: action.darkMode};

    default:
      return state;

  }

};
