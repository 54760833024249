/*
 * Created by Paul Engelke on 14 September 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockDivisionManufactureActionTypes
  from "../constants/action-types/stockDivisionManufactureActionTypes";

const DEFAULT_STATE = StateUtility.State(
    {data: []},
    {async: true, asyncOptions: {useCounter: true}},
);

/**
 * A redux state reducer for a list of stock division manufactures.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockDivisionManufactureActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockDivisionManufactureActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockDivisionManufactureActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case StockDivisionManufactureActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case StockDivisionManufactureActionTypes.MERGE:
      return merge(state, action);

    case StockDivisionManufactureActionTypes.CREATE:
      return create(state, action);

    case StockDivisionManufactureActionTypes.UPDATE:
      return update(state, action);

    case StockDivisionManufactureActionTypes.DELETE:
      return _delete(state, action);

    case StockDivisionManufactureActionTypes.DELETE_LINE_ITEM:
      return deleteLineItem(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }

};

/**
 * Merges one or more new/updated stock manufactures into the local set.
 */
const merge = (state, action) => {
  const next = StateUtility.mergeData(state.data, action.data);
  return StateUtility.defaultSet(state, next);
};

/**
 * Adds a new stock manufacture to the local set.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock manufacture in the local set.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Deletes a stock manufacture from the local set.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted line item from an existing stock manufacture entity.
 */
const deleteLineItem = (state, action) => {
  const {manufactureId, lineItemId} = action.data;
  const next = state.data.map(e => (
      e.id === manufactureId
          ? {...e, lineItems: e.lineItems?.filter(i => i.id !== lineItemId)}
          : e
  ));
  return StateUtility.defaultSet(state, next);
};
