/*
 * Created by Stanton J Francis on 17 July 2024.
 */

import {Box, Dialog} from "@hti-ui/react-web-material";
import {StateUtility, useActions} from "@hti-ui/redux-core";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import useEnhancedHistory from "../../hooks/useEnhancedHistory";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import {updateUserPassword} from "../../actions/userActions";
import useLocalization from "../../hooks/useLocalization";
import PasswordField from "../common/PasswordField";
import {dismissPasswordChangeNotification} from "../../actions/sessionActions";

/**
 * A dialog for informing a user that their password is about to expire.
 */
const PasswordExpiringDialog = () => {

  const {
    loading,
    userId,
    mustChangePassword,
    daysUntilPasswordExpires
  } = useSelector(state => ({
    loading: StateUtility.areBusy(state.auth, state.session, state.users),
    userId: state.session.user?.id,
    mustChangePassword: state.session.user?.mustChangePassword ?? false,
    daysUntilPasswordExpires: state.session.user?.daysUntilPasswordExpires
        ?? null,

  }));

  const [_updateUserPassword, _dismissNotification] = useActions(
      [updateUserPassword, dismissPasswordChangeNotification]);

  const {translate} = useLocalization();
  const {handleError, dispatchSuccessMessage} = useGlobalMessenger();
  const history = useEnhancedHistory();
  const [password, setPassword] = useState(null);

  const isWarning = useMemo(
      () => daysUntilPasswordExpires !== null && daysUntilPasswordExpires <= 3,
      [daysUntilPasswordExpires]);

  /**
   * Signs the user out of the app if they do not consent to some terms and
   * conditions.
   */
  const onDisagreeClicked = useCallback(() =>
      void history.signOut(), [history]);

  const dismissPasswordNotification = useCallback(() =>
      void _dismissNotification(), [_dismissNotification]);

  /**
   * Updates the user's consent for terms and conditions.
   */
  const onUpdateClicked = useCallback(() => {
    _updateUserPassword({
      id: userId,
      password
    })
    .then(() => {
      dispatchSuccessMessage(translate("PasswordExpiryDialog.Success.Message"));
      dismissPasswordNotification();
    })
    .catch(e => {
      const {_code} = e;
      let m;
      if (_code === "PreviouslyUsedPassword") {
        m = translate("Constant.Error.PreviouslyUsedPassword.Description");
      } else {
        m = translate("Common.Snackbar.Error.Update", {
          x: translate("Term.Password")?.toLowerCase(),
        });
      }
      handleError(e, m);
    });
  }, [userId, password, _updateUserPassword, dispatchSuccessMessage,
    handleError, dismissPasswordNotification]);

  return (<Dialog open={!!mustChangePassword || !!isWarning}
                  maxWidth={"xs"} fullWidth>

    <DialogTitle>{!!mustChangePassword ? translate("PasswordExpiryDialog.Title")
        : translate("PasswordExpiryDialog.Warning.Title")}</DialogTitle>
    <Box topMargin={-2} leftPadding={3} rightPadding={3}>
      <Typography variant={'subtitle1'}
                  color={"textSecondary"}>{!!mustChangePassword ? translate(
              "PasswordExpiryDialog.Subtitle")
          : translate("PasswordExpiryDialog.Warning.Subtitle", {
            x: daysUntilPasswordExpires
          })}</Typography>
    </Box>

    <DialogContent>
      <Typography variant={'body1'}>{!!mustChangePassword ? translate(
              "PasswordExpiryDialog.Body")
          : translate("PasswordExpiryDialog.Warning.Body")}</Typography>
      <Box topPadding={1}>
        <PasswordField label={translate("Term.Password")}
                       value={password} onChange={setPassword}
                       showPasswordComplexity={true}
                       useSessionCustomerId={true}/>
      </Box>
    </DialogContent>

    <DialogActions>

      <Button
          variant={'outlined'}
          onClick={!!mustChangePassword ? onDisagreeClicked
              : dismissPasswordNotification}
          disabled={loading}
      >{translate("Common.Button.Dismiss")}</Button>

      <Button
          variant={'outlined'}
          color={'secondary'}
          onClick={onUpdateClicked}
          disabled={loading || !password}
      >{translate("Common.Button.Update")}</Button>

    </DialogActions>

  </Dialog>);

};

export default PasswordExpiringDialog;
