/*
 * Created by Paul Engelke on 06 December 2021.
 */

import i18n from "i18next";
import {initReactI18next} from "react-i18next";

/**
 * A localization backend that supports webpack code splitting, and imports
 * translation files on demand.
 *
 * @see https://gist.github.com/SimeonC/6a738467c691eef7f21ebf96918cd95f
 */
const Backend = {
  type: "backend",
  read: (language, namespace, callback) => {
    import(`./${language}/${namespace}.json`)
    .then((resources) => void callback(null, resources))
    .catch((error) => void callback(error, null));
  },
};

// noinspection JSIgnoredPromiseFromCall
i18n
.use(Backend)
.use(initReactI18next)
.init({
  lng: "en",
  fallbackLng: "en",
  defaultNS: "core",
  keySeparator: false,
  interpolation: {
    escapeValue: false // React does this implicitly.
  },
  react: {
    useSuspense: false,
  }
});

export default i18n;
