/*
 * Created by Paul Engelke on 30 March 2020.
 */

/**
 * A defined set of the available dashboard widgets.
 */
const DashboardWidget = Object.freeze({
  TotalSales: 'TotalSales',
  TotalAdditionalCharges: 'TotalAdditionalCharges',
  TotalPaymentsReceived: 'TotalPaymentsReceived',
  TotalPaymentsOutstanding: 'TotalPaymentsOutstanding',
  TotalVoids: 'TotalVoids',
  TotalDiscounts: 'TotalDiscounts',
  TotalTransfers: 'TotalTransfers',
  TotalCorrections: 'TotalCorrections',
  RevenueByOutlet: 'RevenuePerOutlet',
  RevenueByGroup: 'RevenuePerGroup',
  Top5Users: 'Top5Waiters',
  RevenueByHour: 'RevenuePerHour',
  Top10SellingProducts: 'Top10SellingProducts',
});

export default DashboardWidget;
