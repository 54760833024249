/*
 * Created by Stanton J Francis on 11 July 2022.
 */

import {RecentActorsOutlined as ARMIcon} from '@material-ui/icons';
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality related to ARM module.
 */
const ARMNode = ({goTo, t}) => ({
  id: "arm",
  label: t("glossary:AccountsReceivable"),
  quickLink: {IconComponent: ARMIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [

    {
      id: RouteNames.ArmAccountTypePage,
      label: t("glossary:AccountTypes"),
      onClick: goTo(RouteNames.ArmAccountTypePage),
      security: {rights: [UserRight.ManageAccountTypes]},
    },

    {
      id: RouteNames.ArmCompanyPage,
      label: t("glossary:Companies"),
      onClick: goTo(RouteNames.ArmCompanyPage),
      security: {
        rights: [UserRight.ManageArmCompanies, UserRight.ManageCompanies],
        requireAllRights: false
      },
    },

    {
      id: RouteNames.ArmProfilePage,
      label: t("glossary:Profiles"),
      onClick: goTo(RouteNames.ArmProfilePage),
      security: {
        rights: [UserRight.ManageArmProfiles, UserRight.ManageProfiles],
        requireAllRights: false
      },
    },

    {
      id: RouteNames.ArmTemplatePdfPage,
      label: t("glossary:StatementTemplatePdf"),
      onClick: goTo(RouteNames.ArmTemplatePdfPage),
      security: {
        rights: [UserRight.ManageAccountTypes],
        requireAllRights: true
      },
    },

    {
      id: RouteNames.ArmTemplateEmailPage,
      label: t("glossary:StatementTemplateEmail"),
      onClick: goTo(RouteNames.ArmTemplateEmailPage),
      security: {
        rights: [UserRight.ManageAccountTypes],
        requireAllRights: true
      },
    },

  ],
});

export default ARMNode;
