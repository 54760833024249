/*
 * Created by Paul Engelke on 09 March 2023.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for controlling campaign redux state.
 *
 * @type Object
 */
const CampaignActionTypes = ActionTypeUtility.createActionTypes("Campaigns", {

  Request: "Request",
  CompleteRequest: "CompleteRequest",
  FailRequest: "FailRequest",

  Set: "Set",
  Merge: "Merge",

  Create: "Create",
  Update: "Update",
  Delete: "Delete",

  SetSchedules: "SetSchedules",
  CreateSchedule: "CreateSchedule",
  UpdateSchedule: "UpdateSchedule",
  DeleteSchedule: "DeleteSchedule",

});

export default CampaignActionTypes;
