/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {Sorter} from "@hti-ui/js-core";
import {Autocomplete, Box, Dialog} from "@hti-ui/react-web-material";
import {StateUtility, useActions} from "@hti-ui/redux-core";
import {DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {fetchCustomer, fetchCustomers} from "../../../actions/customerActions";
import {
  fetchCustomerLicence,
  setCustomer,
  setProperty
} from "../../../actions/workspaceActions";
import useCanAccessCustomers from "../../../hooks/useCanAccessCustomers";
import useGlobalMessenger from "../../../hooks/useGlobalMessenger";
import WorkspaceSelectorButton from "./WorkspaceSelectorButton";

/**
 * A component that provides users, with sufficient authorization, the ability
 * to switch customers.
 */
const CustomerWorkspaceSelector = () => {

  const {t} = useTranslation(["App", "glossary", "common"]);
  const {handleError} = useGlobalMessenger();
  const canSelectCustomer = useCanAccessCustomers();
  const [showDialog, toggleDialog] = useState(canSelectCustomer);

  const user = useSelector(state => state.session.user);
  const defaultCustomerId = user?.customerId;
  const loading = useSelector(state => StateUtility.areBusy(
      state.auth,
      state.workspace,
      state.customers,
  ));

  const customer = useSelector(state => state.workspace.customer);
  const customers = useSelector(state => state.customers.data);

  const customerOptions = useMemo(() => (
      customers.slice().sort((a, b) => Sorter.compareAll(
          Sorter.Comparator(
              b.id === defaultCustomerId,
              a.id === defaultCustomerId),
          Sorter.Comparator(a.name.toLowerCase(), b.name.toLowerCase())))
  ), [customers, defaultCustomerId]);

  const [
    _setCustomer, _setProperty, _fetchCustomerLicence,
    _fetchCustomers, _fetchCustomer,
  ] = useActions([
    setCustomer, setProperty, fetchCustomerLicence,
    fetchCustomers, fetchCustomer,
  ]);

  /**
   * Sets the workspace based on the selected customer.
   */
  const onCustomerChanged = useCallback(customer => {
    _fetchCustomerLicence({customerId: customer?.id})
    .then(() => {
      _setCustomer({customer});
      _setProperty();
      toggleDialog(false);
    })
    .catch(e => void handleError(e, t("App:Workspace.Error.SetCustomer")));
  }, [_fetchCustomerLicence, _setCustomer, _setProperty, handleError, t]);

  useEffect(() => {
    if (canSelectCustomer) {
      _fetchCustomers()
      .catch(e => {
        const m = t("common:Snackbar.Error.FetchList", {
          x: t("glossary:Customers").toLocaleLowerCase(),
        });
        handleError(e, m);
      });
    } else if (user?.customerId) {
      _fetchCustomer({id: user?.customerId})
      .then(r => onCustomerChanged(r.data))
      .catch(e => {
        handleError(e, t("App:Workspace.Error.FetchCustomerDetails"));
      });
    }
  }, [user?.customerId]);

  const serializeCustomer = useCallback(c => c?.name ?? '', []);
  const isSelectedCustomer = useCallback((option, value) =>
      option && value && option.id === value.id, []);

  if (!canSelectCustomer) {
    // The user cannot select a customer, so we hide the control.
    return null;
  }

  return (<>

    <WorkspaceSelectorButton
        tooltip={t("Workspace.Button.Tooltip.Customer")}
        onClick={() => toggleDialog(true)}
        disabled={loading}
    >
      {customer?.id
          ? customer?.name ?? t("glossary:Unknown")
          : t("App:Workspace.Button.Placeholder.Customer")}
    </WorkspaceSelectorButton>

    <Dialog
        open={showDialog}
        onClose={customer?.id != null ? () => toggleDialog(false) : undefined}
    >

      <DialogTitle>{t("App:Workspace.Dialog.Title.Customer")}</DialogTitle>

      <DialogContent>

        <Box bottomPadding={1}>
          <DialogContentText>
            {t("App:Workspace.Dialog.Description.Customer")}
          </DialogContentText>
        </Box>

        <Box bottomPadding={2}>
          <Autocomplete
              label={t("glossary:Customer")}
              value={customer?.id ? customer : null}
              options={customerOptions}
              onChange={onCustomerChanged}
              serializeOption={serializeCustomer}
              isSelectedOption={isSelectedCustomer}
              disabled={loading}
              disableClearable
              optionHeight={38}
          />
        </Box>

      </DialogContent>

    </Dialog>

  </>);

};

export default CustomerWorkspaceSelector;
