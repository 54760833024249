/*
 * Created by Paul Engelke on 25 March 2021.
 */

import {AppPreferenceModel} from "./appPreferenceModel";
import JwtTokenModel from "./jwtTokenModel";
import JwtRefreshTokenModel from "./jwtRefreshTokenModel";
import ThemePreferenceModel from "./themePreferenceModel";

/**
 * An interface for interacting with local storage.
 */
export class LocalStorage {

  /**
   * The user's JWT token.
   */
  static get JwtToken() {
    return new JwtTokenModel();
  }

  /**
   * The user's JWT refresh token.
   */
  static get JwtRefreshToken() {
    return new JwtRefreshTokenModel();
  }

  /**
   * The user's theme preferences.
   * @deprecated
   * @see AppPreferences
   */
  static get ThemePreferences() {
    return new ThemePreferenceModel();
  }

  /**
   * The user's app preferences.
   */
  static get AppPreferences() {
    return new AppPreferenceModel();
  }

}
