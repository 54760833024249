/*
 * Created by Paul Engelke on 06 April 2021.
 */

import ThemeTypes from "../constants/themeTypes";
import DarkTheme from '../styles/darkTheme';
import LightTheme from '../styles/lightTheme';
import useAppPreferences from "./useAppPreferences";

/**
 * Provides the UI theme based on the user's theme preferences.
 * @return {*}
 */
const useTheme = () => {
  const theme = useAppPreferences().theme;
  return theme === ThemeTypes.Dark ? DarkTheme : LightTheme;
};

export default useTheme;
