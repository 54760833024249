/*
 * Created by Paul Engelke on 30 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the stock requisition redux state.
 */
const StockRequisitionActionTypes = ActionTypeUtility
.createActionTypes('STOCK_REQUISITIONS', {

  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',

  SET: 'SET',
  MERGE: 'MERGE',

  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DELETE_LINE_ITEM: 'DELETE_LINE_ITEM',

});

export default StockRequisitionActionTypes;
