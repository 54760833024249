/*
 * Created by Stanton J Francis on 16 August 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing a list of arm companies.
 */
const ArmCompanyActionTypes = ActionTypeUtility
.createActionTypes('ARM_COMPANIES', {
  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});

export default ArmCompanyActionTypes;
