/*
 * Created by Paul Engelke on 12 July 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for dispatching actions for the product variant redux
 * state.
 */
const ProductVariantActionTypes = ActionTypeUtility
.createActionTypes("PRODUCT_VARIANTS", {

  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',

  SET: 'SET',

  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',

});

export default ProductVariantActionTypes;
