/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {useActions} from "@hti-ui/redux-core";
import {
  Avatar,
  CardHeader,
  Divider,
  makeStyles,
  MenuItem,
  Popover,
  Typography
} from "@material-ui/core";
import {
  ExitToAppRounded as SignOutIcon,
  NightsStayOutlined as DarkModeIcon,
  WbSunnyOutlined as LightModeIcon
} from '@material-ui/icons';
import PropTypes from "prop-types";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {setTheme} from "../../../../actions/appPreferenceActions";
import UserRight from "../../../../constants/security/userRights";
import ThemeTypes from "../../../../constants/themeTypes";
import useAppPreferences from "../../../../hooks/useAppPreferences";
import useEnhancedHistory from "../../../../hooks/useEnhancedHistory";
import useUserAuthorization from "../../../../hooks/useUserAuthorization";
import LanguagePicker from "./LanguagePicker";

/**
 * A popover card that describes the signed-in user and provides some actions,
 * such as signing out.
 */
const UserCard = props => {

  const classes = useStyles();
  const {t} = useTranslation(["common", "App"]);

  const {anchor, onClose, fullName, initials} = props;
  const history = useEnhancedHistory();
  const userRole = useSelector(state => state.session.userRole);
  const localizationEnabled = useUserAuthorization([UserRight.HtiFullAccess]);

  /**
   * Signs the user out of the app.
   */
  const signOut = useCallback(() => {
    history.signOut();
    onClose();
  }, [history, onClose]);

  const theme = useAppPreferences().theme;
  const darkMode = theme === ThemeTypes.Dark;
  const _setTheme = useActions(setTheme);

  /**
   * Switches between light and dark mode.
   */
  const toggleTheme = useCallback(() => {
    _setTheme({
      theme: darkMode ? ThemeTypes.Light : ThemeTypes.Dark,
      persist: true,
    });
    onClose();
  }, [onClose, _setTheme, darkMode]);

  return (<Popover
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      onClose={onClose}
  >

    <CardHeader
        title={fullName}
        subheader={userRole?.name ?? ""}
        avatar={<Avatar className={classes.avatar}>{initials}</Avatar>}
    />

    <Divider/>

    {localizationEnabled && <LanguagePicker
        classes={classes}
        visible={!!anchor}
        onClose={onClose}
    />}

    <MenuItem onClick={toggleTheme} className={classes.menuItem}>
      {darkMode ? (<LightModeIcon/>) : (<DarkModeIcon/>)}
      <Typography className={classes.menuItemText}>
        {darkMode
            ? t("App:UserWidget.SwitchLightMode")
            : t("App:UserWidget.SwitchDarkMode")}
      </Typography>
    </MenuItem>

    <Divider/>

    <MenuItem onClick={signOut} className={classes.menuItem}>
      <SignOutIcon/>
      <Typography className={classes.menuItemText}>
        {t("common:Button.SignOut")}
      </Typography>
    </MenuItem>

  </Popover>);

};

const useStyles = makeStyles(theme => ({
  customerName: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  avatar: {
    backgroundColor: theme.palette.compliment["100%"],
    color: theme.palette.base["100%"],
  },
  menuItem: {padding: theme.spacing(2)},
  menuItemText: {marginLeft: theme.spacing()},
}));

UserCard.propTypes = {
  anchor: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  initials: PropTypes.string,
};

export default UserCard;
