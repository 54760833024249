/*
 * Created by Paul Engelke on 10 January 2022.
 */

import {useCallback} from "react";
import AppStatusCode from "../../constants/codes/appStatusCodes";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * Provides an error handler function for registration process errors.
 * @return {function(*): void}
 */
const useErrorHandler = () => {
  const {dispatchErrorMessage} = useGlobalMessenger();
  return useCallback(e => {
    switch (e._code) {
      case AppStatusCode.DuplicateUserEmailAddress:
        dispatchErrorMessage(
            "Your email address is already associated with an account. "
            + "Please sign in instead.");
        break;
      default:
        dispatchErrorMessage(
            "Registration failed. Please try again. "
            + "If the issue persists, please contact support.");
        break;
    }
  }, [dispatchErrorMessage]);
};

export default useErrorHandler;
