/*
 * Created by Stanton J Francis on 4 May 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import PrintTemplateActionTypes
  from "../constants/action-types/printTemplateActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing print templates.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case PrintTemplateActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case PrintTemplateActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case PrintTemplateActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case PrintTemplateActionTypes.CREATE:
      return StateUtility.defaultCompleteRequest(state);

    case PrintTemplateActionTypes.UPDATE:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

