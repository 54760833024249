/*
 * Created by Paul Engelke on 08 July 2021.
 */

import {Sorter} from "@hti-ui/js-core";
import {Autocomplete, Box, Dialog} from "@hti-ui/react-web-material";
import {useActions} from "@hti-ui/redux-core";
import {DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {setProperty} from "../../../../actions/workspaceActions";
import useAccessibleProperties from "../../../../hooks/useAccessibleProperties";

/**
 * A dialog for prompting the user to select a property for their workspace.
 */
const SelectionDialog = props => {

  const {t} = useTranslation(["App", "glossary"]);
  const {open, onClose, disabled} = props;
  const property = useSelector(state => state.workspace.property);
  const properties = useAccessibleProperties();
  const [_setProperty] = useActions([setProperty]);
  const propertyOptions = useMemo(() => (
      properties.slice().sort((a, b) =>
          Sorter.compare(a.name.toLowerCase(), b.name.toLowerCase()))
  ), [properties]);

  const serializeProperty = useCallback(p => p?.name ?? '', []);
  const isSelectedProperty = useCallback(other =>
      other.id === property?.id, [property]);

  const onPropertyChanged = useCallback((property) => {
    _setProperty({property});
    onClose(false);
  }, [onClose, _setProperty]);

  return (<Dialog
      open={open}
      onClose={property?.id != null ? () => onClose(false) : undefined}
  >

    <DialogTitle>{t("App:Workspace.Dialog.Title.Property")}</DialogTitle>

    <DialogContent>

      <Box bottomPadding={1}>
        <DialogContentText>
          {t("App:Workspace.Dialog.Description.Property")}
        </DialogContentText>
      </Box>

      <Box bottomPadding={2}>
        <Autocomplete
            label={t("glossary:Property")}
            value={property?.id ? property : null}
            options={propertyOptions}
            onChange={onPropertyChanged}
            serializeOption={serializeProperty}
            isSelectedOption={isSelectedProperty}
            disabled={disabled}
            disableClearable
            optionHeight={38}
        />
      </Box>

    </DialogContent>

  </Dialog>);

};

SelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectionDialog;
