/*
 * Created by Stanton J Francis on 22 June 2023.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the image redux state.
 */
const ImageActionTypes = ActionTypeUtility.createActionTypes('IMAGES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  UPDATE: 'UPDATE',
});

export default ImageActionTypes;
