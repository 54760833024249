/*
 * Created by Paul Engelke on 13 August 2021.
 */

import {FastfoodOutlined as StockSettingsIcon} from "@material-ui/icons";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality related to stock management.
 */
const StockNode = ({goTo, t}) => ({
  id: "Stock",
  label: t("core:Term.Stock"),
  quickLink: {IconComponent: StockSettingsIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [

    {
      id: RouteNames.StockSupplierPage,
      label: t("core:Term.Suppliers"),
      onClick: goTo(RouteNames.StockSupplierPage),
      security: {rights: [UserRight.ManageStockSuppliers]},
    },

    {
      id: RouteNames.StockOrderPage,
      label: t("core:Term.Orders"),
      onClick: goTo(RouteNames.StockOrderPage),
      security: {rights: [UserRight.ManageStockOrders]},
    },

    {
      id: RouteNames.StockOrderTemplatePage,
      label: t("core:Term.OrderTemplates"),
      onClick: goTo(RouteNames.StockOrderTemplatePage),
      security: {rights: [UserRight.ManageStockOrderTemplates]},
    },

    {
      id: RouteNames.InternalGlobalStockOrderPage,
      label: `${t("core:Term.GlobalOrders")} (${t("core:Term.Internal")})`,
      onClick: goTo(RouteNames.InternalGlobalStockOrderPage),
      security: {rights: [UserRight.ManageInternalGlobalStockOrders]},
    },

    {
      id: RouteNames.ExternalGlobalStockOrderPage,
      label: `${t("core:Term.GlobalOrders")} (${t("core:Term.External")})`,
      onClick: goTo(RouteNames.ExternalGlobalStockOrderPage),
      security: {rights: [UserRight.ManageExternalGlobalStockOrders]},
    },

    {
      id: RouteNames.StockDeliveryPage,
      label: t("core:Term.Deliveries"),
      onClick: goTo(RouteNames.StockDeliveryPage),
      security: {rights: [UserRight.ManageStockDeliveries]},
    },

    {
      id: RouteNames.InternalGlobalStockDeliveryPage,
      label:
          `${t("core:Term.GlobalDeliveries")} (${t("core:Term.Internal")})`,
      onClick: goTo(RouteNames.InternalGlobalStockDeliveryPage),
      security: {rights: [UserRight.ManageInternalGlobalStockDeliveries]},
    },

    {
      id: RouteNames.ExternalGlobalStockDeliveryPage,
      label:
          `${t("core:Term.GlobalDeliveries")} (${t("core:Term.External")})`,
      onClick: goTo(RouteNames.ExternalGlobalStockDeliveryPage),
      security: {rights: [UserRight.ManageExternalGlobalStockDeliveries]},
    },

    {
      id: RouteNames.StockRequisitionPage,
      label: t("core:Term.Requisitions"),
      onClick: goTo(RouteNames.StockRequisitionPage),
      security: {rights: [UserRight.ManageStockRequisitions]},
    },

    {
      id: RouteNames.StockIssuePage,
      label: t("core:Term.Issues"),
      onClick: goTo(RouteNames.StockIssuePage),
      security: {rights: [UserRight.ManageStockIssues]},
    },

    {
      id: RouteNames.StockReductionPage,
      label: t("core:Term.Reductions"),
      onClick: goTo(RouteNames.StockReductionPage),
      security: {rights: [UserRight.ManageStockReductions]},
    },

    {
      id: RouteNames.StockManualSalePage,
      label: t("core:Term.ManualSales"),
      onClick: goTo(RouteNames.StockManualSalePage),
      security: {rights: [UserRight.ManageStockManualSales]},
    },

    {
      id: RouteNames.StockTakePage,
      label: t("core:Term.StockTake"),
      onClick: goTo(RouteNames.StockTakePage),
      security: {rights: [UserRight.ManageStockCounts]},
    },

    {
      id: RouteNames.StockRecipePage,
      label: t("core:Term.Recipes"),
      onClick: goTo(RouteNames.StockRecipePage),
      security: {rights: [UserRight.ManageRecipes]},
    },

    {
      id: RouteNames.StockRecipeReductionPage,
      label: t("core:Term.RecipeReductions"),
      onClick: goTo(RouteNames.StockRecipeReductionPage),
      security: {rights: [UserRight.ManageStockRecipeReductions]},
    },

    {
      id: RouteNames.StockCombinationManufacturePage,
      label:
          `${t("core:Term.Manufacturing")} (${t("core:Term.Combination")})`,
      onClick: goTo(RouteNames.StockCombinationManufacturePage),
      security: {rights: [UserRight.ManageStockCombinationManufactures]},
    },

    {
      id: RouteNames.StockDivisionManufacturePage,
      label:
          `${t("core:Term.Manufacturing")} (${t("core:Term.Division")})`,
      onClick: goTo(RouteNames.StockDivisionManufacturePage),
      security: {rights: [UserRight.ManageStockDivisionManufactures]},
    },

  ],
});

export default StockNode;
