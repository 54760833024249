/*
 * Created by Paul Engelke on 30 March 2020.
 */

import {StateUtility} from "@hti-ui/redux-core";
import DashboardWidgetActionTypes
  from "../constants/action-types/dashboardWidgetActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import DashboardWidget from "../constants/dashboardWidgets";

const DEFAULT_STATE = Object.freeze({

  [DashboardWidget.TotalSales]:
    StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.TotalAdditionalCharges]:
    StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.TotalPaymentsReceived]:
    StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.TotalPaymentsOutstanding]:
    StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.TotalVoids]:
    StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.TotalDiscounts]:
    StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.TotalTransfers]:
      StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.TotalCorrections]:
      StateUtility.State({data: 0}, {async: true}),

  [DashboardWidget.RevenueByGroup]:
    StateUtility.State({data: []}, {async: true}),

  [DashboardWidget.RevenueByOutlet]:
    StateUtility.State({data: []}, {async: true}),

  [DashboardWidget.Top5Users]:
    StateUtility.State({data: []}, {async: true}),

  [DashboardWidget.RevenueByHour]:
    StateUtility.State({data: []}, {async: true}),

  [DashboardWidget.Top10SellingProducts]:
    StateUtility.State({data: []}, {async: true}),

});

/**
 * A monolithic state reducer for all dashboard widgets.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case DashboardWidgetActionTypes.REQUEST:
      return requestWidgetData(state, action);

    case DashboardWidgetActionTypes.SET:
      return setWidgetData(state, action);

    case DashboardWidgetActionTypes.FAIL_REQUEST:
      return failWidgetDataRequest(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }

};

/**
 * Sets a dashboard widget's state to indicate a response is pending.
 */
const requestWidgetData = (state, action) => {
  const {widgetId} = action;
  const widget = StateUtility.defaultRequest(state[widgetId]);
  return {...state, [widgetId]: widget};
};

/**
 * Sets a widget's data and state to indicate that the response is no longer
 * pending.
 */
const setWidgetData = (state, action) => {
  const {widgetId, data} = action;
  const widget = StateUtility.defaultSet(state[widgetId], data);
  return {...state, [widgetId]: widget};
};

/**
 * Sets a dashboard widget's state to indicate no response is pending.
 */
const failWidgetDataRequest = (state, action) => {
  const {widgetId} = action;
  const widget = StateUtility.defaultFailRequest(state[widgetId]);
  return {...state, [widgetId]: widget};
};
