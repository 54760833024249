/*
 * Created by Paul Engelke on 13 August 2021.
 */

import {Sorter} from "@hti-ui/js-core";
import {PieChartOutlined as ReportingIcon} from "@material-ui/icons";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality related to reporting.
 */
const ReportNode = ({goTo, t}) => ({
  id: "Reports",
  label: t("core:Term.Reports"),
  quickLink: {IconComponent: ReportingIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [

    {
      id: RouteNames.UserActionHistoryPage,
      label: t("core:Term.UserActionHistory"),
      onClick: goTo(RouteNames.UserActionHistoryPage),
      security: {rights: [UserRight.UserActionHistory]},
    },

    {
      id: "reports/pos",
      label: t("core:Term.PointOfSales"),
      children: [

        {
          id: RouteNames.PaymentAnalysisReportPage,
          label: t("core:Term.PaymentAnalysisReport"),
          onClick: goTo(RouteNames.PaymentAnalysisReportPage),
          security: {rights: [UserRight.PaymentAnalysisReport]},
        },

        {
          id: RouteNames.OutletAnalysisReportPage,
          label: t("core:Term.OutletAnalysisReport"),
          onClick: goTo(RouteNames.OutletAnalysisReportPage),
          security: {rights: [UserRight.OutletAnalysisReport]},
        },

        {
          id: RouteNames.TransactionAuditReportPage,
          label: t("core:Term.TransactionAuditReport"),
          onClick: goTo(RouteNames.TransactionAuditReportPage),
          security: {rights: [UserRight.TransactionAuditReport]},
        },

        {
          id: RouteNames.TopSellersReportPage,
          label: t("core:Term.TopSellersReport"),
          onClick: goTo(RouteNames.TopSellersReportPage),
          security: {rights: [UserRight.TopSellersReport]},
        },

        {
          id: RouteNames.ProductPusherReportPage,
          label: t("core:Term.ProductPusherReport"),
          onClick: goTo(RouteNames.ProductPusherReportPage),
          security: {rights: [UserRight.ProductPusherReport]},
        },

        {
          id: RouteNames.UserAnalysisReportPage,
          label: t("core:Term.UserAnalysisReport"),
          onClick: goTo(RouteNames.UserAnalysisReportPage),
          security: {rights: [UserRight.UserAnalysisReport]},
        },

        {
          id: RouteNames.DiscountTransactionReportPage,
          label: t("core:Term.DiscountTransactionReport"),
          onClick: goTo(RouteNames.DiscountTransactionReportPage),
          security: {rights: [UserRight.DiscountTransactionReport]},
        },

        {
          id: RouteNames.AdditionalTaxesReportPage,
          label: t("core:Term.AdditionalTaxesReport"),
          onClick: goTo(RouteNames.AdditionalTaxesReportPage),
          security: {rights: [UserRight.AdditionalTaxesReport]},
        },
        {
          id: RouteNames.TransactionTransferReportPage,
          label: t("core:Term.TransactionTransferReport"),
          onClick: goTo(RouteNames.TransactionTransferReportPage),
          security: {rights: [UserRight.TransactionTransferReport]},
        },

        {
          id: RouteNames.DetailedPaymentAnalysisReportPage,
          label: t("core:Term.DetailedPaymentAnalysisReport"),
          onClick: goTo(RouteNames.DetailedPaymentAnalysisReportPage),
          security: {rights: [UserRight.DetailedPaymentAnalysisReport]},
        },
        {
          id: RouteNames.ForexPaymentAnalysisReportPage,
          label: t("glossary:ForexPaymentAnalysisReport"),
          onClick: goTo(RouteNames.ForexPaymentAnalysisReportPage),
          security: {rights: [UserRight.ForexPaymentAnalysisReport]},
        },

      ].sort((a, b) => Sorter.compare(a.label, b.label)),
    },

    {
      id: "reports/stock",
      label: t("core:Term.Stock"),
      children: [

        {
          id: RouteNames.DeliveryCostPriceIncreaseReportPage,
          label: t("glossary:DeliveryCostPriceIncreaseReport"),
          onClick: goTo(RouteNames.DeliveryCostPriceIncreaseReportPage),
          security: {rights: [UserRight.DeliveryCostPriceIncreaseReport]},
        },

        {
          id: RouteNames.PARLevelsReportPage,
          label: t("glossary:PARLevelsReport"),
          onClick: goTo(RouteNames.PARLevelsReportPage),
          security: {rights: [UserRight.PARLevelsReport]},
        },

        {
          id: RouteNames.StockItemActivityReportPage,
          label: t("core:Term.ItemActivityReport"),
          onClick: goTo(RouteNames.StockItemActivityReportPage),
          security: {rights: [UserRight.StockItemActivityReport]},
        },

        {
          id: RouteNames.StockMovementReportPage,
          label: t("core:Term.StockMovementReport"),
          onClick: goTo(RouteNames.StockMovementReportPage),
          security: {rights: [UserRight.StockMovementReport]},
        },

        {
          id: RouteNames.StockValueReportPage,
          label: t("core:Term.StockValueReport"),
          onClick: goTo(RouteNames.StockValueReportPage),
          security: {rights: [UserRight.StockValueReport]},
        },
        {
          id: RouteNames.SlowMovementReportPage,
          label: t("core:Term.SlowMovementReport"),
          onClick: goTo(RouteNames.SlowMovementReportPage),
          security: {rights: [UserRight.SlowMovementReport]},
        },

        {
          id: "reports/stock/listing",
          label: t("core:Term.StockListingReports"),
          children: [

            {
              id: RouteNames.StockDeliveryListingReportPage,
              label: t("core:Term.DeliveryListingReport"),
              onClick: goTo(RouteNames.StockDeliveryListingReportPage),
              security: {rights: [UserRight.StockDeliveriesReport]},
            },

            {
              id: RouteNames.GlobalStockDeliveryListingReportPage,
              label: t("core:Term.GlobalDeliveryListingReport"),
              onClick: goTo(RouteNames.GlobalStockDeliveryListingReportPage),
              security: {rights: [UserRight.GlobalStockDeliveryListingReport]},
            },

            {
              id: RouteNames.StockOrderListingReportPage,
              label: t("core:Term.OrderListingReport"),
              onClick: goTo(RouteNames.StockOrderListingReportPage),
              security: {rights: [UserRight.StockOrdersReport]},
            },

            {
              id: RouteNames.GlobalStockOrderListingReportPage,
              label: t("core:Term.GlobalOrderListingReport"),
              onClick: goTo(RouteNames.GlobalStockOrderListingReportPage),
              security: {rights: [UserRight.GlobalStockOrderListingReport]},
            },

            {
              id: RouteNames.StockRequisitionListingReportPage,
              label: t("core:Term.RequisitionListingReport"),
              onClick: goTo(RouteNames.StockRequisitionListingReportPage),
              security: {rights: [UserRight.StockRequisitionsReport]},
            },

            {
              id: RouteNames.StockIssueListingReportPage,
              label: t("core:Term.IssueListingReport"),
              onClick: goTo(RouteNames.StockIssueListingReportPage),
              security: {rights: [UserRight.StockIssuesReport]},
            },

            {
              id: RouteNames.StockReductionListingReportPage,
              label: t("core:Term.ReductionListingReport"),
              onClick: goTo(RouteNames.StockReductionListingReportPage),
              security: {rights: [UserRight.StockIssuesReport]},
            },

            {
              id: RouteNames.StockManualSaleListingReportPage,
              label: t("core:Term.ManualSalesListingReport"),
              onClick: goTo(RouteNames.StockManualSaleListingReportPage),
              security: {rights: [UserRight.StockManualSalesReport]},
            },

            {
              id: RouteNames.StockTakeListingReportPage,
              label: t("core:Term.StockTakeListingReport"),
              onClick: goTo(RouteNames.StockTakeListingReportPage),
              security: {rights: [UserRight.StockTakeReport]},
            },

            {
              id: RouteNames.StockRecipeCostPriceListingReportPage,
              label: t("core:Term.StockRecipeCostPriceListingReportPage"),
              onClick: goTo(RouteNames.StockRecipeCostPriceListingReportPage),
              security: {rights: [UserRight.StockRecipeCostPriceListingReport]},
            },

          ]
          .sort((a, b) => Sorter.compare(a.label, b.label)),
        },

        {
          id: "reports/stock/summary",
          label: t("core:Term.StockSummaryReports"),
          children: [

            {
              id: RouteNames.PartialStockStoreSummaryReportPage,
              label: t("core:Term.PartialStoreSummaryReport"),
              onClick: goTo(RouteNames.PartialStockStoreSummaryReportPage),
              security: {rights: [UserRight.StockStoreSummaryReport]},
            },

            {
              id: RouteNames.StockStoreSummaryReportPage,
              label: t("core:Term.StoreSummaryReport"),
              onClick: goTo(RouteNames.StockStoreSummaryReportPage),
              security: {rights: [UserRight.StockStoreSummaryReport]},
            },

            {
              id: RouteNames.StockGroupSummaryReportPage,
              label: t("core:Term.GroupSummaryReport"),
              onClick: goTo(RouteNames.StockGroupSummaryReportPage),
              security: {rights: [UserRight.StockGroupSummaryReport]},
            },

            {
              id: RouteNames.StockCategorySummaryReportPage,
              label: t("core:Term.CategorySummaryReport"),
              onClick: goTo(RouteNames.StockCategorySummaryReportPage),
              security: {rights: [UserRight.StockCategorySummaryReport]},
            },

            {
              id: RouteNames.StockReductionCategorySummaryReportPage,
              label: t("core:Term.ReductionCategorySummaryReport"),
              onClick: goTo(RouteNames.StockReductionCategorySummaryReportPage),
              security: {rights: [UserRight.StockReductionCategorySummaryReport]},
            },

          ],
        },

        {
          id: "reports/stock/variance",
          label: t("core:Term.StockVarianceReports"),
          children: [

            {
              id: RouteNames.StockVarianceReportPage,
              label: t("core:Term.StockVarianceReport"),
              onClick: goTo(RouteNames.StockVarianceReportPage),
              security: {rights: [UserRight.StockVarianceReport]},
            },

            {
              id: RouteNames.PeriodicStockVarianceReportPage,
              label: t("core:Term.PeriodicStockVarianceReport"),
              onClick: goTo(RouteNames.PeriodicStockVarianceReportPage),
              security: {rights: [UserRight.PeriodicStockVarianceReport]},
            },

          ].sort((a, b) => Sorter.compare(a.label, b.label)),
        },
      ]
      .sort((a, b) => Sorter.compareAll(
          Sorter.Comparator(!!b.children?.length, !!a.children?.length),
          Sorter.Comparator(a.name, b.name),
      )),
    },

    {
      id: RouteNames.PointOfSaleReportPage,
      label: `${t("core:Term.PointOfSales")} (${t("core:Term.SeeAll")})`,
      onClick: goTo(RouteNames.PointOfSaleReportPage),
      security: {
        rights: [
          UserRight.PaymentAnalysisReport,
          UserRight.OutletAnalysisReport,
          UserRight.TransactionAuditReport,
          UserRight.TransactionTransferReport,
          UserRight.TopSellersReport,
          UserRight.UserAnalysisReport,
          UserRight.DiscountTransactionReport,
          UserRight.AdditionalTaxesReport,
          UserRight.DetailedPaymentAnalysisReport,
          UserRight.ForexPaymentAnalysisReport,
        ],
        requireAllRights: false,
      },
    },

    {
      id: RouteNames.StockReportPage,
      label: `${t("core:Term.Stock")} (${t("core:Term.SeeAll")})`,
      onClick: goTo(RouteNames.StockReportPage),
      security: {
        rights: [
          UserRight.StockItemActivityReport,
          UserRight.StockMovementReport,
          UserRight.StockStoreSummaryReport,
          UserRight.StockGroupSummaryReport,
          UserRight.StockCategorySummaryReport,
          UserRight.StockValueReport,
          UserRight.StockVarianceReport,
          UserRight.StockDeliveriesReport,
          UserRight.PeriodicStockVarianceReport,
          UserRight.StockOrdersReport,
          UserRight.StockRequisitionsReport,
          UserRight.StockIssuesReport,
          UserRight.StockReductionReport,
          UserRight.StockManualSalesReport,
          UserRight.StockTakeReport,
          UserRight.GlobalStockOrderListingReport,
          UserRight.GlobalStockDeliveryListingReport,
        ],
        requireAllRights: false,
      },
    },

    {
      id: RouteNames.GlExportPage,
      label: t("core:Term.GlExport"),
      onClick: goTo(RouteNames.GlExportPage),
      security: {rights: [UserRight.ManageGLExport]},
    },
    {
      id: RouteNames.AccountingExportReportPage,
      label: t("core:Term.AccountingExport"),
      onClick: goTo(RouteNames.AccountingExportReportPage),
      security: {rights: [UserRight.ManageGLExport]},
    },

    {
      id: RouteNames.DataExportPage,
      label: "Data Export",
      onClick: goTo(RouteNames.DataExportPage),
      security: {rights: [UserRight.DataExport]},
    },

    {
      id: RouteNames.ArmReportPage,
      label: t("glossary:AccountsReceivable"),
      onClick: goTo(RouteNames.ArmReportPage),
      security: {
        rights: [
          UserRight.AccountsReceivableTransactionReport,
        ],
        requireAllRights: false,
      },
    },

    {
      id: RouteNames.UserLoginReportPage,
      label: t("glossary:UserLogin"),
      onClick: goTo(RouteNames.UserLoginReportPage),
      security: {rights: [UserRight.UserLoginReport]},
    },

  ],
});

export default ReportNode;
