/*
 * Created by Stanton J Francis on 22 June 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockOrderTemplateActionTypes
  from "../constants/action-types/stockOrderTemplateActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for stock orders.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockOrderTemplateActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockOrderTemplateActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case StockOrderTemplateActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockOrderTemplateActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case StockOrderTemplateActionTypes.CREATE:
      return create(state, action);

    case StockOrderTemplateActionTypes.UPDATE:
      return update(state, action);

    case StockOrderTemplateActionTypes.DELETE:
      return _delete(state, action);

    case StockOrderTemplateActionTypes.DELETE_LINE_ITEM:
      return deleteLineItem(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new stock order template to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock order template in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted stock order template from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted line item from an existing stock order template entity.
 */
const deleteLineItem = (state, action) => {
  const {id, lineItemId} = action.data;
  const next = state.data.map(e => (
      e.id === id
          ? {...e, items: e.items?.filter(i => i.id !== lineItemId)}
          : e
  ));
  return StateUtility.defaultSet(state, next);
};
