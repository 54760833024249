/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CustomMenuActionTypes
  from "../constants/action-types/customMenuActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of custom menus.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CustomMenuActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CustomMenuActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CustomMenuActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case CustomMenuActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case CustomMenuActionTypes.CREATE:
      return create(state, action);

    case CustomMenuActionTypes.UPDATE:
      return update(state, action);

    case CustomMenuActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new custom menu to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing custom menu in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted custom menu from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
