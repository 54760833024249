/*
 * Created by Paul Engelke on 25 August 2021.
 */

import {Sorter} from "@hti-ui/js-core";
import {Autocomplete} from "@hti-ui/react-web-material";
import {useActions} from "@hti-ui/redux-core";
import {useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {fetchCountries} from "../../actions/countryActions";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import PropTypes from "prop-types";

/**
 * An autocomplete for finding and setting a country.
 */
const CountryAutocomplete = props => {

  const {handleError} = useGlobalMessenger();
  const [_fetchCountries] = useActions([fetchCountries]);
  useEffect(() => {
    _fetchCountries()
    .catch(e => handleError(e, "Unable to fetch a list of countries."));
  }, []);

  const countries = useSelector(state => state.countries.data);
  const options = useMemo(() =>
          countries.slice()
          .sort((a, b) => Sorter.compare(a.name, b.name)),
      [countries]);

  const serializeCountry = useCallback(c => c?.name ?? "", []);
  const isSelectedCountry = useCallback((o, v) => v?.id && o.id === v.id, []);

  return (<Autocomplete
      label={"Country"}
      serializeOption={serializeCountry}
      isSelectedOption={isSelectedCountry}
      options={options}
      {...props}
  />);

};

CountryAutocomplete.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  disableClearable: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CountryAutocomplete;
