/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for the property redux state.
 */
const PropertyActionTypes = ActionTypeUtility.createActionTypes('PROPERTIES', {
  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  UPDATE_PROPERTY_IMAGE: 'UPDATE_PROPERTY_IMAGE',
  DELETE_PROPERTY_IMAGE: 'DELETE_PROPERTY_IMAGE'
});

export default PropertyActionTypes;
