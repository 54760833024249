/*
 * Created by Paul Engelke on 09 March 2023.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import CampaignActionTypes from "../constants/action-types/campaignActionTypes";

const defaultState = StateUtility.State(
  {data: []},
  {async: true, asyncOptions: {useCounter: true}},
);

/**
 * A reducer for maintaining campaign data.
 */
export default (state = defaultState, action) => {

  switch (action.type) {

    case CampaignActionTypes.Request:
      return StateUtility.defaultRequest(state);

    case CampaignActionTypes.CompleteRequest:
      return StateUtility.defaultFailRequest(state);

    case CampaignActionTypes.FailRequest:
      return StateUtility.defaultCompleteRequest(state);

    case CampaignActionTypes.Set:
      return StateUtility.defaultSet(state, action.data);

    case CampaignActionTypes.Merge:
      return merge(state, action);

    case CampaignActionTypes.Create:
      return create(state, action);

    case CampaignActionTypes.Update:
      return update(state, action);

    case CampaignActionTypes.Delete:
      return _delete(state, action);

    case CampaignActionTypes.SetSchedules:
      return setSchedules(state, action);

    case CampaignActionTypes.CreateSchedule:
      return createSchedule(state, action);

    case CampaignActionTypes.UpdateSchedule:
      return updateSchedule(state, action);

    case CampaignActionTypes.DeleteSchedule:
      return deleteSchedule(state, action);

    case GlobalActionTypes.RESET:
      return defaultState;

    default:
      return state;

  }

};

const merge = (state, action) => {
  const next = StateUtility.mergeData(state.data, action.data);
  return StateUtility.defaultSet(state, next);
};

const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

const setSchedules = (state, action) => {
  const {campaignId, schedules} = action.data;
  const next = state.data.map(c => c.id === campaignId ? {...c, schedules} : c);
  return StateUtility.defaultSet(state, next);
};

const createSchedule = (state, action) => {
  const schedule = action.data;
  const next = state.data.map(c => (
    c.id === schedule.campaignId
      ? {...c, schedules: [...(c.schedules ?? []), schedule]}
      : c
  ));
  return StateUtility.defaultSet(state, next);
};

const updateSchedule = (state, action) => {
  const schedule = action.data;
  const next = state.data.map(c => (
    c.id === schedule.campaignId
      ? {...c, schedules: c.schedules?.map(s => s.id === schedule.id ? schedule : s)}
      : c
  ));
  return StateUtility.defaultSet(state, next);
};

const deleteSchedule = (state, action) => {
  const schedule = action.data;
  const next = state.data.map(c => (
    c.id === schedule.campaignId
      ? {...c, schedules: c.schedules?.filter(s => s.id !== schedule.id)}
      : c
  ));
  return StateUtility.defaultSet(state, next);
};
