/*
 * Created by Stanton J Francis on 23 October 2021.
 */

import {SupervisedUserCircleOutlined as CustomersIcon} from '@material-ui/icons';
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality that only Customer Management should have access to. Currently
 * this includes customers with the type of HTI or VAR Partner
 */
const CustomerManagementNode = ({goTo, t}) => ({
  id: "management",
  label: t("core:Term.CustomerManagement"),
  quickLink: {IconComponent: CustomersIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [
    {
      id: RouteNames.CustomerPage,
      label: t("core:Term.Customers"),
      onClick: goTo(RouteNames.CustomerPage),
      security: {rights: [UserRight.ManageCustomers]},
    },
    {
      id: RouteNames.PropertyAdminPage,
      label: t("core:Term.PropertyAdministration"),
      onClick: goTo(RouteNames.PropertyAdminPage),
      security: {rights: [UserRight.PropertyAdministrator]},
    },
  ]
});

export default CustomerManagementNode;
