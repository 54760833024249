/*
 * Created by Kevin May 2021.
 */
import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import GlExportActionTypes
  from "../constants/action-types/glExportActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux reducer for the license state.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case GlExportActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case GlExportActionTypes.REQUEST_WRITE:
      return StateUtility.defaultRequest(state);

    case GlExportActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case GlExportActionTypes.RECEIVED_DATA:
      return StateUtility.defaultSet(state, [...action.data]) ;


    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }
};

