/*
 * Created by Stanton J Francis on 4 April 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ReasonActionTypes from "../constants/action-types/reasonActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for reasons
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ReasonActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ReasonActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ReasonActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case ReasonActionTypes.CREATE:
      return create(state, action);

    case ReasonActionTypes.UPDATE:
      return update(state, action);

    case ReasonActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new reason to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing reason in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted reason from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
