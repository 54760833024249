/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the stock-take redux state.
 */
const StockTakeActionTypes = ActionTypeUtility
.createActionTypes('STOCK_TAKES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  SET_ENTRIES: 'SET_ENTRIES',
  UPDATE_ENTRY: 'UPDATE_ENTRY',
  DELETE_ENTRY: 'DELETE_ENTRY',
});

export default StockTakeActionTypes;
