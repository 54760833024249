/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the delivery interface redux state.
 */
const DeliveryInterfaceActionTypes = ActionTypeUtility
.createActionTypes('DELIVERY_INTERFACES', {

  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',

  SET: 'SET',
  SET_POS_INTEGRATION: 'SET_POS_INTEGRATION',

  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',

});

export default DeliveryInterfaceActionTypes;
