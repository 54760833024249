/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {LoadingIndicator, Page, PageContent} from "@hti-ui/react-web-material";
import {StateUtility} from "@hti-ui/redux-core";
import {makeStyles} from "@material-ui/core";
import {useSelector} from "react-redux";
import {useImageAssets} from "../../assets/images";
import AuthOptionForm from "./AuthOptionForm";
import Footer from "./Footer";
import Header from "./Header";
import LoginForm from "./LoginForm";

/**
 * A page for authenticating users.
 */
const LoginPage = () => {

  const classes = useStyles();
  const ImageAssets = useImageAssets();
  const {loading} = useSelector(state => ({
    loading: StateUtility.isBusy(state.auth),
  }));

  return (<Page>

    <LoadingIndicator visible={loading}/>

    <Header/>

    <PageContent>

      <div className={classes.formRoot}>

        <img
            className={classes.productLogo}
            alt={'nebula-pos-logo'}
            src={ImageAssets.PosLogoHorizontal}
        />

        <div className={classes.formContent}>

          <div className={classes.formColumn}>
            <LoginForm disabled={loading}/>
          </div>

          <div className={classes.formDividerRoot}>
            <div className={classes.formDividerLine}/>
            <p className={classes.formDividerText}>OR</p>
            <div className={classes.formDividerLine}/>
          </div>

          <div className={classes.formColumn}>
            <AuthOptionForm disabled={loading}/>
          </div>

        </div>

      </div>

    </PageContent>

    <Footer/>

  </Page>);

};

const useStyles = makeStyles(theme => ({
  productLogo: {
    width: 'calc(100vw / 5)',
    minWidth: 300,
    marginBottom: theme.spacing(3),
  },
  formRoot: {
    height: 'calc(100vh - 100px)',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  formContent: {
    minHeight: 200,
    width: 800,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: theme.spacing(),
    border: `1px solid ${theme.palette.compliment['50%']}`,
    padding: theme.spacing(2),
  },
  formColumn: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formDividerRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formDividerLine: {
    width: 1,
    backgroundColor: theme.palette.compliment['50%'],
    flex: 1,
  },
  formDividerText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
}));

export default LoginPage;
