/*
 * Created by Paul Engelke on 20 January 2023.
 */

import PropTypes from "prop-types";
// import {useHistory} from "react-router-dom";
// import {useCallback} from "react";
// import RouteNames from "../../constants/routeNames";
import {Button} from "@material-ui/core";
import {ContactMail as NewAccountIcon} from '@material-ui/icons';
// import {PersonAddRounded as NewAccountIcon} from '@material-ui/icons';

/**
 * A button on the sign-in page for navigating to the account registration page.
 */
const CreateAccountButton = props => {

  // const history = useHistory();

  // const onRegisterClicked = useCallback(
  //     () => history.push(RouteNames.AccountRegistrationPage),
  //     [history]);

  return (<Button
      variant={"contained"}
      color={"secondary"}
      // onClick={onRegisterClicked}
      disabled={props.disabled}
      target={'_blank'} rel={'noopener noreferrer'}
      href={'https://pos.nebulaone.io/contact'}
  >
    <NewAccountIcon style={{marginRight: 8}}/>
    Contact Us
    {/*Create Account*/}
  </Button>);

};

CreateAccountButton.propTypes = {
  disabled: PropTypes.bool,
};

export default CreateAccountButton;
