/*
 * Created by Stanton J Francis 19 July 2024.
 */

import {Page, PageContent} from "@hti-ui/react-web-material";
import {makeStyles,} from "@material-ui/core";
import {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useImageAssets} from "../../assets/images";
import RouteNames from "../../constants/routeNames";
import LoadingDialog from "../BarcodePage/DownloadDialog/LoadingDialog";
import {signInAndInitialize,} from "../../actions/authActions";
import {useActions} from "@hti-ui/redux-core";
import {LoginType} from "../../constants/loginType";
import {AppType} from "../../constants/appType";

/**
 * A page for notifying the user that their account is being activated.
 */
const UserActivationPage = () => {

  const {token} = useParams();
  const ImageAssets = useImageAssets();
  const classes = useStyles();
  const history = useHistory();
  const signIn = useActions(signInAndInitialize);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token && token.length > 0) {
      signIn(token, null, {
        loginType: LoginType.STANDARD_LOGIN,
        appType: AppType.WEB_APP
      }).then(() => {
        setLoading(false);
        history.push(RouteNames.Root);
      })
      .catch(() => {
        setLoading(false);
        history.push(RouteNames.LoginPage);
      });
    }
  }, [token]);

  // noinspection JSValidateTypes
  return (<Page>

    <PageContent>

      <div className={classes.formRoot}>

        <img
            className={classes.productLogo}
            alt={'nebula-pos-logo'}
            src={ImageAssets.PosLogoHorizontal}
        />
        <LoadingDialog open={loading}/>
      </div>

    </PageContent>

  </Page>);

};

const useStyles = makeStyles(theme => ({
  productLogo: {
    width: 'calc(100vw / 4)',
    minWidth: 300,
    marginBottom: theme.spacing(24),
  },
  formRoot: {
    height: 'calc(100vh - 100px)',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
}));

export default UserActivationPage;
