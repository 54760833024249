/*
 * Created by Stanton J Francis on 13 September 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing a list of arm profiles.
 */
const ArmProfileActionTypes = ActionTypeUtility
.createActionTypes('ARM_PROFILES', {
  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  UNLINK_LEVEL: 'UNLINK_LEVEL',
});

export default ArmProfileActionTypes;
