/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {useMemo} from 'react';
import {useSelector} from "react-redux";
import SecurityUtility from "../utils/securityUtility";

/**
 * Checks if the current user has all the user rights in the given list.
 *
 * @param {Object[]} rights A set of user rights that the current user requires
 * to gain access to some feature.
 * @param {boolean} [all=true] If true, then the user must be authorized for
 * all specified user rights. If false, then the user only needs authorization
 * for at least on of the rights.
 * @return {boolean}
 */
const useUserAuthorization = (rights, all = true) => {

  const {user, userRole, licence, propertyId} = useSelector(state => ({
    user: state.session.user,
    userRole: state.session.userRole,
    licence: state.workspace.customerLicence,
    propertyId: state.workspace.property?.id,
  }));

  const _rights = rights?.map(r => r.id).sort().join('-');
  return useMemo(() => (
      SecurityUtility.isLicensedAndAuthorized({
        user, licence,
        userRole, rights, all,
        propertyId, skipPropertyCheck: !propertyId,
      })
  ), [user, userRole, licence, propertyId, _rights]);

};

export default useUserAuthorization;

