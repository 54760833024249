/*
 * Created by Kevin May 2021.
 */
import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import GLMappingsActionTypes
  from "../constants/action-types/glMappingsActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of gl mappings.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case GLMappingsActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case GLMappingsActionTypes.REQUEST_WRITE:
      return StateUtility.defaultRequest(state);

    case GLMappingsActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case GLMappingsActionTypes.FAIL_REQUEST_WRITE:
      return StateUtility.defaultFailRequest(state);

    case GLMappingsActionTypes.RECEIVED_DATA:
      return StateUtility.defaultSet(state, action.data);

    case GLMappingsActionTypes.SET:
      return StateUtility.defaultSet(state, action);

    case GLMappingsActionTypes.CREATE:
      return StateUtility.defaultSet(state, [...state.data, action.data]);

    case GLMappingsActionTypes.UPDATE:
      return StateUtility.defaultSet(
          state,
          state.data.map(ur => ur.id === action.data.id ? action.data : ur)
      );

    case GLMappingsActionTypes.DELETE:
      return StateUtility.defaultSet(
          state,
          state.data.filter(ur => ur.id !== action.id)
      );

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }

};

