/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {Box, TextField} from "@hti-ui/react-web-material";
import {useActions} from "@hti-ui/redux-core";
import {Button, Link} from "@material-ui/core";
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useHistory} from "react-router-dom";
import {signInWithCredentials} from "../../actions/authActions";
import RouteNames from "../../constants/routeNames";
import useErrorHandler from "./useErrorHandler";
import PasswordField from "../common/PasswordField";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import ForgotPasswordDialog from "./ForgotPasswordDialog";

/**
 * The email/password login form for the Login Page.
 */
const LoginForm = props => {

  const {disabled} = props;
  const signIn = useActions(signInWithCredentials);

  const handleError = useErrorHandler();
  const {dispatchErrorMessage} = useGlobalMessenger();
  const history = useHistory();

  const [showForgotPasswordDialod, setShowForgotPasswordDialod] = useState(
      false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  const disableLoginButton = !emailAddress || !password;

  const onForgotPasswordClicked = useCallback(() => {
    setShowForgotPasswordDialod(true);
  }, [setShowForgotPasswordDialod]);

  const closeForgotPasswordDialog = useCallback(() => {
    setShowForgotPasswordDialod(false);
  }, [setShowForgotPasswordDialod]);

  const onSignInClicked = useCallback(() => {
    signIn({emailAddress, password})
    .then((r) => {
      if (r?.data?.usePolicy) {
        const {retriesLeft, lockedReason = null} = r.data;
        if (lockedReason) {
          dispatchErrorMessage(
              `${lockedReason} To regain access use the 'Forgot Password' option or contact your system administrator.`);
        } else {
          dispatchErrorMessage(
              `Login failed, you have ${retriesLeft} remaining attempts until your account is locked.`);
        }
      } else {
        // Redirect to the Root route upon successful login
        history.push(RouteNames.Root);
      }
    })
    .catch(error => {
      handleError(error);
    });
  }, [
    emailAddress, password, history,
    signIn, handleError,
  ]);

  const onEnterPressed = useCallback((e) => {
    if (e?.key === 'Enter' && !!emailAddress && !!password) {
      onSignInClicked();
    }
  }, [emailAddress, password, onSignInClicked]);

  return (<>

    <Box width={'100%'}>

      <Box bottomPadding={1}>
        <TextField
            label={'Email Address'}
            value={emailAddress}
            onChange={setEmailAddress}
            fullWidth
            onKeyDown={onEnterPressed}
        />
      </Box>

      <Box bottomPadding={2}>
        <PasswordField
            type={'password'}
            label={'Password'}
            value={password}
            onChange={setPassword}
            fullWidth
            onKeyDown={onEnterPressed}
        />
        <Link href="#" color="secondary"
              style={{textAlign: 'right', marginTop: '8px'}} onClick={(e) => {
          e.preventDefault();
          onForgotPasswordClicked();
        }}>
          Forgot Password?
        </Link>
      </Box>

    </Box>

    <Box width={'100%'} alignItems={'center'}>
      <Button
          variant={'contained'}
          color={'secondary'}
          onClick={onSignInClicked}
          disabled={disabled || disableLoginButton}
      >Sign In</Button>
    </Box>

    <ForgotPasswordDialog open={showForgotPasswordDialod}
                          onDismiss={closeForgotPasswordDialog}/>

  </>);

};

LoginForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default LoginForm;
