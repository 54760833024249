/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the workspace for the current user as a
 * redux state part.
 */
const WorkspaceActionTypes = ActionTypeUtility.createActionTypes('WORKSPACE', {

  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_PROPERTY: 'SET_PROPERTY',
  SET_CUSTOMER_LICENCE: 'SET_CUSTOMER_LICENCE',

});

export default WorkspaceActionTypes;
