/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {useActions} from "@hti-ui/redux-core";
import {useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {fetchProperties} from "../actions/propertyActions";
import SecurityUtility from "../utils/securityUtility";
import useCanAccessCustomers from "./useCanAccessCustomers";
import useGlobalMessenger from "./useGlobalMessenger";

/**
 * Fetches and filters a list of properties based on the current user's
 * property access rights.
 */
const useAccessibleProperties = () => {

  const {
    customerId, properties,
    user, userRole, propertyAccess,
  } = useSelector(state => ({
    customerId: state.workspace.customer?.id,
    properties: state.properties.data,
    user: state.session.user,
    userRole: state.session.userRole,
    propertyAccess: state.session.propertyAccess,
  }));

  const {handleError} = useGlobalMessenger();
  const [_fetchProperties] = useActions([fetchProperties]);

  useEffect(() => {
    if (customerId != null) {
      _fetchProperties({customerId})
      .catch(e => void handleError(e, 'Unable to fetch a list of properties.'));
    }
  }, [customerId]);

  const canSelectProperties = useCanAccessCustomers();
  const unrestricted = useMemo(() =>
      SecurityUtility.isUnrestricted({user, userRole}), [user, userRole]);

  const accessSet = useMemo(() => {
    const set = new Set();
    propertyAccess?.forEach(({propertyID}) => void set.add(propertyID));
    return set;
  }, [propertyAccess]);

  return useMemo(() => (
      unrestricted || canSelectProperties
          ? properties
          : properties.filter(p => accessSet.has(p.id))
  ), [unrestricted, accessSet, properties, canSelectProperties]);

};

export default useAccessibleProperties;
