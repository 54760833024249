/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import PropertyActionTypes from "../constants/action-types/propertyActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of properties.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case PropertyActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case PropertyActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case PropertyActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case PropertyActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case PropertyActionTypes.CREATE:
      return createProperty(state, action);

    case PropertyActionTypes.UPDATE:
      return updateProperty(state, action);

    case PropertyActionTypes.DELETE:
      return deleteProperty(state, action);

    case PropertyActionTypes.UPDATE_PROPERTY_IMAGE:
      return updatePropertyImage(state, action)

    case PropertyActionTypes.DELETE_PROPERTY_IMAGE:
      return deletePropertyImage(state, action)

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new property to the list.
 */
const createProperty = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing property in the list.
 */
const updateProperty = (state, action) => {
  const updated = action.data;
  const next = state.data.map(p => p.id === updated.id ? updated : p);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted property from the list.
 */
const deleteProperty = (state, action) => {
  const next = state.data.filter(p => p.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing property in the list with the image.
 */
const updatePropertyImage = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.propertyId ? {
    ...e,
    base64image: updated?.base64image
  } : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Deletes an existing property's image.
 */
const deletePropertyImage = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? {
    ...e,
    base64image: null
  } : e);
  return StateUtility.defaultSet(state, next);
};
