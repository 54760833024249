/*
 * Created by Stanton J Francis on 06 October 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the customer type redux state.
 */
const CustomerTypeActionTypes = ActionTypeUtility
.createActionTypes('CUSTOMER_TYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default CustomerTypeActionTypes;
