/*
 * Created by Paul Engelke on 24 June 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import GlobalStockOrderActionTypes
  from "../constants/action-types/globalStockOrderActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A reducer for global stock orders.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case GlobalStockOrderActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case GlobalStockOrderActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case GlobalStockOrderActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case GlobalStockOrderActionTypes.UPDATE:
      return update(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }
};

/**
 * Updates the status of a global order. This is done by a source customer
 * or property when an order is accepted, rejected, or cancelled.
 *
 * @param state The current reducer state.
 * @param action The dispatched action.
 * @return {Object} The next state.
 */
const update = (state, action) => {
  const {data} = action;
  const next = state.data.map(o => (o.id === data.id ? data : o));
  return StateUtility.defaultSet(state, next);
};
