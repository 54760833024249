/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the app preference redux state.
 */
const AppPreferenceActionTypes = ActionTypeUtility.createActionTypes(
    "APP_PREFERENCES",
    {
      SET: "SET",
      SET_THEME: "SET_THEME",
      SET_LANGUAGE: "SET_LANGUAGE",
    },
);

export default AppPreferenceActionTypes;
