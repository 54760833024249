/*
 * Created by Stanton J Francis on 22 August 2024.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import OTPRequiredActionTypes
  from "../constants/action-types/otpRequiredActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of custom menus.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case OTPRequiredActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case OTPRequiredActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case OTPRequiredActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

