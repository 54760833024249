/*
 * Created by Paul Engelke on 14 September 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for manipulating the stock division manufacture redux
 * state.
 */
const StockDivisionManufactureActionTypes = ActionTypeUtility
.createActionTypes("STOCK_DIVISION_MANUFACTURE", {

  REQUEST: "REQUEST",
  FAIL_REQUEST: "FAIL_REQUEST",
  COMPLETE_REQUEST: "COMPLETE_REQUEST",

  SET: "SET",
  MERGE: "MERGE",

  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  DELETE_LINE_ITEM: "DELETE_LINE_ITEM",

});

export default StockDivisionManufactureActionTypes;
