/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {Tooltip} from "@hti-ui/react-web-material";
import {StateUtility} from "@hti-ui/redux-core";
import {ButtonBase, makeStyles, Typography} from "@material-ui/core";
import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import UserCard from "./UserCard";

/**
 * A button that identifies the current user. It can be clicked to see
 * additional information and actions.
 */
const UserWidget = () => {

  const classes = useStyles();
  const {t} = useTranslation("App");

  const user = useSelector(state => state.session.user);
  const loading = useSelector(state => StateUtility.isBusy(state.session));

  const firstName = user?.firstName ?? "";
  const lastName = user?.lastName ?? "";
  const fullName = [firstName, lastName].join(" ");
  const initials = [firstName, lastName].map(s => s.charAt(0)).join("");

  const [anchor, setAnchor] = useState(null);
  const _setAnchor = useCallback(e => setAnchor(e.currentTarget), []);
  const clearAnchor = useCallback(() => setAnchor(null), []);

  return (<>

    <Tooltip content={t("App:UserWidget.SignedInAsX", {x: fullName})}>
      <ButtonBase
          className={classes.button}
          onClick={_setAnchor}
          disabled={loading}
      >
        <Typography className={classes.buttonContent}>{initials}</Typography>
      </ButtonBase>
    </Tooltip>

    <UserCard
        anchor={anchor}
        onClose={clearAnchor}
        fullName={fullName}
        initials={initials}
    />

  </>);

};

const useStyles = makeStyles(theme => ({
  button: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.compliment['30%']}`,
  },
  buttonContent: {
    fontSize: 'medium',
    fontWeight: 500,
  },
}));

export default UserWidget;
