/*
 * Created by Paul Engelke on 10 August 2022.
 */

import ObjectUtility from "../utils/objectUtility";
import {OperatingSystems} from "@hti-ui/react-web-material";

/**
 * A set of common or global keyboard shortcuts used throughout NebulaPOS.
 */
const KeyboardShortcuts = ObjectUtility.deepFreeze({

  /**
   * Adds a new line item to some new or existing unconfirmed stock process.
   */
  AddStockLineItem: {
    [OperatingSystems.MacOS]: ["control", "="],
    default: ["control", "alt", "="],
  },

  /**
   * Opens the primary navigation drawer.
   */
  OpenNavigationDrawer: {
    [OperatingSystems.MacOS]: ["meta", "shift", "k"],
    default: ["control", "shift", "k"],
  },

});

export default KeyboardShortcuts;
