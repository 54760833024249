/*
 * Created by Paul Engelke on 06 April 2021.
 */

import RegistrationActionTypes
  from "../constants/action-types/registrationActionTypes";
import AppStatusCode from "../constants/codes/appStatusCodes";
import StdError from "../utils/error/stdError";
import HttpManager from "../utils/httpManager";
import SecurityUtility from "../utils/securityUtility";
import {signInAndInitialize} from "./authActions";

/**
 * Registers new customer account.
 * @param {Object} args The account registration details.
 * @return {function(*,*): Promise}
 */
export const registerCustomerAccount = args => dispatch => {
  dispatch({type: RegistrationActionTypes.REQUEST});
  return HttpManager.post('registration', args)
  .then(r => {
    dispatch({type: RegistrationActionTypes.COMPLETE_REQUEST});
    return r;
  })
  .catch(e => {
    dispatch({type: RegistrationActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Registers an account for a Google user and authenticates the user.
 * @param args.idToken The user's Google ID token.
 * @return {function(*, *): void}
 */
export const registerWithGoogle = args => async (dispatch, getState) => {

  try {

    dispatch({type: RegistrationActionTypes.REQUEST});
    const response = await HttpManager
    .post("registration/google", args.idToken);

    const token = parseToken(response);
    await signInAndInitialize(token)(dispatch, getState);
    dispatch({type: RegistrationActionTypes.COMPLETE_REQUEST});

  } catch (e) {
    dispatch({type: RegistrationActionTypes.FAIL_REQUEST});
    throw e;
  }

};

/**
 * A helper method that parses and validates the token received in a response
 * from the server.
 *
 * @param {Object} response The response object.
 * @return {string}
 */
const parseToken = (response) => {

  const token = response?.data?.token;
  const _token = SecurityUtility.parseToken(token);

  if (!_token) {
    throw new StdError()
    .setCode(AppStatusCode.Unauthenticated)
    .setMessage("The token is invalid.");
  }

  return token;

};

/**
 * Verifies the ReCaptcha token
 * @param {Object} args The token to be verified
 * @return {function(*,*): Promise}
 */
export const verifyReCaptchaToken = args => dispatch => {
  dispatch({type: RegistrationActionTypes.REQUEST});
  return HttpManager.post('registration/verify-token', args)
  .then(r => {
    dispatch({type: RegistrationActionTypes.COMPLETE_REQUEST});
    return r;
  })
  .catch(e => {
    dispatch({type: RegistrationActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Emails an invitation to a user.
 * @param {Object} args The invitation details.
 * @return {function(*,*): Promise}
 */
export const emailUserInvitation = args => dispatch => {
  dispatch({type: RegistrationActionTypes.REQUEST});
  return HttpManager.post('registration/email/invite', args)
  .then(r => {
    dispatch({type: RegistrationActionTypes.COMPLETE_REQUEST});
    return r;
  })
  .catch(e => {
    dispatch({type: RegistrationActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Emails a password reset to a user.
 * @param {Object} args The invitation details.
 * @return {function(*,*): Promise}
 */
export const emailPasswordReset = args => dispatch => {
  dispatch({type: RegistrationActionTypes.REQUEST});
  return HttpManager.post('registration/email/reset', args)
  .then(r => {
    dispatch({type: RegistrationActionTypes.COMPLETE_REQUEST});
    return r;
  })
  .catch(e => {
    dispatch({type: RegistrationActionTypes.FAIL_REQUEST});
    throw e;
  });
};
