/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {
  CacheLifetime,
  LocalCache,
  LocalCacheRegistry
} from "@hti-ui/local-cache";
import UserActionTypes from "../constants/action-types/userActionTypes";
import HttpManager from "../utils/httpManager";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * Fetches a list of users for a customer.
 * @param {number} [args.customerId=workspace.customer.id] The customer ID.
 * @param {boolean} [args.force=false] Should cached data be invalidated?
 * @return {function(*, *): Promise}
 */
export const fetchUsersByCustomer = args => (dispatch, getState) => {

  const defaultCustomerId = getState().workspace?.customer?.id;
  const {customerId = defaultCustomerId, force = false} = args ?? {};
  const url = `users/${customerId}`;

  if (!force && cache.has(url)) {
    return cache.get(url);
  }

  cache.clear();
  const request = HttpManager.get(url);
  cache.set(url, CacheLifetime.SHORT, request);

  dispatch({type: UserActionTypes.REQUEST});
  return request
  .then(r => {
    dispatch({type: UserActionTypes.SET, data: r.data});
    return r;
  })
  .catch(e => {
    cache.delete(url);
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Creates a new user.
 * @param {Object} args The new user details.
 * @return {function(*,*): Promise}
 */
export const createUser = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.post('users', args)
  .then(r => {
    dispatch({type: UserActionTypes.CREATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Updates an existing user.
 * @param {Object} args The updated user details.
 * @return {function(*,*): Promise}
 */
export const updateUser = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.put(`users`, args)
  .then(r => {
    dispatch({type: UserActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Updates an existing user's password.
 * @param {Object} args The updated user details.
 * @return {function(*,*): Promise}
 */
export const updateUserPassword = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.put(`users/password`, args)
  .then(r => {
    dispatch({type: UserActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Deletes an existing user.
 * @param {number} args.id The user's ID.
 * @return {function(*,*): Promise}
 */
export const deleteUser = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.delete(`users/${args.id}`)
  .then(r => {
    dispatch({type: UserActionTypes.DELETE, data: args});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Links a uuid to an existing user.
 * @param {Object} args The updated user details.
 * @return {function(*,*): Promise}
 */
export const linkUUIDToUser = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.put(`users/link-uuid`, args)
  .then(r => {
    dispatch({type: UserActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Unlinks a uuid from an existing user.
 * @param {number} args.id The user's ID.
 * @return {function(*,*): Promise}
 */
export const unlinkUUIDFromUser = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.put(`users/unlink-uuid/${args.id}`)
  .then(r => {
    dispatch({type: UserActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Links an nfc card to an existing user.
 * @param {Object} args The updated user details.
 * @return {function(*,*): Promise}
 */
export const linkNfcCardToUser = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.put(`users/link-nfc-card`, args)
  .then(r => {
    dispatch({type: UserActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Unlinks an nfc card from an existing user.
 * @param {number} args.id The user's ID.
 * @return {function(*,*): Promise}
 */
export const unlinkNfcCardFromUser = args => dispatch => {
  dispatch({type: UserActionTypes.REQUEST});
  return HttpManager.put(`users/unlink-nfc-card/${args.id}`)
  .then(r => {
    dispatch({type: UserActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: UserActionTypes.FAIL_REQUEST});
    throw e;
  });
};
