/*
 * Created by Paul Engelke on 16 March 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A list of action types for the user action history redux state.
 */
const UserActionHistoryActionTypes = ActionTypeUtility.createActionTypes(
    "USER_ACTION_HISTORY",
    {
      REQUEST: "REQUEST",
      FAIL_REQUEST: "FAIL_REQUEST",
      SET: "SET",
    },
);

export default UserActionHistoryActionTypes;
