/*
 * Created by Paul Engelke on 27 May 2021.
 */

import {NumberFormat} from "@hti-ui/js-core";

/**
 * A set of custom number formats for the app that can be used with
 * @hti-ui/js-core.
 */
const NumberFormats = Object.freeze({
  StockQuantity: NumberFormat.CustomDecimal({mantissa: 5, trimMantissa: true}),
});

export default NumberFormats;
