/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {LocalCacheRegistry} from "@hti-ui/local-cache";
import AuthActionTypes from "../constants/action-types/authActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import AppStatusCode from "../constants/codes/appStatusCodes";
import StdError from "../utils/error/stdError";
import HttpManager from "../utils/httpManager";
import SecurityUtility from "../utils/securityUtility";
import {
  fetchPropertyAccessForUser,
  fetchUserRoleForUser,
  setUserViaToken
} from "./sessionActions";
import {fetchCustomerLicence} from "./workspaceActions";
import {LoginType} from "../constants/loginType";
import {storeLoginInfo} from "./loginInfoActions";
import {AppType} from "../constants/appType";

/**
 * Attempts to sign the user in automatically, using the stored JWT token.
 * @return {function(*,*): Promise}
 */
export const autoSignIn = () => async (dispatch, getState) => {

  let signedIn = false;

  try {

    dispatch({type: AuthActionTypes.REQUEST});
    const token = SecurityUtility.getToken();
    const refreshToken = SecurityUtility.getRefreshToken();
    if (SecurityUtility.isTokenValid(token,)) {
      await signInAndInitialize(token, refreshToken, {
        loginType: LoginType.AUTO_LOGIN,
        appType: AppType.WEB_APP
      })(dispatch, getState);
      signedIn = true;
    }

  } catch (e) {
    dispatch({type: AuthActionTypes.FAIL_REQUEST});
    throw new StdError('Automatic sign-in failed.', e)
    .setCode(AppStatusCode.Unauthenticated);
  }

  if (!signedIn) {
    await signOut()(dispatch, getState);
    throw new StdError()
    .setCode(AppStatusCode.Unauthenticated)
    .setMessage('Automatic sign-in failed due to an expired token.');
  }

};

/**
 * Signs a user in with their email address and password.
 *
 * @param {object} args The request object.
 * @param {string} args.emailAddress The user's email address.
 * @param {string} args.password The user's plaintext password.
 * @return {function(*,*): Promise}
 */
export const signInWithCredentials = (args) => async (dispatch, getState) => {

  try {

    const loginInfo = {
      loginType: LoginType.STANDARD_LOGIN,
      appType: AppType.WEB_APP
    };

    dispatch({type: AuthActionTypes.REQUEST});
    const response = await HttpManager.post('authentication', {
      email: args.emailAddress,
      password: args.password,
      loginInfo
    });

    if (response?.data?.token) {
      const token = parseToken(response);
      const refreshToken = parseRefreshToken(response);
      await signInAndInitialize(token, refreshToken, loginInfo)(dispatch,
          getState);
    } else {
      dispatch({type: AuthActionTypes.FAIL_REQUEST});
      return response;
    }

  } catch (e) {
    dispatch({type: AuthActionTypes.FAIL_REQUEST});
    throw e;
  }

};

/**
 * Signs the user in using Google.
 *
 * @param {Object} args The method arguments.
 * @param {Object} args.idToken The user's Google ID token.
 * @return {function(*=, *=): Promise}
 */
export const signInWithGoogle = (args) => async (dispatch, getState) => {

  try {

    const loginInfo = {
      loginType: LoginType.GOOGLE_LOGIN,
      appType: AppType.WEB_APP
    };

    dispatch({type: AuthActionTypes.REQUEST});
    const response = await HttpManager
    .post("authentication/google", {
      token: args.idToken,
      loginInfo
    });

    const token = parseToken(response);
    const refreshToken = parseRefreshToken(response);
    await signInAndInitialize(token, refreshToken, loginInfo)(dispatch,
        getState);

  } catch (e) {
    dispatch({type: AuthActionTypes.FAIL_REQUEST});
    throw e;
  }

};

/**
 * Sets the JWT token in the local storage and our HTTP utility.
 *
 * Then, the user's data is extracted from the token, and we initialize the
 * user session redux state with this information.
 *
 * Once we have the user's ID, we fetch the rest of their required data.
 *
 * @param {string} token A valid JWT token returned by the server or the app's
 * local storage.
 * @param {string} refreshToken A valid JWT refresh token returned by the server or the app's
 * local storage.
 * @param loginInfo Details about the login
 * @return {function(*,*): Promise}
 */
export const signInAndInitialize = (token, refreshToken,
    loginInfo = {}) => async (dispatch,
    getState) => {

  try {
    HttpManager.setToken(token);
    SecurityUtility.setToken(token);
    SecurityUtility.setRefreshToken(refreshToken);
    await setUserViaToken(token)(dispatch, getState);
    await Promise.all([
      fetchCustomerLicence()(dispatch, getState),
      fetchUserRoleForUser()(dispatch, getState),
      fetchPropertyAccessForUser()(dispatch, getState),
      storeLoginInfo(loginInfo)(dispatch),
    ]);
    dispatch({type: AuthActionTypes.SIGN_IN});
  } catch (e) {
    throw e;
  }

};

/**
 * Signs the current user out of the system and removes any data stored in
 * memory.
 *
 * @return {function(*,*): Promise}
 */
export const signOut = () => async (dispatch) => {

  try {

    dispatch({type: AuthActionTypes.REQUEST});
    SecurityUtility.revokeToken();
    SecurityUtility.revokeRefreshToken();
    LocalCacheRegistry.clear();
    dispatch({type: GlobalActionTypes.RESET});

  } catch (e) {
    dispatch({type: AuthActionTypes.FAIL_REQUEST});
    throw e;
  }

};

/**
 * A helper method that parses and validates the token received in a response
 * from the server.
 *
 * @param {Object} response The response object.
 * @return {string}
 */
const parseToken = (response) => {

  const token = response?.data?.token;
  const _token = SecurityUtility.parseToken(token);

  if (!_token) {
    throw new StdError()
    .setCode(AppStatusCode.Unauthenticated)
    .setMessage('No valid authentication token found.');
  }

  return token;

};

/**
 * A helper method that parses and validates the refresh token
 * received in a response from the server.
 *
 * @param {Object} response The response object.
 * @return {string}
 */
const parseRefreshToken = (response) => {

  const refreshToken = response?.data?.refreshToken;
  const _refreshToken = SecurityUtility.parseToken(refreshToken);

  if (!_refreshToken) {
    throw new StdError()
    .setCode(AppStatusCode.Unauthenticated)
    .setMessage('No valid authentication token found.');
  }

  return refreshToken;

};
