/*
 * Created by Paul Engelke on 06 April 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ProvinceActionTypes from "../constants/action-types/provinceActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of provinces.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ProvinceActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ProvinceActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ProvinceActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
