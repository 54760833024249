/*
 * Created by Stanton J Francis on 4 May 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for the print template type redux state.
 */
const PrintTemplateTypeActionTypes = ActionTypeUtility
.createActionTypes('PRINT_TEMPLATE_TYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: ActionTypeUtility.SET,
});

export default PrintTemplateTypeActionTypes;
