/*
 * Created by Stanton J Francis on 04 April 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import ReasonTypeActionTypes
  from "../constants/action-types/reasonTypeActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for reason types.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ReasonTypeActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ReasonTypeActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ReasonTypeActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
