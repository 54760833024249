/*
 * Created by Paul Engelke on 17 March 2021.
 */

import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import RouteNames from "../constants/routeNames";
import useSessionStatus, {SessionStatus} from "../hooks/useSessionStatus";
import useUserAuthorization from "../hooks/useUserAuthorization";
import SplashScreen from "./SplashScreen";

/**
 * A route that is protected by user authentication. If the route is accessed
 * and no valid authentication is provided, the user will be redirected to the
 * sign-in page.
 *
 * @see https://reacttraining.com/react-router/web/api/Route
 */
const PrivateRoute = (props) => {

  const {
    component: Component,
    userRights, requireAllUserRights,
    ...otherProps
  } = props;

  const sessionStatus = useSessionStatus();
  const authenticated = sessionStatus === SessionStatus.Authenticated;
  const requiresAuthorization = userRights?.length > 0;
  const authorized = useUserAuthorization(userRights ?? [],
      !!requireAllUserRights);
  const allowAccess = authenticated && (!requiresAuthorization || authorized);

  return (<Route {...otherProps} render={(props) => {

    if (sessionStatus === SessionStatus.Pending) {
      return (<SplashScreen/>);
    }

    return allowAccess
        ? (<Component {...props}/>)
        : (<Redirect
            to={{
              pathname: authenticated ? RouteNames.Root : RouteNames.LoginPage,
              state: {from: props.location}
            }}
        />);

  }}/>);

};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  userRights: PropTypes.array,
  requireAllUserRights: PropTypes.bool,
};

export default PrivateRoute;
