/*
 * Created by Paul Engelke on 06 April 2021.
 */

import {useActions} from "@hti-ui/redux-core";
import {useCallback, useMemo} from "react";
import {useHistory} from "react-router-dom";
import {signOut} from "../actions/authActions";
import RouteNames from "../constants/routeNames";

/**
 * Provides a decorated 'history' entity for navigation, that includes some
 * useful app-specific functionality involving navigation.
 */
const useEnhancedHistory = () => {

  const history = useHistory();
  const [_signOut] = useActions([signOut]);

  /**
   * Signs the user out of the app and navigates them to the login page.
   */
  const signOutWithHistory = useCallback(() => {
    _signOut()
    .finally(() => void history.push(RouteNames.LoginPage));
  }, [_signOut, history]);

  return useMemo(() => ({
    ...history,
    signOut: signOutWithHistory,
  }), [history, signOutWithHistory]);

};

export default useEnhancedHistory;
