/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockProductActionTypes
  from "../constants/action-types/stockProductActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A state reducer for a product list used during stock operations.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockProductActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockProductActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockProductActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
