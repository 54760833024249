/*
 * Created by Stanton J Francis on 17 July 2023.
 */

import {ActionTypeUtility} from '@hti-ui/redux-core';

/**
 * A set of action types related to user login info.
 */
const LoginInfoActionTypes = ActionTypeUtility.createActionTypes('LOGIN_INFO', {

  /**
   * A request to the server to record additional information about the user's login.
   */
  REQUEST: 'REQUEST',

  /**
   * A request has received a successful response.
   */
  COMPLETE: 'COMPLETE',

  /**
   * The request failed.
   */
  FAIL_REQUEST: 'FAIL_REQUEST',

  /**
   * Temporarily stores data in the redux state, for later use. The data stored
   * is additional info about the login example the type PIN, UUID etc.
   */
  STORE_DATA: 'STORE_DATA',

});

export default LoginInfoActionTypes;
