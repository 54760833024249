/*
 * Created by Stanton J Francis on 12 October 2021.
 */

import {useSelector} from "react-redux";
import {CustomerType} from "../constants/customerType";

/**
 * Checks if the current user's customer has permission to access a list of
 * other customers. This is driven by the Customer Type at the time of writing
 * this function customer type HTI, VAR Partners and Customer Group is allowed
 * to access a list of customers.
 *
 * @param {string} [userCustomerType] The type of the customer
 * @return {boolean}
 */
const useCanAccessCustomers = (userCustomerType) => {

  const {customerType} = useSelector(state => ({
    customerType: state.session.user?.customerType
  }));

  const _customerType = userCustomerType || customerType;

  const eligibleCustomerTypes = [
    CustomerType.HTI,
    CustomerType.VAR_PARTNER,
    CustomerType.CUSTOMER_GROUP
  ];

  return eligibleCustomerTypes.includes(_customerType);

};

export default useCanAccessCustomers;

