/* 
 * Created by Paul Engelke on 10 Jun 2019.
 */

import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import AuthActionTypes from "../constants/action-types/authActionTypes";
import {StateUtility} from '@hti-ui/redux-core';

const DEFAULT_STATE = StateUtility.State({
  authenticated: false,
}, {async: true});

/**
 * A reducer for some authentication state, including the user's
 * authentication token for app reference.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case AuthActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case AuthActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case AuthActionTypes.SIGN_IN:
      return StateUtility.defaultCompleteRequest({
        ...state,
        authenticated: true,
      });

    case AuthActionTypes.COMPLETE:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }
};
