/*
 * Created by Paul Engelke on 01 April 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ProductActionTypes from "../constants/action-types/productActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of products.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ProductActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ProductActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case ProductActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ProductActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case ProductActionTypes.CREATE:
      return create(state, action);

    case ProductActionTypes.UPDATE:
      return update(state, action);

      // todo remove
    case ProductActionTypes.UPDATE_VARIANT_REDUCTIONS:
      return updateVariantReductions(state, action);

    case ProductActionTypes.DELETE:
      return _delete(state, action);

      // todo remove
    case ProductActionTypes.DELETE_VARIANT:
      return deleteVariant(state, action);

      // todo remove
    case ProductActionTypes.DELETE_VARIANT_REDUCTION:
      return deleteVariantReduction(state, action);

      // todo remove ProductActionTypes.RESET
    case ProductActionTypes.RESET:
    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new product to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing product in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted product from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted product variant from its parent product.
 */
const deleteVariant = (state, action) => {
  const {productId, variantId} = action.data;
  const next = state.data.map(p => (
      p.id === productId
          ? {
            ...p,
            productVariants:
                p.productVariants?.filter(v => v.id !== variantId),
          }
          : p
  ));
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates a product variant's reduction configuration.
 */
const updateVariantReductions = (state, action) => {
  const {productId, variantId, reductions} = action.data;
  const next = state.data.map(p => (
      p.id === productId
          ? {
            ...p,
            variants: p.variants?.map(v => (
                v.id === variantId
                    ? {...v, reductions}
                    : v
            ))
          }
          : p
  ));
  return StateUtility.defaultSet(state, next);
};

/**
 * Deletes a reduction from a product variant's reduction configuration.
 */
const deleteVariantReduction = (state, action) => {
  const {productId, variantId, reductionId} = action.data;
  const next = state.data.map(p => (
      p.id === productId
          ? {
            ...p,
            variants: p.variants?.map(v => (
                v.id === variantId
                    ? {
                      ...v,
                      reductions:
                          v.reductions?.filter(r => r.id !== reductionId),
                    }
                    : v
            )),
          }
          : p
  ));
  return StateUtility.defaultSet(state, next);
};
