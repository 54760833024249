import {ActionTypeUtility} from "@hti-ui/redux-core";

const DataExportActionTypes = ActionTypeUtility.createActionTypes(
    'DATA_EXPORT', {
      REQUEST: 'REQUEST',
      FAIL_REQUEST: 'FAIL_REQUEST',
      COMPLETE_REQUEST: 'COMPLETE_REQUEST'
    });

export default DataExportActionTypes;
