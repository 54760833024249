/* 
 * Created by Paul Engelke on 22 Aug 2018.
 */

/**
 * A set of standard HTTP status codes.
 */
const HttpStatusCode = Object.freeze({
  Unauthorized: 401,
  Forbidden: 403,
});

export default HttpStatusCode;
