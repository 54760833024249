/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the customer licence redux state.
 */
const CustomerLicenseActionTypes = ActionTypeUtility
.createActionTypes('CUSTOMER_LICENCES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  UPDATE: 'UPDATE',
});

export default CustomerLicenseActionTypes;
