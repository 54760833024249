/*
 * Created by Paul Engelke on 12 July 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ProductVariantActionTypes
  from "../constants/action-types/productVariantActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for product variants.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ProductVariantActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ProductVariantActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ProductVariantActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case ProductVariantActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case ProductVariantActionTypes.CREATE:
      return create(state, action);

    case ProductVariantActionTypes.UPDATE:
      return update(state, action);

    case ProductVariantActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Addes a newly created variant to the local set.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing variant in the local set.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Deletes an existing variant from the local set.
 */
const _delete = (state, action) => {
  const deleted = action.data;
  const next = state.data.filter(e => e.id !== deleted.id);
  return StateUtility.defaultSet(state, next);
};
