/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the 'outlets' redux state.
 * @type Object
 */
const OutletActionTypes = ActionTypeUtility.createActionTypes('OUTLETS', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});

export default OutletActionTypes;
