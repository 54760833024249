/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const enhancers = [];
const middleware = [thunk];

// noinspection JSUnresolvedVariable
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof  devToolsExtension === 'function') {
  enhancers.push(devToolsExtension())
}

// noinspection JSCheckFunctionSignatures
const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    rootReducer,
    {},
    composedEnhancers
);

export default store;
