/*
 * Created by Stanton J Francis on 19 July 2024.
 */

import {Box, Dialog, EmailField} from "@hti-ui/react-web-material";
import {useActions} from "@hti-ui/redux-core";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useState} from "react";
import useGlobalMessenger from "../../../hooks/useGlobalMessenger";
import {EmailAddressValidator} from "@hti-ui/js-core";
import {emailPasswordReset} from "../../../actions/registrationActions";

/**
 * A dialog providing functionality to help a user to reset their password.
 */
const ForgotPasswordDialog = props => {

  const {
    open, onDismiss
  } = props;

  const {
    handleError, dispatchSuccessMessage
  } = useGlobalMessenger();

  const [_emailPasswordReset] = useActions([emailPasswordReset]);
  const [busy, setBusy] = useState(false);

  const [emailAddress, setEmailAddress] = useState('');

  const onResetPassword = useCallback(() => {
    setBusy(true);
    _emailPasswordReset({email: emailAddress.trim()})
    .then(() => {
      setBusy(false);
      dispatchSuccessMessage(
          'The password reset email has been sent successfully.');
      onDismiss();
    })
    .catch(e => {
      setBusy(false);
      handleError(e,
          'Failed to send the password reset email. Please check the email address and try again.');
    });
  }, [_emailPasswordReset, handleError, onDismiss, emailAddress, busy]);

  const onEnterPressed = useCallback((e) => {
    if (e?.key === 'Enter' && !!emailAddress) {
      onResetPassword();
    }
  }, [emailAddress, onResetPassword]);

  useEffect(() => {
    if (!open) {
      setEmailAddress('');
    }
  }, [open]);

  return (<Dialog
      open={open}
      fullWidth
      maxWidth={"sm"}>

    <DialogTitle>Account Recovery</DialogTitle>
    <Box topMargin={-2} leftPadding={3} rightPadding={3}>
      <Typography variant={'subtitle1'}
                  color={"textSecondary"}>{
        'Reset your password to regain access to your account.'
      }</Typography>
    </Box>
    <DialogContent>
      <Typography variant={'body1'}>{
        `Can't remember your password? No problem. Enter your email to reset it. If you need further assistance, contact your system admin.`
      }</Typography>
      <Box topPadding={2} bottomPadding={2}>
        <EmailField
            label={'Email Address'}
            value={emailAddress}
            onChange={setEmailAddress}
            fullWidth
            onKeyDown={onEnterPressed}
        />
      </Box>
    </DialogContent>

    <DialogActions>

      <Button
          variant={"outlined"}
          onClick={onDismiss}
      >Close</Button>
      <Button
          variant={'outlined'}
          color={'secondary'}
          onClick={onResetPassword}
          disabled={!EmailAddressValidator.validate(emailAddress?.trim())
              || busy}
      >Reset Password</Button>

    </DialogActions>

  </Dialog>);

};

ForgotPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

// noinspection JSValidateTypes
export default ForgotPasswordDialog;
