/*
 * Created by Stanton J Francis on 17 July 2023.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import LoginInfoActionTypes
  from "../constants/action-types/loginInfoActionTypes";

const DEFAULT_STATE = {
  details: {}
};

/**
 * A redux state reducer for managing the app preferences.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case LoginInfoActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case LoginInfoActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case LoginInfoActionTypes.STORE_DATA:
      return StateUtility.defaultCompleteRequest({
        ...state,
        details: action.loginInfo,
      });

    case LoginInfoActionTypes.COMPLETE:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }
};
