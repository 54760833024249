/*
 * Created by Stanton J Francis on 17 July 2023.
 */

const LoginType = {

  PIN_LOGIN: 'Pin',
  UUID_LOGIN: 'Uuid',
  NFC_CARD_LOGIN: 'Nfc Card',
  GOOGLE_LOGIN: 'Google',
  STANDARD_LOGIN: 'Username & Password',
  AUTO_LOGIN: 'Auto Login'

};

export {LoginType};
