/*
 * Created by Paul Engelke on 25 March 2021.
 */

import {Tooltip} from "@hti-ui/react-web-material";
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';

/**
 * A standard button for the workspace selectors in the workspace toolbar.
 */
const WorkspaceSelectorButton = props => {
  const {tooltip, children, onClick, disabled} = props;
  const classes = useStyles();
  return (<Grid item xs={12} sm={6} md={4} className={classes.root}>
    <Tooltip placement={'right'} content={tooltip}>
      <Button
          variant={'outlined'}
          onClick={onClick}
          disabled={disabled}
          fullWidth
      >
        <Typography className={classes.text}>
          {children}
        </Typography>
      </Button>
    </Tooltip>
  </Grid>);
};

const useStyles = makeStyles(theme => ({
  root: {padding: theme.spacing(.5)},
  text: {
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'noWrap',
    overflow: 'hidden',
  },
}));

WorkspaceSelectorButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default WorkspaceSelectorButton;
