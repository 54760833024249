/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the delivery interface type redux state.
 */
const DeliveryInterfaceTypeActionTypes = ActionTypeUtility
.createActionTypes('DELIVERY_INTERFACE_TYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default DeliveryInterfaceTypeActionTypes;
