/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the global messenger redux state.
 */
const GlobalMessengerActionTypes = ActionTypeUtility
.createActionTypes('GLOBAL_MESSENGER', {
  ENQUEUE: 'ENQUEUE',
  DEQUEUE: 'DEQUEUE',
});

export default GlobalMessengerActionTypes;
