/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the PMS interface type redux state.
 */
const PmsInterfaceTypeActionTypes = ActionTypeUtility
.createActionTypes('PMS_INTERFACE_TYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default PmsInterfaceTypeActionTypes;
