/*
 * Created by Paul Engelke on 09 July 2021.
 */

import {Dialog} from "@hti-ui/react-web-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import PropTypes from "prop-types";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import useEnhancedHistory from "../../../../hooks/useEnhancedHistory";

/**
 * A dialog for informing a user that they do not have access to any properties.
 */
const NoAccessDialog = props => {

  const {t} = useTranslation(["common", "App"]);
  const {open, disabled} = props;
  const history = useEnhancedHistory();

  /**
   * Signs the user out of the app.
   */
  const onSignOutClicked = useCallback(() => history.signOut(), [history]);

  return (<Dialog open={open}>

    <DialogTitle>
      {t("App:Workspace.Dialog.Property.NoAccess.Title")}
    </DialogTitle>

    <DialogContent>

      <DialogContentText>
        {t("App:Workspace.Dialog.Property.NoAccess.Description.Part1")}
        <br/>
        {t("App:Workspace.Dialog.Property.NoAccess.Description.Part2")}
      </DialogContentText>

    </DialogContent>

    <DialogActions>

      <Button
          variant={"outlined"}
          color={"secondary"}
          onClick={onSignOutClicked}
          disabled={disabled}
      >{t("common:Button.SignOut")}</Button>

    </DialogActions>

  </Dialog>);

};

NoAccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default NoAccessDialog;
