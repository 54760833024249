/*
 * Created by Paul Engelke on 19 July 2021.
 */

import {makeStyles} from "@material-ui/core";
import ImageAssets from "../../../assets/images";

/**
 * The navigation drawer header component.
 */
const DrawerHeader = () => {
  const classes = useStyles();
  return (<div className={classes.root}>
    <img
        alt={"nebula-pos"}
        src={ImageAssets.PosLogoHorizontal.dark}
        className={classes.logo}
    />
  </div>);
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(),
  },
  logo: {maxWidth: 300},
}));

export default DrawerHeader;
