/*
 * Created by Paul Engelke on 25 June 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing global stock delivery redux state.
 */
const GlobalStockDeliveryActionTypes = ActionTypeUtility
.createActionTypes('GLOBAL_STOCK_DELIVERIES', {
  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DELETE_LINE_ITEM: 'DELETE_LINE_ITEM',
});

export default GlobalStockDeliveryActionTypes;
