/*
 * Created by Paul Engelke on 01 April 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the brand redux state.
 */
const ProductActionTypes = ActionTypeUtility.createActionTypes('PRODUCTS', {

  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',

  SET: 'SET',

  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',

  // todo  remove below?
  DELETE_VARIANT: 'DELETE_VARIANT',
  UPDATE_VARIANT_REDUCTIONS: 'UPDATE_VARIANT_REDUCTIONS',
  DELETE_VARIANT_REDUCTION: 'DELETE_VARIANT_REDUCTION',
  RESET: 'RESET',

});

export default ProductActionTypes;
