/*
 * Created by Stanton J Francis on 22 June 2023.
 */

import {StateUtility} from "@hti-ui/redux-core";
import ImageActionTypes from "../constants/action-types/imageActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of categories.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ImageActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ImageActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ImageActionTypes.UPDATE:
      return update(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds or updates an existing image in the list.
 */
const update = (state, action) => {
  const {data} = action;
  const newData = state.data
  .map(d => d.uri === data.uri ? {...data} : d);

  const item = newData.find(d => d.uri === data.uri);
  let newState = newData;
  if (!item) {
    newState.push({...data})
  }
  return StateUtility.defaultSet(state, newState);
};
