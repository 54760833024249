/*
 * Created by Paul Engelke on 30 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockRequisitionActionTypes
  from "../constants/action-types/stockRequisitionActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for stock requisitions.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockRequisitionActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockRequisitionActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case StockRequisitionActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockRequisitionActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case StockRequisitionActionTypes.MERGE:
      return merge(state, action);

    case StockRequisitionActionTypes.CREATE:
      return create(state, action);

    case StockRequisitionActionTypes.UPDATE:
      return update(state, action);

    case StockRequisitionActionTypes.DELETE:
      return _delete(state, action);

    case StockRequisitionActionTypes.DELETE_LINE_ITEM:
      return deleteLineItem(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Merges a set of new/updated stock requisitions into the local set.
 */
const merge = (state, action) => {
  const next = StateUtility.mergeData(state.data, action.data);
  return StateUtility.defaultSet(state, next);
};

/**
 * Adds a new stock requisition to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock requisition in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted stock requisition from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted line item from an existing stock requisition entity.
 */
const deleteLineItem = (state, action) => {
  const {id, lineItemId} = action.data;
  const next = state.data.map(e => (
      e.id === id
      ? {...e, items: e.items?.filter(i => i.id !== lineItemId)}
      : e
  ));
  return StateUtility.defaultSet(state, next);
};
