/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {useMemo} from "react";
import ThemeTypes from "../../constants/themeTypes";
import useAppPreferences from "../../hooks/useAppPreferences";
import HtiIcon from './png/hti-icon.png';
import PosIconThin from './png/pos-icon-thin.png';
import PosReportLogo from './png/pos-report-logo.png';
import PosIconDark from "./svg/pos-icon-thick.dark.svg";
import PosIconLight from "./svg/pos-icon-thick.light.svg";
import PosLogoHorizontalLight from "./svg/pos-logo-hoizontal.light.svg";
import PosLogoVerticalLight from "./svg/pos-logo-hoizontal.light.svg";
import PosLogoHorizontalDark from "./svg/pos-logo-horizontal.dark.svg";
import PosLogoVerticalDark from "./svg/pos-logo-horizontal.dark.svg";
import RotatingLogoLight from "./gif/rotating-logo.light.gif";
import RotatingLogoDark from "./gif/rotating-logo.dark.gif";

/**
 * A set of image assets used throughout the app.
 * @see useImageAssets
 */
const ImageAssets = Object.freeze({

  HtiIcon,
  PosReportLogo,

  PosIconThin,
  PosIconThick: {
    light: PosIconLight,
    dark: PosIconDark,
  },

  PosLogoHorizontal: {
    light: PosLogoHorizontalLight,
    dark: PosLogoHorizontalDark,
  },

  PosIconVertical: {
    light: PosLogoVerticalLight,
    dark: PosLogoVerticalDark,
  },

  RotatingLogo: {
    light: RotatingLogoLight,
    dark: RotatingLogoDark,
  }

});

export default ImageAssets;

/**
 * A hook that provides image assets based on the current theme.
 * @return {Object}
 */
export const useImageAssets = () => {
  const appPreferences = useAppPreferences();
  const darkMode = appPreferences.theme === ThemeTypes.Dark;
  return useMemo(() => {
    const assets = {};
    Object.entries(ImageAssets)
    .forEach(([k, v]) => {
      if (v?.light || v?.dark) {
        const wantedAsset = v[darkMode ? "dark" : "light"];
        const fallbackAsset = v[darkMode ? "light" : "dark"] ?? null;
        assets[k] = wantedAsset ?? fallbackAsset;
      } else {
        assets[k] = v;
      }
    });
    return assets;
  }, [darkMode]);
};

