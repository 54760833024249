/*
 * Created by Paul Engelke on 06 Jun 2019.
 */

import {ActionTypeUtility} from '@hti-ui/redux-core';

/**
 * A set of global action types to which reducers may, or in some cases,
 * should subscribe.
 *
 * @type Object
 */
const GlobalActionTypes = ActionTypeUtility.createActionTypes('GLOBAL', {

  /**
   * All subscribed reducers should reset to their default state.
   *
   * This is especially useful when performing a sign-out action, where all data
   * relevant to a specific user should be cleared.
   */
  RESET: 'RESET',

});

export default GlobalActionTypes;
