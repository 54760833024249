/*
 * Created by Paul Engelke on 19 January 2023.
 */

import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import useAppPreferences from "../../../hooks/useAppPreferences";
import ThemeTypes from "../../../constants/themeTypes";

/**
 * A button for signing users in using Google.
 *
 * @see https://developers.google.com/identity/gsi/web/reference/js-reference
 */
const GoogleSignInButton = props => {

  const preferences = useAppPreferences();
  const theme = preferences.theme === ThemeTypes.Light ? "filled_black" : "outline";

  const [ready, setReady] = useState(false);
  useEffect(() => {

    // This effect hook will check if the Google Sign-In API has loaded, before allowing the
    // sign-in button to be initialized and rendered.
    //
    // If the API has not been loaded, another check will be performed after 200ms until such a
    // time as the API has been loaded or until the component has been unmounted.

    const isApiLoaded = () => !!window.google?.accounts?.id;

    if (isApiLoaded()) {
      // Don't bother with the interval check if the API has been loaded.
      setReady(true);
      return;
    }

    let interval;
    const checkApiLoaded = () => {
      if (isApiLoaded()) {
        clearInterval(interval);
        setReady(true);
      }
    };

    interval = setInterval(checkApiLoaded, 200);
    return () => clearInterval(interval);

  }, []);

  useEffect(() => {

    if (!ready) {
      // The API has not loaded yet, so we cannot safely initialize the button.
      return;
    }

    // The API is ready to be used. We can initialize and render the button.

    window.google.accounts.id.initialize({
      client_id: "256773985684-tjaancgpin3j0vdq36eidv01dl29el4t.apps.googleusercontent.com",
      callback: res => props.onSuccess({idToken: res.credential}),
    });

    window.google.accounts.id.renderButton(
        document.getElementById("google-sign-in-button"),
        {
          theme: theme,
          size: "large",
          shape: "rectangular",
          text: props.textType ?? "signin_with",
        },
    );

  }, [ready, props.onSuccess, props.textType, theme]);

  return (<div id={"google-sign-in-button"}/>);

};

GoogleSignInButton.propTypes = {
  onSuccess: PropTypes.func,
  textType: PropTypes.oneOf(["signin_with", "signup_with"]),
};

export default GoogleSignInButton;
