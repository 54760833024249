/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CostPriceTypeActionTypes
  from "../constants/action-types/costPriceTypeActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for cost price types.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CostPriceTypeActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CostPriceTypeActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CostPriceTypeActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
