/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {
  CacheLifetime,
  LocalCache,
  LocalCacheRegistry
} from "@hti-ui/local-cache";
import WorkspaceActionTypes
  from "../constants/action-types/workspaceActionTypes";
import HttpManager from "../utils/httpManager";
import DateFormatter from "../utils/reporting/dataFormatter";
import {recordLogin} from "./loginInfoActions";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * Fetches the customer licence for the signed-in user.
 *
 * @param {Object} [args] The method parameters.
 * @param {number} [args.customerId = session.user.customerId] The ID of the
 * customer. This defaults to the current user's customer ID.
 * @param {boolean} [args.force = false] Should cache be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchCustomerLicence = (args) => (dispatch, getState) => {

  const defaultCustomerId = getState().session.user?.customerId;
  const {customerId = defaultCustomerId, force = false} = args ?? {};
  const urlPrefix = "licenses/";
  const url = `${urlPrefix}${customerId}`;

  if (!force && cache.has(url)) {
    return cache.get(url);
  }

  dispatch({type: WorkspaceActionTypes.REQUEST});
  const request = HttpManager.get(url);
  cache.deleteLike(k => k?.includes(urlPrefix));
  cache.set(url, CacheLifetime.MEDIUM, request);

  return request
  .then(r => {

    const {data} = r;
    const {
      ExpiryDate: expiryDate,
      NoOfDevices: noOfDevices,
      ...rest
    } = data ?? {};
    const licence = {...rest, expiryDate, noOfDevices};

    dispatch({
      type: WorkspaceActionTypes.SET_CUSTOMER_LICENCE,
      data: licence,
    });
    return {...r, data: licence};

  })
  .catch(e => {
    cache.delete(url);
    dispatch({type: WorkspaceActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Sets the customer in the user's workspace.
 *
 * @param args The function arguments.
 * @param args.customer The customer object.
 * @return {function(*,*): void}
 */
export const setCustomer = (args) => (dispatch) => {
  const {id, name} = args.customer ?? {};
  dispatch({
    type: WorkspaceActionTypes.SET_CUSTOMER,
    data: {id, name},
  });
};

/**
 * Sets the property in the user's workspace.
 *
 * @param args The function arguments.
 * @param args.property The property object.
 * @return {function(*,*): void}
 */
export const setProperty = (args) => (dispatch, getState) => {
  const {property} = args ?? {};
  dispatch({
    type: WorkspaceActionTypes.SET_PROPERTY,
    data: {
      id: property?.id,
      name: property?.name,
      systemDate: property?.systemDate,
      currencyId: property?.currencyId,
      currencyCode: property?.currencyCode,
      currencyName: property?.currencyName,
      taxRuleId: property?.taxRuleID,
      goLiveInProgress: property?.goLiveInProgress,
      liveSinceTimestamp: DateFormatter.formatTimestamp(
          property?.liveSinceTimestamp, true),
    },
  });
  // Triggers a request to the server to record the login details every time
  // a new property is selected.
  if (property) {
    recordLogin(property)(dispatch, getState);
  }
};
