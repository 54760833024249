/*
 * Created by Stanton J Francis on 17 July 2023.
 */

import LoginInfoActionTypes
  from "../constants/action-types/loginInfoActionTypes";
import HttpManager from "../utils/httpManager";

/**
 * Initializes the app settings for the signed-in user.
 * @return {function(*, *): Promise<void>}
 */
export const storeLoginInfo = (loginInfo) => async (dispatch) => {
  dispatch({type: LoginInfoActionTypes.STORE_DATA, loginInfo});
};

/**
 * Records the login in the database, so it can be reported on later
 *
 * @param {Object} property The property object.
 * @return {function(*, *): Promise<T>}
 */
export const recordLogin = (property) => (dispatch, getState) => {

  const {user, userRole} = getState().session;
  const workspace = getState().workspace;
  const {customer} = workspace;
  const {details = {}} = getState().loginInfo;

  const params = {
    userId: user?.id,
    customerId: customer?.id,
    propertyId: property?.id,
    details: {
      ...details,
      userRoleId: userRole?.id,
      userRoleName: userRole?.name,
      propertyName: property?.name,
    }
  };

  dispatch({type: LoginInfoActionTypes.REQUEST});
  return HttpManager.post('authentication/login-info', params)
  .then(r => {
    dispatch({type: LoginInfoActionTypes.COMPLETE});
    return r;
  })
  .catch(e => {
    dispatch({type: LoginInfoActionTypes.FAIL_REQUEST});
    throw e;
  });

};
