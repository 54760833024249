/*
 * Created by Paul Engelke on 06 April 2021.
 */

import {useActions} from "@hti-ui/redux-core";
import {useCallback} from "react";
import {dequeueGlobalMessage} from "../actions/globalMessengerActions";
import {
  Snackbar,
  SnackbarActions,
  SnackbarContent
} from "@hti-ui/react-web-material";
import {useSelector} from "react-redux";
import {Button} from "@material-ui/core";

/**
 * A snackbar to be appended to the 'root' app component.
 *
 * This component is responsible for displaying global messages and dequeuing
 * them from the global message redux state.
 */
const GlobalSnackbar = () => {

  const messages = useSelector(state => state.globalMessenger.queue);
  const current = messages?.[0];
  const isError = current?.type === "error";

  const [dequeMessage] = useActions([dequeueGlobalMessage]);
  const dismissMessage = useCallback(() => dequeMessage(), [current]);

  const readSpeed = 4; // 4 words per second based on average human read speed.
  const baseDuration = isError ? 60000 : 3000;
  const maxDuration = isError ? 60000 : 20000;
  const wordCount = current?.description?.split(" ")?.length;
  const duration = Math.min(
      maxDuration,
      Math.max(baseDuration, (wordCount / readSpeed) * 1000));

  return (<Snackbar
      visible={!!current}
      duration={duration}
      type={current?.type}
      onShowComplete={dismissMessage}
  >
    <SnackbarContent>{current?.description ?? ""}</SnackbarContent>
    <SnackbarActions>
      <Button variant={"outlined"} onClick={dismissMessage}>Dismiss</Button>
    </SnackbarActions>
  </Snackbar>);

};

export default GlobalSnackbar;
