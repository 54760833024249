/*
 * Created by Stanton J Francis on 9 February 2023.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import InvoiceActionTypes from "../constants/action-types/invoiceActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for reasons
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case InvoiceActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case InvoiceActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case InvoiceActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case InvoiceActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
