/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import DeliveryInterfaceActionTypes
  from "../constants/action-types/deliveryInterfaceActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of delivery interfaces.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case DeliveryInterfaceActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case DeliveryInterfaceActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case DeliveryInterfaceActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case DeliveryInterfaceActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case DeliveryInterfaceActionTypes.SET_POS_INTEGRATION:
      return setPosIntegration(state, action);

    case DeliveryInterfaceActionTypes.CREATE:
      return create(state, action);

    case DeliveryInterfaceActionTypes.UPDATE:
      return update(state, action);

    case DeliveryInterfaceActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new delivery interface to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing delivery interface in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted delivery interface from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Sets the pos integration status of some existing Uber Eats interface.
 */
const setPosIntegration = (state, action) => {
  const {interfaceId, interfaceEnabled} = action.data;
  const next = state.data.map(i =>
      i.id === interfaceId
          ? {...i, isActivated: interfaceEnabled}
          : i
  );
  return StateUtility.defaultSet(state, next);
};
