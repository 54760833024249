/*
 * Created by Paul Engelke on 07 September 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * Action types for controlling the registration redux state.
 */
const RegistrationActionTypes = ActionTypeUtility
.createActionTypes("REGISTRATION", {
  REQUEST: "REQUEST",
  COMPLETE_REQUEST: "COMPLETE_REQUEST",
  FAIL_REQUEST: "FAIL_REQUEST",
});

export default RegistrationActionTypes;
