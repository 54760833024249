/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import WorkspaceActionTypes
  from "../constants/action-types/workspaceActionTypes";

/**
 * The default state for the user's workspace.
 *
 * Please note that these values are nullable, and are set here to demonstrate
 * what attributes can be expected to exist at some point for each workspace
 * entity.
 */
const DEFAULT_STATE = StateUtility.State({
  customer: {
    id: null,
    name: null,
  },
  property: {
    id: null,
    name: null,
    currencyId: null,
    currencyCode: null,
    systemDate: null,
    taxRuleId: null,
    goLiveInProgress: false,
    liveSinceTimestamp: null,
  },
  customerLicence: {},
}, {async: true});

/**
 * A state reducer for the current user's workspace.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case WorkspaceActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case WorkspaceActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case WorkspaceActionTypes.SET_CUSTOMER:
      return setCustomer(state, action);

    case WorkspaceActionTypes.SET_PROPERTY:
      return setProperty(state, action);

    case WorkspaceActionTypes.SET_CUSTOMER_LICENCE:
      return setCustomerLicence(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Sets the global customer context.
 */
const setCustomer = (state, action) => {
  return StateUtility.defaultCompleteRequest({
    ...state,
    customer: action.data,
  });
};

/**
 * Sets the global property context.
 */
const setProperty = (state, action) => {
  return StateUtility.defaultCompleteRequest({
    ...state,
    property: action.data,
  });
};

/**
 * Sets the global customer licence context.
 */
const setCustomerLicence = (state, action) => {
  return StateUtility.defaultCompleteRequest({
    ...state,
    customerLicence: action.data,
  });
};
