/*
 * Created by Robert Mason on 17 February 2023.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the printer redux state.
 */
const StockScaleActionTypes = ActionTypeUtility.createActionTypes('STOCK_SCALES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});

export default StockScaleActionTypes;
