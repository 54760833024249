/*
 * Created by Paul Engelke on 01 April 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockInterfaceActionTypes
  from "../constants/action-types/stockInterfaceActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of stock interfaces.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockInterfaceActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockInterfaceActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockInterfaceActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case StockInterfaceActionTypes.CREATE:
      return create(state, action);

    case StockInterfaceActionTypes.UPDATE:
      return update(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new stock interface to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock interface in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};
