/*
 * Created by Paul Engelke on 08 February 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import ForexRateActionTypes
  from "../constants/action-types/forexRateActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State(
    {data: []},
    {async: true, asyncOptions: {useCounter: true}},
);

/**
 * A redux state reducer for a property's foreign exchange rate data.
 */
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case ForexRateActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ForexRateActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ForexRateActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case ForexRateActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case ForexRateActionTypes.MERGE:
      return merge(state, action);

    case ForexRateActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }
};

const merge = (state, action) => {
  const keyFn = r =>
      `${r.property?.id}#${r.baseCurrency?.id}#${r.foreignCurrency?.id}`;
  const next = StateUtility.mergeData(state.data, action.data, keyFn);
  return StateUtility.defaultSet(state, next);
};

const _delete = (state, action) => {
  const {propertyId, code} = action.data;
  const next = state.data.filter(r =>
      r.property?.id === propertyId
      && r.foreignCurrency?.code !== code);
  return StateUtility.defaultSet(state, next);
};
