/*
 * Created by Paul Engelke on 17 March 2021.
 */

/**
 * A defined set or navigation routes for the app.
 */
const RouteNames = {

  Root: '/',

  LoginPage: '/login',
  AccountRegistrationPage: '/register',
  AccountActivationPage: '/account-activation',
  UserActivationPage: '/user-activation/:token',
  ErrorPage: '/error/:error',

  DashboardPage: '/dashboard',

  CustomerLicencePage: '/hti/customer-licences',

  CustomerPage: '/customers',
  PropertyAdminPage: '/property-admin',

  CustomerDetailPage: '/customer-details',
  UserPage: '/users',
  UserRolePage: '/user-roles',

  PaymentTypePage: '/payment-types',
  TaxRulePage: '/tax-rules',
  DiscountPage: '/pos/discounts',
  CampaignPage: '/pos/campaigns',
  ReasonPage: '/pos/reasons',

  BrandPage: '/brands',
  BarcodePage: '/barcodes',
  PropertyPage: '/properties',
  ForexRatePage: '/forex-rates',
  PropertyCreationWizardPage: '/properties/wizard',
  OutletPage: '/pos/outlets',
  ShiftPage: '/pos/shifts',

  ProductGroupPage: '/pos/product-groups',
  ProductCategoryPage: '/pos/product-categories',
  ProductPage: '/pos/products',
  ProductImportPage: '/pos/products/import',
  ProductOptionMenuPage: '/pos/product-option-menus',
  CustomMenuPage: '/pos/custom-menus',
  SetMenuPage: '/pos/set-menus',

  RegisteredDevicePage: '/registered-devices',
  PrinterPage: '/pos/printers',
  StockScalePage: '/stock/scales',

  StockStorePage: '/stock/stores',
  StockSupplierPage: '/stock/suppliers',
  StockCostCentrePage: '/stock/cost-centres',
  StockOrderPage: '/stock/orders',
  StockOrderTemplatePage: '/stock/order-templates',
  InternalGlobalStockOrderPage: '/stock/global-orders/internal',
  ExternalGlobalStockOrderPage: '/stock/global-orders/external',
  StockDeliveryPage: '/stock/deliveries',
  InternalGlobalStockDeliveryPage: '/stock/global-deliveries/internal',
  ExternalGlobalStockDeliveryPage: '/stock/global-deliveries/external',
  StockRequisitionPage: '/stock/requisitions',
  StockIssuePage: '/stock/issues',
  StockCombinationManufacturePage: '/stock/manufacturing/combination',
  StockDivisionManufacturePage: '/stock/manufacturing/division',
  StockReductionPage: '/stock/reductions',
  StockRecipePage: '/stock/recipes',
  StockRecipeReductionPage: '/stock/recipes/reductions',
  StockManualSalePage: '/stock/manual-sales',
  StockTakePage: '/stock/stock-take',

  StockInterfacePage: '/interfaces/stock',
  PmsInterfacePage: '/interfaces/pms',
  ManualSalesImportPage: '/interfaces/manual-sales',
  DeliveryInterfacePage: '/interfaces/delivery',

  GlExportPage: '/interfaces/gl-export',
  GlExportSetupPage: '/interfaces/gl-export-setup',
  GlMappingsPage: '/interfaces/gl-mappings',
  AccountingExportReportPage: '/reports/accounting-export',

  DataExportPage: '/data-export',

  PointOfSaleReportPage: '/reports/point-of-sale',
  TopSellersReportPage: '/reports/top-sellers',
  ProductPusherReportPage: '/reports/product-pusher',
  OutletAnalysisReportPage: '/reports/outlet-analysis',
  UserAnalysisReportPage: '/reports/user-analysis',
  TransactionAuditReportPage: '/reports/transaction-audit',
  PaymentAnalysisReportPage: '/reports/payment-analysis',
  DetailedPaymentAnalysisReportPage: '/reports/detailed-payment-analysis',
  ForexPaymentAnalysisReportPage: '/reports/forex-payment-analysis',
  DiscountTransactionReportPage: '/reports/discount-transaction-report',
  AdditionalTaxesReportPage: '/reports/additional-taxes-report',
  TransactionTransferReportPage: '/reports/transaction-transfer-report',

  ArmReportPage: '/reports/ar',
  AccountsReceivableTransactionReportPage: '/reports/ar-transaction-report',

  StockReportPage: '/reports/stock',
  StockMovementReportPage: '/reports/stock/stock-movement-report',
  StockStoreSummaryReportPage: '/reports/stock/store-summary-report',
  PartialStockStoreSummaryReportPage: '/reports/stock/partial-store-summary-report',
  StockReductionCategorySummaryReportPage: '/reports/stock/stock-reduction-category-summary-report',
  StockGroupSummaryReportPage: '/reports/stock/group-summary-report',
  StockCategorySummaryReportPage: '/reports/stock/category-summary-report',
  StockItemActivityReportPage: '/reports/stock/item-activity-report',
  StockValueReportPage: '/reports/stock/stock-value-report',
  StockVarianceReportPage: '/reports/stock/stock-variance-report',
  StockDeliveryListingReportPage: '/reports/stock/delivery-listing-report',
  StockOrderListingReportPage: '/reports/stock/order-listing-report',
  GlobalStockOrderListingReportPage: '/reports/stock/global-order-listing-report',
  GlobalStockDeliveryListingReportPage: '/reports/stock/global-delivery-listing-report',
  StockRequisitionListingReportPage: '/reports/stock/requisition-listing-report',
  StockIssueListingReportPage: '/reports/stock/issue-listing-report',
  StockReductionListingReportPage: '/reports/stock/reductions-listing-report',
  StockManualSaleListingReportPage: '/reports/stock/manual-sales-listing-report',
  StockTakeListingReportPage: '/reports/stock/stock-take-listing-report',
  PeriodicStockVarianceReportPage: '/reports/stock/periodic-stock-variance-report',
  DeliveryCostPriceIncreaseReportPage: '/reports/stock/delivery-cost-price-increase-report',
  PARLevelsReportPage: '/reports/stock/par-levels-report',
  SlowMovementReportPage: '/reports/stock/slow-movement-report',
  StockRecipeCostPriceListingReportPage: '/reports/stock/recipe-cost-price-listing-report',

  UserActionHistoryPage: "/reports/user-action-history",
  UserLoginReportPage: "/reports/user-login-report",

  ArmAccountTypePage: '/arm/account-types',
  ArmCompanyPage: '/arm/companies',
  ArmCompanyImportPage: '/arm/companies/import',
  ArmProfilePage: '/arm/profiles',
  ArmProfileImportPage: '/arm/profiles/import',
  ArmTemplatePdfPage: '/arm/statement-template-pdf',
  ArmTemplateEmailPage: '/arm/statement-template-email',

};

export default RouteNames;
