/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the stock delivery redux state.
 */
const StockDeliveryActionTypes = ActionTypeUtility
.createActionTypes('STOCK_DELIVERIES', {

  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',

  SET: 'SET',

  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DELETE_LINE_ITEM: 'DELETE_LINE_ITEM',
  DELETE_LINE_ITEMS: 'DELETE_LINE_ITEMS',

});

export default StockDeliveryActionTypes;
