/*
 * Created by Paul Engelke on 07 December 2021.
 */

import AppPreferenceActionTypes
  from "../constants/action-types/appPreferenceActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ThemeTypes from "../constants/themeTypes";

const DEFAULT_STATE = {
  theme: ThemeTypes.Light,
  language: "en",
};

/**
 * A redux state reducer for managing the app preferences.
 */
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case AppPreferenceActionTypes.SET:
      return {...state, ...action.preferences};

    case AppPreferenceActionTypes.SET_THEME:
      return {...state, theme: action.theme};

    case AppPreferenceActionTypes.SET_LANGUAGE:
      return {...state, language: action.language};

    case GlobalActionTypes.RESET:
      return {...state, language: "en"};

    default:
      return state;

  }
};
