/*
 * Created by Paul Engelke on 15 January 2020.
 */

/**
 * A set of categories used to group user rights, logically.
 */
const RightCategory = Object.freeze({

  Hti: 'HTI',
  CustomerManagement: 'Customer Management',
  AccountManagement: 'Account Management',
  PointOfSaleManagement: 'Point of Sale Management',
  StockManagement: 'Stock Management',
  Arm: 'Accounts Receivable',
  InvoiceToGeneralLedger: 'Invoice To General Ledger',
  Interfaces: 'Interfaces',
  Publishing: 'Publishing',
  Reports: 'Reports',
  MobileApp: 'Mobile & Tablet',

});

export default RightCategory;
