import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the PMS failed distribution actions
 */
const PmsFailedDistributionActionTypes = ActionTypeUtility
.createActionTypes('PMS_FAILED_DISTRIBUTION', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});

export default PmsFailedDistributionActionTypes;
