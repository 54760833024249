/*
 * Created by Paul Engelke on 22 February 2021.
 */

import AppStatusCode from "../../constants/codes/appStatusCodes";
import StdError from "./stdError";

/**
 * A class defining HTTP errors for the app.
 */
export default class HttpError extends StdError {

  /**
   * Creates a new HttpError
   *
   * @param {* | HttpError} [error] An error that occurred as a result
   * of a failed HTTP request.
   * @param {string} [message] A descriptive message for the error.
   */
  constructor(error, message) {

    super(message, error);

    this.setError(error);
    this.setMessage(message);

    if (error?.code === AppStatusCode.ConnectionTimeout) {
      this.setCode(AppStatusCode.ConnectionTimeout);
    } else if (!error?.response && !error?.code) {
      this.setCode(AppStatusCode.NetworkError);
    } else if (error?.response) {

      const {data, status} = error.response;

      if (data?.code) {
        this.setCode(data.code);
      } else if (status) {
        this.setCode(status);
      }

      if (data?.message) {
        // This is the preferred error message property.
        this.setMessage(data.message);
      } else if (data?.error) {
        // This is used for some errors that existed prior to the
        // standardized error framework.
        this.setMessage(data.error);
      }

    }

  }

}

