/*
 * Created by Stanton J Francis on 06 October 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CustomerTypeActionTypes
  from "../constants/action-types/customerTypeActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of customer types.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CustomerTypeActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CustomerTypeActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CustomerTypeActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
