/*
 * Created by Stanton J Francis on 4 May 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import PrintTemplateTypeActionTypes
  from "../constants/action-types/printTemplateTypeActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for print template types.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case PrintTemplateTypeActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case PrintTemplateTypeActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case PrintTemplateTypeActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
