/* 
 * Created by Paul Engelke on 06 Jun 2019.
 */

import {ActionTypeUtility} from '@hti-ui/redux-core';

/**
 * A set of action types related to user authentication.
 */
const AuthActionTypes = ActionTypeUtility.createActionTypes('AUTH', {

  /**
   * An authentication request has been made to the server.
   */
  REQUEST: 'REQUEST',

  /**
   * An authentication request has received a successful response.
   */
  COMPLETE: 'COMPLETE',

  /**
   * The authentication request failed.
   */
  FAIL_REQUEST: 'FAIL_REQUEST',

  /**
   * An authentication request was successful.
   */
  SIGN_IN: 'SIGN_IN',

});

export default AuthActionTypes;
