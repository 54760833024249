/*
 * Created by Stanton J Francis on 03 November 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the property admin redux state.
 */
const PropertyAdminActionTypes = ActionTypeUtility
.createActionTypes('PROPERTY_ADMIN', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
});

export default PropertyAdminActionTypes;
