/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {useActions} from "@hti-ui/redux-core";
import {makeStyles, Menu, MenuItem, Typography} from "@material-ui/core";
import {
  LanguageRounded as LocaleIcon,
  CheckRounded as CheckIcon
} from "@material-ui/icons";
import PropTypes from "prop-types";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {setLanguage} from "../../../../actions/appPreferenceActions";
import useAppPreferences from "../../../../hooks/useAppPreferences";

/**
 * A menu item for the user widget that allows the current language to be
 * changed.
 */
const LanguagePicker = props => {

  const classes = useStyles(props);
  const {t} = useTranslation("App");
  const {visible, onClose} = props;

  const [anchor, setAnchor] = useState(null);
  useEffect(() => setAnchor(null), [visible]);
  const _setAnchor = useCallback(e => setAnchor(e.currentTarget), []);
  const clearAnchor = useCallback(() => setAnchor(null), []);

  const languageOptions = useMemo(() => ([
    {description: "English", code: "en"},
    {description: "Afrikaans", code: "af"},
  ]), []);

  const currentLanguage = useAppPreferences().language;
  const _setLanguage = useActions(setLanguage);
  const onLanguagePicked = useCallback(language => {
    _setLanguage({language, persist: true});
    onClose();
  }, [onClose]);

  return (<>

    <MenuItem onClick={_setAnchor} className={classes.menuItem}>
      <LocaleIcon/>
      <Typography className={classes.menuItemText}>
        {t("App:UserWidget.Language", {
          x: languageOptions.find(o => o.code === currentLanguage)?.description,
        })}
      </Typography>
    </MenuItem>

    <Menu open={!!anchor} anchorEl={anchor} onClose={clearAnchor}>
      {languageOptions.map(language => {
        const selected = currentLanguage === language.code;
        return (<MenuItem
            key={language.code}
            onClick={() => onLanguagePicked(language.code)}
        >
          {selected && <CheckIcon/>}
          <Typography className={selected ? classes.languageText : undefined}>
            {language.description}
          </Typography>
        </MenuItem>);
      })}
    </Menu>

  </>);

};

const useStyles = makeStyles(theme => ({
  languageText: {
    paddingLeft: theme.spacing(),
  },
}));

LanguagePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LanguagePicker;
