/*
 * Created by Paul Engelke on 01 April 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the stock interfaces redux state.
 */
const StockInterfaceActionTypes = ActionTypeUtility
.createActionTypes('STOCK_INTERFACES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
});

export default StockInterfaceActionTypes;
