/*
 * Created by Paul Engelke on 07 December 2021.
 */

import {useSelector} from "react-redux";

/**
 * Provides access to the user's app preferences.
 */
const useAppPreferences = () => useSelector(state => state.appPreferences);
export default useAppPreferences;
