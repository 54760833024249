/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {StateUtility, useActions} from "@hti-ui/redux-core";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {setProperty} from "../../../../actions/workspaceActions";
import useAccessibleProperties from "../../../../hooks/useAccessibleProperties";
import WorkspaceSelectorButton from "../WorkspaceSelectorButton";
import CreationDialog from "./CreationDialog";
import NoAccessDialog from "./NoAccessDialog";
import SelectionDialog from "./SelectionDialog";

/**
 * A component that allows the user to select a property as the context for
 * their workspace.
 */
const PropertyWorkspaceSelector = () => {

  const {t} = useTranslation(["glossary", "common", "App"]);
  const customer = useSelector(state => state.workspace.customer);
  const property = useSelector(state => state.workspace.property);
  const properties = useSelector(state => state.properties.data);
  const loading = useSelector(state => StateUtility.areBusy(
      state.auth,
      state.workspace,
      state.properties,
  ));

  const accessibleProperties = useAccessibleProperties();
  const [_setProperty] = useActions([setProperty]);
  useEffect(() => {
    if (accessibleProperties?.length === 1) {
      _setProperty({property: accessibleProperties[0]});
    }
  }, [accessibleProperties]);

  const shouldShowDialog = customer?.id != null && property?.id == null;
  const hasProperties = !!properties.length;
  const hasAccessibleProperties = !!accessibleProperties?.length;

  const [showDialog, toggleDialog] = useState(shouldShowDialog);
  useEffect(() => toggleDialog(shouldShowDialog), [shouldShowDialog]);
  const hideDialog = useCallback(() => toggleDialog(false), []);

  return (<>

    {hasAccessibleProperties && <WorkspaceSelectorButton
        tooltip={t("App:Workspace.Button.Tooltip.Property")}
        onClick={() => toggleDialog(true)}
        disabled={loading}
    >
      {property?.id
          ? property?.name
          : t("App:Workspace.Button.Placeholder.Property")}
    </WorkspaceSelectorButton>}

    <SelectionDialog
        open={showDialog && hasAccessibleProperties && !loading}
        onClose={hideDialog}
        disabled={loading}
    />

    <CreationDialog
        open={showDialog && !hasProperties && !loading}
        onClose={hideDialog}
        disabled={loading}
    />

    <NoAccessDialog
        open={showDialog
        && hasProperties
        && !hasAccessibleProperties
        && !loading}
        disabled={loading}
    />

  </>);

};

export default PropertyWorkspaceSelector;
