/*
 * Created by Paul Engelke on 17 March 2021.
 */

import axios from 'axios';
import qs from "qs";
import HttpError from "./error/httpError";
import AuthResponseInterceptor from "./authResponseInterceptor";

const ENVIRONMENT = process.env.NODE_ENV;
const URL_ENVIRONMENT = process.env.REACT_APP_URL_ENVIRONMENT;
const TIMEOUT_THRESHOLD =
    ENVIRONMENT === 'development'
        ? 120000
        : 300000; // ms

/**
 * Gets the base URL based on the current node environment.
 * @private
 */
const getBaseUrl = () => {

  const production = "https://nebulapos.hti.app/rest/";
  const regression = "https://regression-nebulapos.hti.app/rest/";
  const test = "https://test-nebulapos.hti.app/rest/";
  const development = "http://localhost:8080/nebulapos/rest/";

  if (ENVIRONMENT === "production") {

    switch (URL_ENVIRONMENT) {

      case "production":
        return production;

      case "test":
        return test;

      case "regression":
        return regression;

      default:
        console.error("No url environment was set!");
        return null;

    }

  }

  return development;

}

const baseUrl = getBaseUrl();

const _instance = axios.create({
  baseURL: baseUrl,
  timeout: TIMEOUT_THRESHOLD,
  paramsSerializer: (params) =>
      qs.stringify(params, {arrayFormat: 'repeat'}),
});

_instance.interceptors.response.use(undefined,
    AuthResponseInterceptor(_instance));

/**
 * A class for performing Http requests.
 */
export default class HttpManager {

  /**
   * The current user's session token.
   * @private
   */
  static _token;

  constructor() {
    throw new TypeError('This class cannot be instantiated.');
  }

  /**
   * Sets the authentication token.
   * @param {string} token The new token.
   */
  static setToken(token) {
    _instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    this._token = token;
  }

  /**
   * Gets the current authentication token.
   * @return {string}
   */
  static getToken() {
    return this._token;
  }

  /**
   * Performs a GET request.
   *
   * @param {string} url The request URL.
   * @param {Object} [config] The request configuration.
   * @return {Promise}
   */
  static get(url, config) {
    return _instance.get(url, config)
    .catch(e => {
      throw new HttpError(e);
    });
  }

  /**
   * Performs a POST request.
   *
   * @param {string} url The request URL.
   * @param {Object} [data] The request data.
   * @param {Object} [config] The request configuration.
   * @return {Promise}
   */
  static post(url, data, config) {
    return _instance.post(url, data, config)
    .catch(e => {
      throw new HttpError(e);
    });
  }

  /**
   * Performs a PUT request.
   *
   * @param {string} url The request URL.
   * @param {Object} [data] The request data.
   * @param {Object} [config] The request configuration.
   * @return {Promise}
   */
  static put(url, data, config) {
    return _instance.put(url, data, config)
    .catch(e => {
      throw new HttpError(e);
    });
  }

  /**
   * Performs a DELETE request.
   *
   * @param {string} url The request URL.
   * @param {Object} [config] The request configuration.
   * @return {Promise}
   */
  static delete(url, config) {
    return _instance.delete(url, config)
    .catch(e => {
      throw new HttpError(e);
    });
  }

  /**
   * Acquires a cancellation token source, whose token can be set in a
   * request's configuration.
   *
   * Calling {@code source.cancel()} will cause the request to terminate and
   * throw an error that must be handled by one the promise chain's
   * {.catch()} handlers.
   *
   * @return {*}
   */
  static getCancellationTokenSource() {
    return axios.CancelToken.source();
  }

  /**
   * Checks if the given error indicates that the request was cancelled on the
   * client.
   * @param {StdError} error The error that was thrown in the request promise
   * chain.
   * @return {boolean}
   */
  static isCancelled(error) {
    const cause = error.getError();
    return axios.isCancel(cause);
  }

}
