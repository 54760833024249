/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CustomerActionTypes from "../constants/action-types/customerActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A reducer for managing a list of customers.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CustomerActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CustomerActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case CustomerActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CustomerActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case CustomerActionTypes.MERGE:
      return merge(state, action);

    case CustomerActionTypes.CREATE:
      return create(state, action);

    case CustomerActionTypes.UPDATE:
      return update(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Merges one or more customers into the existing list.
 * The Existing list will maybe be replaced.
 */
const merge = (state, action) => {
  const next = StateUtility.mergeData(state.data, action.data);
  return StateUtility.defaultSet(state, next);
};

/**
 * Adds a new customer to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing customer in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};


