import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import PmsFailedDistributionActionTypes
  from "../constants/action-types/pmsFailedDistributionActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of PMS failed distribution.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case PmsFailedDistributionActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case PmsFailedDistributionActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case PmsFailedDistributionActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case PmsFailedDistributionActionTypes.CREATE:
      return create(state, action);

    case PmsFailedDistributionActionTypes.UPDATE:
      return update(state, action);

    case PmsFailedDistributionActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new PMS interface to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing PMS interface in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted PMS interface from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
