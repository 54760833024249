/*
 * Created by Paul Engelke on 19 January 2023.
 */

import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {useActions} from "@hti-ui/redux-core";
import {signInWithGoogle} from "../../actions/authActions";
import useErrorHandler from "./useErrorHandler";
import GoogleSignInButton from "../common/button/GoogleSignInButton";
import {useCallback} from "react";
import RouteNames from "../../constants/routeNames";

/**
 * A button that allows the user to sign in using Google Sign-In.
 */
const GoogleSignInOption = props => {

  const history = useHistory();

  const signIn = useActions(signInWithGoogle);
  const handlerError = useErrorHandler();

  const onSignInSuccessful = useCallback(args => {
    signIn(args)
    .then(() => history.push(RouteNames.Root))
    .catch(e => handlerError(e));
  }, [signIn, history, handlerError]);

  return (<GoogleSignInButton
      onSuccess={onSignInSuccessful}
      disabled={props.disabled}
  />);

};

GoogleSignInOption.propTypes = {
  disabled: PropTypes.bool,
};

export default GoogleSignInOption;
