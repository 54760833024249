/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for the cost price type redux state.
 */
const CostPriceTypeActionTypes = ActionTypeUtility
.createActionTypes('COST_PRICE_TYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: ActionTypeUtility.SET,
});

export default CostPriceTypeActionTypes;
