/*
 * Created by Paul Engelke on 18 March 2021.
 */

import decodeToken from 'jwt-decode';
import Module from '../constants/security/modules';
import UserRight from "../constants/security/userRights";
import {LocalStorage} from "./local-storage";

/**
 * A utility class for front-end application security.
 */
export default class SecurityUtility {

  /**
   * Checks if a given token is valid. That is, it has not expired.
   *
   * @param {string} token The token to validate.
   * @return {boolean}
   */
  static isTokenValid = (token) => {

    if (!token) {
      return false;
    }

    const decoded = decodeToken(token);
    const {exp} = decoded;
    const now = Date.now().valueOf() / 1000;

    return exp != null && exp > now;
  };

  /**
   * Parses the given JWT token and maps it to a readable object.
   *
   * @param {string} token The token to decode.
   * @return {null|any}
   */
  static parseToken = (token) => {
    if (!token) {
      return null;
    }
    return decodeToken(token);
  };

  /**
   * Sets the JWT token in local storage.
   * @param {string} token The user's JWT token.
   */
  static setToken(token) {
    LocalStorage.JwtToken.set(token);
  }

  /**
   * Gets the stored JWT token from local storage.
   * @return {string}
   */
  static getToken() {
    return LocalStorage.JwtToken.get();
  }

  /**
   * Removes the user's JWT token from local storage.
   */
  static revokeToken() {
    LocalStorage.JwtToken.remove();
  }

  /**
   * Sets the JWT refresh token in local storage.
   * @param {string} refreshToken The user's JWT refresh token.
   */
  static setRefreshToken(refreshToken) {
    LocalStorage.JwtRefreshToken.set(refreshToken);
  }

  /**
   * Gets the stored JWT refresh token from local storage.
   * @return {string}
   */
  static getRefreshToken() {
    return LocalStorage.JwtRefreshToken.get();
  }

  /**
   * Removes the user's JWT refresh token from local storage.
   */
  static revokeRefreshToken() {
    LocalStorage.JwtRefreshToken.remove();
  }

  /**
   * Checks if the user is authorized to access some part of the system or
   * perform a certain action and that their license allows access to the
   * functionality associated with the user right(s).
   *
   * This should be used as the primary authorization method to call, since it
   * ensures both license and user right authorization.
   *
   * @param {Object} args The method parameters.
   * @param {Object} args.user The signed-in user.
   * @param {Object} args.userRole The current user's security role.
   * @param {Object} args.licence The current license from the user's
   * workspace.
   * @param {number} [args.propertyId] The ID of a property, for property-restricted license modules.
   * @param {boolean} [args.skipPropertyCheck=false] Should the property
   * check be skipped? This is useful for cases where a property has not yet
   * been set in the workspace context, but the user should be allowed to
   * pick one before being kicked off a page.
   * @param {Array} args.rights One or more rights that the user may require
   * to perform some action or see some data.
   * @param {boolean} [args.all=true] Should the user be licensed and
   * authorized for every user right in the given list? If false, only one
   * right will need to be present for this method to return true.
   * @return {boolean}
   * @public
   */
  static isLicensedAndAuthorized = args => {

    const {
      user, licence,
      userRole, rights, all,
      propertyId, skipPropertyCheck,
    } = args;

    const modules = rights?.map(r => r.module) ?? [];
    const customerTypes = this.getListOfUniqueCustomerTypes(rights);
    return this.isLicensed({
          licence, modules, all,
          propertyId, skipPropertyCheck,
        })
        && this.hasElevatedAccess({user, customerTypes})
        && this.isAuthorized({user, userRole, rights, all});
  };

  /**
   * Determines whether a user is authorized for some action or data
   * based their user rights.
   *
   * @param {Object} args The function parameters.
   * @param {Object} args.user The signed-in user.
   * @param {Object} args.userRole The user's security role.
   * @param {Array} args.rights One or more rights that the user may require
   * to perform some action or see some data.
   * @param {boolean} [args.all=true] Should every specified right exist in
   * the user role? If not, then the user will be authorized when at least one
   * of the user rights exist in the user role.
   * @return {boolean} True, if authorized, else false.
   * @public
   */
  static isAuthorized = args => {

    if (SecurityUtility.isUnrestricted(args)) {
      return true;
    }

    const {all = true, userRole} = args ?? {};
    const queryRights = args.rights ?? [];
    const authorizedRights = userRole?.rights ?? new Set();

    if (all) {
      // Match every required right.
      for (const r of queryRights) {
        if (!authorizedRights.has(r.id)) {
          return false;
        }
      }
      return true;
    }

    // Otherwise, find at least one right.
    for (const r of queryRights) {
      if (authorizedRights.has(r.id)) {
        return true;
      }
    }
    return false;

  };

  /**
   *
   * @param {Object} args The function parameters.
   * @param {Object} args.user The signed-in user.
   * @param {Set} args.customerTypes One or more customer types the user may
   * require to perform some action or see some data.
   * @return {boolean} True, if it has elevated access, else false.
   */
  static hasElevatedAccess = args => {
    const {user, customerTypes} = args ?? {};
    const {customerType} = user ?? {};

    if (customerTypes.size === 0) {
      return true;
    } else {
      return customerTypes.has(customerType);
    }
  };

  /**
   * Checks if the user has unrestricted access to the system.
   *
   * @param {Object} args.user The signed-in user.
   * @param {Object} args.userRole The user's security role.
   * @return {boolean}
   * @public
   */
  static isUnrestricted(args) {
    const {user, userRole} = args;
    return !!userRole?.rights?.has(UserRight.HtiFullAccess.id)
        || this.isAdmin(user);
  }

  /**
   * Checks if a user is a system administrator.
   * @param {Object} user The user entity.
   * @return {boolean}
   */
  static isAdmin(user) {
    return !!user?.admin;
  }

  /**
   * Checks if the user is licensed for one or more modules within the system.
   *
   * @param {Object} args The method parameters.
   * @param {Object} args.licence The license for the current user or their
   * workspace.
   * @param {number} [args.propertyId] The ID of a property, for property-restricted license modules.
   * @param {boolean} [args.skipPropertyCheck=false] Should the property
   * check be skipped? This is useful for cases where a property has not yet
   * been set in the workspace context, but the user should be allowed to
   * pick one before being kicked off a page.
   * @param {Array} args.modules A list of modules required for access to be
   * granted.
   * @param {boolean} [args.all=true] Should all modules be necessary for
   * access to be granted? If false, access will be granted where at least one
   * module exists in the list.
   * @return {boolean} True, if access should be granted, else false.
   * @public
   */
  static isLicensed = (args) => {

    const {licence, propertyId, skipPropertyCheck = false, all = true} = args;
    const modules = args.modules ?? [];
    const licensedModules = licence?.package?.modules ?? [];

    const isBaseModule = m => m?.id === Module.Base.id;
    const hasModule = m =>
        (licensedModules.some((lm) =>
            (!!lm && !!m && lm?.id === m?.id)));

    const isPropertyAllowed = m => {

      if (skipPropertyCheck) {
        return true;
      }

      const lm = licensedModules.find(lm => lm.id === m.id);
      let {allowedProperties} = lm ?? {};
      if (!allowedProperties) {
        // The module does not require property restriction.
        return true;
      }

      if (!propertyId) {
        // The module is property restricted, but no property ID was given.
        return false;
      }

      allowedProperties = new Set(allowedProperties);
      return allowedProperties.has(propertyId);

    };

    if (all) {
      // Match every module.
      for (const m of modules) {
        if (!isBaseModule(m) && !hasModule(m)) {
          return false;
        }
        if (!isPropertyAllowed(m)) {
          // The licence has the module, but the current property is not
          // allowed to access its feature set.
          return false;
        }
      }
      return true;
    }

    // Otherwise, match at least one module.
    for (const m of modules) {
      if ((isBaseModule(m) || hasModule(m)) && isPropertyAllowed(m)) {
        return true;
      }
    }

    return false;

  };

  /**
   * Checks if the user's license is the given tier.
   *
   * @param {Object} user The user, whose license will be evaluated.
   * @param {string} tier The license tier that is required.
   * @return {boolean} True, if the user's license is of the required tier,
   * else false.
   * @see LicenceTier
   * @public
   */
  static hasLicenceTier(user, tier) {
    const {licence} = user;
    const {package: licencePackage} = licence || {};
    const {package: licenceTier = ''} = licencePackage || {};
    return licenceTier === tier;
  }

  /**
   * Each user right can have an array of customer types. This function will
   * merge all these customerType arrays into one array with unique types.
   *
   * @param {Array} rights An array of user rights
   * @return {Set} A set with unique values
   */
  static getListOfUniqueCustomerTypes(rights) {

    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }

    let customerTypes = [];
    rights?.forEach(r => {
      customerTypes = customerTypes.concat(r.customerTypes || []);
    });

    const uniqueTypes = customerTypes.filter(unique)

    return new Set(uniqueTypes);
  }

}
