/*
 * Created by Kevin May 2021.
 */
import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the GL Export Data redux state.
 */
const GlExportActionTypes = ActionTypeUtility
.createActionTypes('GL_EXPORT_DATA', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  REQUEST_WRITE: 'REQUEST_WRITE',
  FAIL_REQUEST_WRITE: 'FAIL_REQUEST_WRITE',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  RECEIVED_DATA: 'RECEIVED_DATA',
});

export default GlExportActionTypes;
