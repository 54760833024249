/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ShiftActionTypes from "../constants/action-types/shiftActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of shifts.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ShiftActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ShiftActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case ShiftActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case ShiftActionTypes.CREATE:
      return create(state, action);

    case ShiftActionTypes.UPDATE:
      return update(state, action);

    case ShiftActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new shift to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing shift in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted shift from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
