/*
 * Created by Stanton Francis on 12 October 2021.
 */

const CustomerType = {

  HTI: "HTI",
  VAR_PARTNER: "VAR Partner",
  STANDARD: "Standard",
  SUPPLIER: "Supplier",
  CUSTOMER_GROUP: "Customer Group",

};

export {CustomerType};
