/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {makeStyles} from "@material-ui/core";
import {useImageAssets} from "../assets/images";

/**
 * A splash screen component for preventing a user from interacting or viewing
 * the app.
 *
 * A prominent use case is session initialization on routing.
 */
const SplashScreen = () => {
  const classes = useStyles();
  const ImageAssets = useImageAssets();
  return (<div className={classes.root}>
    <img
        className={classes.logo}
        src={ImageAssets.PosIconThin}
        alt={'NebulaPOS'}
    />
  </div>);
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0, bottom: 0,
    left: 0, right: 0,
    backgroundColor: theme.palette.base['100%'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {height: 944 * .3, width: 835 * .3},
}));

export default SplashScreen;
