/*
 * Created by Paul Engelke on 13 August 2021.
 */

import {LocalMallOutlined as MarketPlaceIcon} from "@material-ui/icons";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality related our application marketplace.
 */
const MarketPlaceNode = ({goTo, t}) => ({
  id: "Market Place",
  label: t("core:Term.MarketPlace"),
  quickLink: {IconComponent: MarketPlaceIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  children: [

    {
      id: RouteNames.StockInterfacePage,
      label: t("core:Term.StockInterfaces"),
      onClick: goTo(RouteNames.StockInterfacePage),
      security: {rights: [UserRight.HtiFullAccess]},
    },

  ],
});

export default MarketPlaceNode;
