/*
 * Created by Stanton J Francis on 04 April 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the reason type redux state.
 */
const ReasonTypeActionTypes = ActionTypeUtility
.createActionTypes('REASON_TYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default ReasonTypeActionTypes;
