/*
 * Created by Stanton J Francis on 13 July 2024.
 */

import {
  Box,
  TextField,
  Tooltip,
  TooltipContent
} from "@hti-ui/react-web-material";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IconButton, InputAdornment, Typography} from "@material-ui/core";
import {
  CheckCircleOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined
} from "@material-ui/icons";
import {useSelector} from "react-redux";
import {useActions} from "@hti-ui/redux-core";
import {fetchCustomerPasswordPolicy} from "../../actions/customerActions";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A password field that has a unmasking feature to only the user to view the
 * password they typed.
 */
const PasswordField = props => {

  const customerId = useSelector(state => state.workspace.customer?.id);
  const sessionCustomerId = useSelector(
      state => state.session.user?.customerId);
  const {handleError} = useGlobalMessenger();
  const [_fetchPasswordPolicy] = useActions([fetchCustomerPasswordPolicy]);

  const {
    value,
    onChange,
    showPasswordComplexity = false,
    setPasswordInvalid = null,
    useSessionCustomerId = false,
    ...rest
  } = props;

  const [_value, setValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState({});

  useEffect(() => setValue(value), [value]);

  useEffect(() => {
    const currentCustomerId = useSessionCustomerId ? sessionCustomerId
        : customerId;
    if (currentCustomerId > 0) {
      _fetchPasswordPolicy({customerId: currentCustomerId})
      .then((r) => {
        if (r?.data && Object.keys(r.data.policy).length > 0) {
          setPasswordPolicy(r.data.policy);
        } else {
          setPasswordPolicy({});
        }
      })
      .catch(e => {
        handleError(e, 'The password policy could not be retrieved');
      });
    }
  }, [customerId, sessionCustomerId, useSessionCustomerId]);

  const onBlur = useCallback(() => {
    setShowPassword(false);
  }, [setShowPassword]);

  const handleClickShowPassword = useCallback(
      () => setShowPassword(!showPassword), [showPassword]);

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  }, []);

  const isPolicyEnabled = useMemo(() => {
    const {usePolicy = false} = passwordPolicy ?? {};
    return usePolicy;
  }, [passwordPolicy]);

  const passwordRegex = useMemo(() => {
    let regexParts = [];
    if (passwordPolicy.includeNumber) {
      regexParts.push('(?=.*\\d)'); // At least one digit
    }
    if (passwordPolicy.includeLowercase) {
      regexParts.push('(?=.*[a-z])'); // At least one lowercase letter
    }
    if (passwordPolicy.includeUppercase) {
      regexParts.push('(?=.*[A-Z])'); // At least one uppercase letter
    }
    if (passwordPolicy.includeSpecialCharacter) {
      regexParts.push('(?=.*[!@#$%^&*])'); // At least one special character
    }

    // Join all parts and add the minimum length requirement
    const regexString = `^${regexParts.join(
        '')}.{${passwordPolicy.passwordLength},}$`;
    return new RegExp(regexString);
  }, [passwordPolicy]);

  const requirements = useMemo(() => {
    const policy = Object.keys(passwordPolicy);
    if (isPolicyEnabled && policy.length > 0) {
      let result = [];
      policy.forEach(k => {
        if (k === 'includeNumber') {
          result.push({
            [k]: passwordPolicy[k],
            title: 'Must include a number (0-9)',
            passwordRegex: /\d/
          })
        } else if (k === 'includeLowercase') {
          result.push({
            [k]: passwordPolicy[k],
            title: 'Must include a lowercase letter (a-z)',
            passwordRegex: /[a-z]+/
          })
        } else if (k === 'includeUppercase') {
          result.push({
            [k]: passwordPolicy[k],
            title: 'Must include a uppercase letter (A-Z)',
            passwordRegex: /[A-Z]/
          })
        } else if (k === 'includeSpecialCharacter') {
          result.push({
            [k]: passwordPolicy[k],
            title: 'Must include a special character (!@#$%^&*)',
            passwordRegex: /[!@#$%^&*()]/
          })
        } else if (k === 'passwordLength') {
          result.push({
            [k]: passwordPolicy[k],
            title: `Minimum password length ${passwordPolicy[k]}`,
            passwordRegex: new RegExp(`.{${passwordPolicy[k]},}`),
          });
        }
      });
      return result;
    } else {
      return [];
    }
  }, [passwordPolicy, isPolicyEnabled]);

  const isPasswordInvalid = useMemo(() => {
    const result = showPasswordComplexity && isPolicyEnabled
        && _value?.length > 1
        && !passwordRegex.test(_value);
    if (setPasswordInvalid) {
      setPasswordInvalid(result);
    }
    return result;
  }, [_value, showPasswordComplexity, passwordRegex, isPolicyEnabled]);

  return (
      <Tooltip wrapChildren={isPolicyEnabled && showPasswordComplexity
          && isPasswordInvalid}
               placement={'top'}
               trigger={'mouseenter focus focusin'}
               content={(
                   <TooltipContent title={'Password Policy'}
                                   subtitle={'Please ensure your password meets these requirements'}
                                   width={400}>
                     <Box flexDirection={'column'} topPadding={1}
                          rightPadding={2}
                          leftPadding={2} bottomPadding={2}>
                       {
                         requirements.map(
                             (r, i) => (
                                 <Box key={`ppc-${i}`} flexDirection={"row"}>
                                   <CheckCircleOutlined
                                       style={{
                                         marginRight: '8px',
                                         color: r.passwordRegex.test(_value)
                                             ? 'rgb(19, 128, 37)'
                                             : 'rgba(0, 0, 0, 0.54)'
                                       }}/>
                                   <Typography color={"textSecondary"}
                                               style={r.passwordRegex.test(
                                                   _value)
                                                   ? {
                                                     color: 'rgb(19, 128, 37)',
                                                     fontWeight: 500
                                                   }
                                                   : null}>
                                     {r.title}
                                   </Typography>
                                 </Box>))
                       }
                     </Box>
                   </TooltipContent>
               )}>
        <TextField
            autoFocus
            fullWidth
            {...rest}
            value={_value}
            onChange={(v) => {
              onChange(v);
              setValue(v);
            }}
            onBlur={onBlur}
            type={showPassword ? 'text' : 'password'}
            error={isPasswordInvalid}
            helperText={isPasswordInvalid
                ? 'Password does not meet policy requirements' : null}
            InputProps={{
              endAdornment: (<InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOutlined/> :
                      <VisibilityOffOutlined/>}
                </IconButton>
              </InputAdornment>)
            }}
        />
      </Tooltip>
  );

};

PasswordField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setPasswordInvalid: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  showPasswordComplexity: PropTypes.bool,
  useSessionCustomerId: PropTypes.bool,
};

export default PasswordField;
