/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {ComponentContextProvider} from '@hti-ui/react-web-material';
import {useActions} from "@hti-ui/redux-core";
import {useMediaQuery} from "@material-ui/core";
import {Suspense, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Provider as ReduxProvider} from 'react-redux';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import {setAppPreferences} from "../actions/appPreferenceActions";
import RouteNames from "../constants/routeNames";
import UserRight from "../constants/security/userRights";
import ThemeTypes from "../constants/themeTypes";
import useAppPreferences from "../hooks/useAppPreferences";
import useTheme from "../hooks/useTheme";
import useUserAuthorization from "../hooks/useUserAuthorization";
import store from "../store";
import {LocalStorage} from "../utils/local-storage";
import AccountActivationPage from "./AccountActivationPage";
import UserActivationPage from "./UserActivationPage";
import AccountRegistrationPage from "./AccountRegistrationPage";
import App from "./App";
import ErrorPage from "./ErrorPage";
import GlobalSnackbar from "./GlobalSnackbar";
import LoginPage from "./LoginPage";
import PrivateRoute from "./PrivateRoute";
import LoadingDialog from "./BarcodePage/DownloadDialog/LoadingDialog";

/**
 * The entry point for the app.
 *
 * It is responsible for providing global context to the rest of the app.
 */
const Root = () => {

  const theme = useTheme();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const _setAppPreferences = useActions(setAppPreferences);

  /*
   * Set the app preferences in the redux state based on the user's current
   * system preferences, or saved app preferences.
   */
  useEffect(() => {

    const preferences = LocalStorage.AppPreferences.get();
    if (preferences) {
      _setAppPreferences({preferences});
    } else {
      _setAppPreferences({
        preferences: {
          theme: prefersDarkMode ? ThemeTypes.Dark : ThemeTypes.Light,
          language: "en",
        }
      });
    }

  }, [prefersDarkMode]);

  const localizationEnabled = useUserAuthorization([UserRight.HtiFullAccess]);
  const language = useAppPreferences().language;
  const translator = useTranslation();

  /*
   * Listen for changes in the user's language settings.
   */
  useEffect(() => {
    if (translator.ready) {
      translator.i18n
      .changeLanguage(localizationEnabled ? language : "en")
      .finally(() => undefined);
    }
  }, [language, translator.ready, localizationEnabled]);

  return (<ComponentContextProvider
      theme={theme}
      experimentalSettings={{SideDrawer: {defaultProps: {unmountOnExit: false}}}}
  >
    <>

      <Router>
        <Suspense fallback={<LoadingDialog open={true}/>}>
          <Switch>

            <Route path={RouteNames.LoginPage} component={LoginPage}/>

            <Route
                path={RouteNames.AccountRegistrationPage}
                component={AccountRegistrationPage}
            />

            <Route
                path={RouteNames.AccountActivationPage}
                component={AccountActivationPage}
            />

            <Route
                path={RouteNames.UserActivationPage}
                component={UserActivationPage}
            />

            <Route path={RouteNames.ErrorPage} component={ErrorPage}/>

            <PrivateRoute path={RouteNames.Root} component={App}/>

          </Switch>
        </Suspense>
      </Router>

      <GlobalSnackbar/>

    </>
  </ComponentContextProvider>);

};

/**
 * A higher order component that provides the redux store context. This allows
 * Root to determine the correct theme to inject based on the theme redux state.
 */
const withReduxProvider = (Component) => () =>
    (<ReduxProvider store={store}>
      <Component/>
    </ReduxProvider>);

export default withReduxProvider(Root);
