/*
 * Created by Kevin June 2021.
 */

import {EmailAddressValidator} from "@hti-ui/js-core";
import {
  Box,
  EmailField,
  LoadingIndicator,
  Page,
  PageContent,
  TextField
} from "@hti-ui/react-web-material";
import {StateUtility, useActions} from "@hti-ui/redux-core";
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import {useCallback, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {registerCustomerAccount, verifyReCaptchaToken} from "../../actions/registrationActions";
import {useImageAssets} from "../../assets/images";
import RouteNames from "../../constants/routeNames";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import CountryAutocomplete from "../common/CountryAutocomplete";
import useErrorHandler from "./useErrorHandler";
import GoogleSignInOption from "./GoogleSignInOption";

/**
 * A page for registering a new customer account.
 */
const AccountRegistrationPage = () => {

  const classes = useStyles();
  const ImageAssets = useImageAssets();
  const history = useHistory();

  const {dispatchErrorMessage} = useGlobalMessenger();
  const handleError = useErrorHandler();
  const [_register, _verifyReCaptchaToken] = useActions(
      [registerCustomerAccount, verifyReCaptchaToken]);
  const loading = useSelector(state => StateUtility.areBusy(
      state.auth,
      state.registration,
      state.countries,
  ));

  const [firstName, setFirstName] = useState(null);
  const _firstName = firstName?.trim();

  const [lastName, setLastName] = useState(null);
  const _lastName = lastName?.trim();

  const [contactNumber, setContactNumber] = useState(null);
  const _contactNumber = contactNumber?.trim();

  const [email, setEmail] = useState("");
  const _email = email?.trim();
  const [password, setPassword] = useState(null);

  const [city, setCity] = useState(null);
  const _city = city?.trim();

  const [country, setCountry] = useState(null);
  const countryId = country?.id;

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const disabledRegister =
      loading
      || !_firstName
      || !_lastName
      || !_email
      || !EmailAddressValidator.validate(_email)
      || !password
      || !recaptchaToken;

  const [complete, setComplete] = useState(false);

  /**
   * Registers a new customer account in NebulaPOS.
   */
  const onRegisterClicked = useCallback(() => {
    _register({
      name: _firstName,
      surname: _lastName,
      email: _email,
      password,
      tel: contactNumber,
      city: _city,
      countryID: countryId,
    })
    .then(() => setComplete(true))
    .catch(e => handleError(e));
  }, [
    _firstName, _lastName, _contactNumber,
    _email, password,
    _city, countryId,
    _register, dispatchErrorMessage,
  ]);

  const recaptchaHandler = useCallback((value) => {
    if (value) {
      _verifyReCaptchaToken({response: value})
      .then(r => {
        const {success} = r.data;
        if (success) {
          setRecaptchaToken(value);
        }
      })
      .catch(() => {
        dispatchErrorMessage("The reCAPTCHA token could not be verified.")
        setRecaptchaToken(null);
      });
    } else {
      setRecaptchaToken(null);
    }
  }, [_verifyReCaptchaToken]);

  return (<Page>

    <LoadingIndicator visible={loading}/>

    <PageContent>

      <img
          className={classes.logo}
          src={ImageAssets.PosLogoHorizontal}
          alt={'nebula-pos-logo'}
      />

      {complete && <div className={classes.formWrapper}>
        <Typography className={classes.paragraph}>
          You have successfully registered an account for the NebulaPOS system!
          An <b>email</b> has been sent to the address, with which you
          registered, containing a <b>verification code</b> that can be used to
          let us know your email address is correct. Once you've verified your
          email address, you can sign in to NebulaPOS.
        </Typography>
        <Box
            width={"100%"}
            justifyContent={"center"}
            flexDirection={"row"}
        >
          <Button
              variant={'contained'}
              color={'secondary'}
              onClick={() => history.replace(RouteNames.LoginPage)}
          >Go To Sign-In Screen</Button>
        </Box>
      </div>}

      {!complete
          && <div className={classes.formWrapper}>

            <div className={classes.loginOptionsWrapper}>
              <GoogleSignInOption disabled={loading}/>
            </div>

            <div className={classes.loginOptionDividerWrapper}>
              <div className={classes.loginOptionDivider}/>
              <p className={classes.loginOptionDividerText}>OR</p>
              <div className={classes.loginOptionDivider}/>
            </div>

            <Typography className={classes.paragraph}>
              If you don't want to use one of the social media sign-in methods
              above, you may register using a more traditional method below.
              Please
              enter all required fields to continue.
            </Typography>

            <Grid container spacing={1}>

              <Grid item md={6} xs={12}>
                <TextField
                    label={"First Name"}
                    value={firstName}
                    onChange={setFirstName}
                    disabled={loading}
                    required
                    fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                    label={"Last Name"}
                    value={lastName}
                    onChange={setLastName}
                    disabled={loading}
                    required
                    fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <EmailField
                    label={"Email Address"}
                    value={email}
                    onChange={setEmail}
                    disabled={loading}
                    required
                    fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                    label={"Password"}
                    type={"password"}
                    value={password}
                    onChange={setPassword}
                    disabled={loading}
                    required
                    fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                    label={"Contact Number"}
                    value={contactNumber}
                    onChange={setContactNumber}
                    disabled={loading}
                    fullWidth
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                    label={"City"}
                    value={city}
                    onChange={setCity}
                    disabled={loading}
                    fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <CountryAutocomplete
                    value={country}
                    onChange={setCountry}
                    disabled={loading}
                />
              </Grid>

              <Grid
                  container alignItems={"center"} justifyContent={"center"}
                  item xs={12}
              >
                <ReCAPTCHA
                    sitekey={'6Ldgv4odAAAAAIXzv798H8qAnd9zfk_YoUNyLvvz'}
                    onChange={recaptchaHandler}
                    className={classes.recaptcha}
                />
              </Grid>

              <Grid
                  container alignItems={"center"} justifyContent={"center"}
                  item xs={12}
              >
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={onRegisterClicked}
                    disabled={disabledRegister}
                >Register</Button>
              </Grid>

            </Grid>

          </div>}

    </PageContent>

  </Page>);

};

const useStyles = makeStyles(theme => ({

  logo: {
    width: 'calc(100vw / 5)',
    minHeight: 60,
    minWidth: 300,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    alignSelf: "center",
  },

  formWrapper: {
    alignSelf: "center",
    maxWidth: 800,
    borderRadius: theme.spacing(),
    border: `1px solid ${theme.palette.compliment['100%']}`,
    padding: theme.spacing(2),
  },

  loginOptionsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },

  loginOptionDividerWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  loginOptionDivider: {
    height: 1,
    backgroundColor: theme.palette.compliment['100%'],
    flex: 1,
  },

  loginOptionDividerText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },

  paragraph: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(15),
  },

  recaptcha: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }

}));

export default AccountRegistrationPage;
