/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {Chip, Grid, makeStyles} from "@material-ui/core";
import CustomerWorkspaceSelector from "./CustomerWorkspaceSelector";
import PropertyWorkspaceSelector from "./PropertyWorkspaceSelector";
import UserWidget from "./UserWidget";
import {useMemo} from "react";

/**
 * A toolbar that gives the user the ability to
 */
const WorkspaceBar = () => {

  const classes = useStyles();

  const instance = useMemo(() => {
    switch (process.env.REACT_APP_URL_ENVIRONMENT) {
      case "production":
        return null;
      case "test":
        return <Chip label={'Test'} className={classes.chipTest}/>;
      case "regression":
        return <Chip label={'Regression'} className={classes.chipRegression}/>;
      default:
        return <Chip label={'Development'} className={classes.chipDev}/>;
    }
  }, [process.env.REACT_APP_URL_ENVIRONMENT]);

  return (<Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      className={classes.root}
  >

    <Grid
        container
        item xs={12} sm={8}
        alignItems={'center'}
        justifyContent={'flex-start'}
    >
      <CustomerWorkspaceSelector/>
      <PropertyWorkspaceSelector/>
    </Grid>

    <Grid
        container item xs={12} sm={4}
        alignItems={'center'}
        justifyContent={'flex-end'}
        className={classes.content}
    >
      {instance}
      <UserWidget/>
    </Grid>

  </Grid>);

};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.base['100%'],
    borderBottom: `1px solid ${theme.palette.compliment['30%']}`,
    padding: theme.spacing(.5),
  },
  content: {
    padding: theme.spacing(.5),
  },
  chipTest: {
    marginRight: theme.spacing(2),
    color: theme.palette.custom.white,
    backgroundColor: theme.palette.custom.blue,
  },
  chipRegression: {
    marginRight: theme.spacing(2),
    color: theme.palette.custom.white,
    backgroundColor: theme.palette.custom.raspberry,
  },
  chipDev: {
    marginRight: theme.spacing(2),
    color: theme.palette.custom.white,
    backgroundColor: theme.palette.custom.charcoal,
  }
}));

export default WorkspaceBar;
