/*
 * Created by Robert Mason on 20 February 2023.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockScaleActionTypes from "../constants/action-types/stockScaleActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of stock scales.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockScaleActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockScaleActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockScaleActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case StockScaleActionTypes.CREATE:
      return create(state, action);

    case StockScaleActionTypes.UPDATE:
      return update(state, action);

    case StockScaleActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new stock scale to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock scale in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted stock scale from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
