/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {combineReducers} from "redux";
import appPreferenceReducer from "./appPreferenceReducer";
import armAccountTypeReducer from "./armAccountTypeReducer";
import armCompanyReducer from "./armCompanyReducer";
import barcodeTemplateReducer from "./barcodeTemplateReducer";
import armProfileReducer from "./armProfileReducer";
import armStatementTemplateReducer from "./armStatementTemplateReducer";
import authReducer from "./authReducer";
import brandReducer from "./brandReducer";
import categoryReducer from "./categoryReducer";
import costCentreReducer from "./costCentreReducer";
import costPriceTypeReducer from "./costPriceTypeReducer";
import countryReducer from "./countryReducer";
import currencyReducer from "./currencyReducer";
import customerLicencePrototypeReducer from "./customerLicencePrototypeReducer";
import customerLicenceReducer from "./customerLicenceReducer";
import customerReducer from "./customerReducer";
import customerTypeReducer from "./customerTypeReducer";
import customMenuReducer from "./customMenuReducer";
import dashboardWidgetReducer from "./dashboardWidgetReducer";
import dataExportReducer from "./dataExportReducer";
import deliveryInterfaceReducer from "./deliveryInterfaceReducer";
import deliveryInterfaceTypeReducer from "./deliveryInterfaceTypeReducer";
import deviceReducer from "./deviceReducer";
import discountReducer from "./discountReducer";
import forexRateReducer from "./forexRateReducer";
import reasonReducer from "./reasonReducer";
import glExportReducer from "./glExportReducer";
import glExportSetupReducer from "./glExportSetupReducer";
import glMappingsReducer from "./glMappingsReducer";
import globalMessengerReducer from "./globalMessengerReducer";
import globalStockDeliveryReducer from "./globalStockDeliveryReducer";
import globalStockOrderReducer from "./globalStockOrderReducer";
import groupReducer from "./groupReducer";
import outletReducer from "./outletReducer";
import paymentTypeReducer from "./paymentTypeReducer";
import pmsInterfaceReducer from "./pmsInterfaceReducer";
import pmsInterfaceTypeReducer from "./pmsInterfaceTypeReducer";
import pmsInterfaceFailedDistributionReducer
  from "./pmsInterfaceFailedDistributionReducer";
import printerReducer from "./printerReducer";
import printerTagReducer from "./printerTagReducer";
import printTemplateReducer from "./printTemplateReducer";
import printTemplateTypeReducer from "./printTemplateTypeReducer";
import productOptionMenuReducer from "./productOptionMenuReducer";
import productReducer from "./productReducer";
import productVariantReducer from "./productVariantReducer";
import propertyAdminReducer from "./propertyAdminReducer";
import propertyReducer from "./propertyReducer";
import provinceReducer from "./provinceReducer";
import reasonTypeReducer from "./reasonTypeReducer";
import registrationReducer from "./registrationReducer";
import roundingTypeReducer from "./roundingTypeReducer";
import sessionReducer from "./sessionReducer";
import setMenuReducer from "./setMenuReducer";
import shiftReducer from "./shiftReducer";
import stockCombinationManufactureReducer
  from "./stockCombinationManufactureReducer";
import stockDeliveryReducer from "./stockDeliveryReducer";
import stockDivisionManufactureReducer from "./stockDivisionManufactureReducer";
import stockInterfaceReducer from "./stockInterfaceReducer";
import stockIssueReducer from "./stockIssueReducer";
import stockManualSaleReducer from "./stockManualSaleReducer";
import stockOrderReducer from "./stockOrderReducer";
import stockOrderTemplateReducer from "./stockOrderTemplateReducer";
import stockProductReducer from "./stockProductReducer";
import stockRecipeReducer from "./stockRecipeReducer";
import stockRecipeReductionReducer from "./stockRecipeReductionReducer";
import stockReductionReducer from "./stockReductionReducer";
import stockRequisitionReducer from "./stockRequisitionReducer";
import stockStoreReducer from "./stockStoreReducer";
import stockSupplierReducer from "./stockSupplierReducer";
import stockTakeReducer from "./stockTakeReducer";
import subcategoryReducer from "./subcategoryReducer";
import taxRuleReducer from "./taxRuleReducer";
import themeReducer from "./themeReducer";
import userActionHistoryReducer from "./userActionHistoryReducer";
import userReducer from "./userReducer";
import userRoleReducer from "./userRoleReducer";
import workspaceReducer from "./workspaceReducer";
import timezoneReducer from "./timezoneReducer";
import invoiceReducer from "./invoiceReducer";
import stockScaleReducer from "./stockScaleReducer";
import imageReducer from "./imageReducer";
import loginInfoReducer from "./loginInfoReducer";
import campaignReducer from "./campaignReducer";
import otpRequiredActionReducer from "./otpRequiredActionReducer";

/**
 * The root reducer for our global redux state.
 */
export default combineReducers({

  theme: themeReducer,
  appPreferences: appPreferenceReducer,
  globalMessenger: globalMessengerReducer,

  timezones: timezoneReducer,
  currencies: currencyReducer,
  countries: countryReducer,
  provinces: provinceReducer,

  registration: registrationReducer,
  auth: authReducer,
  session: sessionReducer,
  workspace: workspaceReducer,

  dashboardWidgets: dashboardWidgetReducer,

  customerLicencePrototypes: customerLicencePrototypeReducer,
  customerLicences: customerLicenceReducer,

  customers: customerReducer,
  userRoles: userRoleReducer,
  users: userReducer,

  brands: brandReducer,
  properties: propertyReducer,
  outlets: outletReducer,
  shifts: shiftReducer,
  taxRules: taxRuleReducer,
  forexRates: forexRateReducer,

  devices: deviceReducer,
  printerTags: printerTagReducer,
  printers: printerReducer,

  groups: groupReducer,
  categories: categoryReducer,
  subcategories: subcategoryReducer,
  products: productReducer,
  productVariants: productVariantReducer,
  productOptionMenus: productOptionMenuReducer,
  customMenus: customMenuReducer,
  setMenus: setMenuReducer,

  paymentTypes: paymentTypeReducer,
  discounts: discountReducer,
  campaigns: campaignReducer,
  reasons: reasonReducer,

  reasonTypes: reasonTypeReducer,
  roundingTypes: roundingTypeReducer,
  customerTypes: customerTypeReducer,

  costPriceTypes: costPriceTypeReducer,
  printTemplateTypes: printTemplateTypeReducer,
  printTemplates: printTemplateReducer,
  stockSuppliers: stockSupplierReducer,
  stockStores: stockStoreReducer,
  stockProducts: stockProductReducer,
  costCentres: costCentreReducer,
  stockOrders: stockOrderReducer,
  stockOrderTemplates: stockOrderTemplateReducer,
  globalStockOrders: globalStockOrderReducer,
  stockDeliveries: stockDeliveryReducer,
  globalStockDeliveries: globalStockDeliveryReducer,
  stockRequisitions: stockRequisitionReducer,
  stockIssues: stockIssueReducer,
  stockCombinationManufactures: stockCombinationManufactureReducer,
  stockDivisionManufactures: stockDivisionManufactureReducer,
  stockReductions: stockReductionReducer,
  stockRecipes: stockRecipeReducer,
  stockRecipeReductions: stockRecipeReductionReducer,
  stockManualSales: stockManualSaleReducer,
  stockTakes: stockTakeReducer,
  stockScales: stockScaleReducer,

  pmsInterfaceTypes: pmsInterfaceTypeReducer,
  pmsInterfaces: pmsInterfaceReducer,
  pmsFailedDistribution: pmsInterfaceFailedDistributionReducer,

  deliveryInterfaceTypes: deliveryInterfaceTypeReducer,
  deliveryInterfaces: deliveryInterfaceReducer,

  stockInterfaces: stockInterfaceReducer,

  glMappings: glMappingsReducer,
  glExport: glExportSetupReducer,
  glExportData: glExportReducer,

  propertyAdmin: propertyAdminReducer,
  otpRequiredAction: otpRequiredActionReducer,
  dataExport: dataExportReducer,

  userActionHistory: userActionHistoryReducer,

  armCompanies: armCompanyReducer,
  armAccountTypes: armAccountTypeReducer,
  armProfiles: armProfileReducer,
  armStatementTemplates: armStatementTemplateReducer,
  images: imageReducer,
  loginInfo: loginInfoReducer,

  barcodeTemplates: barcodeTemplateReducer,

  invoices: invoiceReducer,

});
