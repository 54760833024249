/*
 * Created by Paul Engelke on 07 September 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import RegistrationActionTypes
  from "../constants/action-types/registrationActionTypes";

const DEFAULT_STATE = StateUtility.State({}, {async: true});

/**
 * A reducer for providing basic redux state for account registration.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case RegistrationActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case RegistrationActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case RegistrationActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }

};
