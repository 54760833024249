import {Dialog} from "@hti-ui/react-web-material";
import {DialogContent} from "@material-ui/core";
import PropTypes from "prop-types";
import {useImageAssets} from "../../../../assets/images";

const LoadingDialog = props => {
  const {open = false} = props ?? {};
  const ImageAssets = useImageAssets();

  return (
      <Dialog open={open} disableBackdropClick={true}
              disableEscapeKeyDown={true}>
        <DialogContent>
          <img
              style={{width: '150px', height: '150px'}}
              alt={'nebula-pos-logo'}
              src={ImageAssets.RotatingLogo}
          />
        </DialogContent>
      </Dialog>
  )
};

LoadingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default LoadingDialog;
