/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {
  KeyboardShortcutTooltipContent,
  NavigationBar as HtiNavigationBar,
  NavigationBarCrest,
  NavigationDrawer,
  Tooltip,
  useKeyboardShortcut,
} from '@hti-ui/react-web-material';
import {makeStyles} from "@material-ui/core";
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import ImageAssets from "../../../assets/images";
import DrawerFooter from "./DrawerFooter";
import DrawerHeader from "./DrawerHeader";
import NavigationQuickLinks from "./NavigationQuickLinks";
import useNavigationTree from "./useNavigationTree";
import KeyboardShortcuts from "../../../constants/keyboardShortcuts";

/**
 * The navigation bar for the app.
 */
const NavigationBar = () => {

  const {t} = useTranslation("App");
  const classes = useStyles();

  const navigationTree = useNavigationTree();
  const defaultNavigationState = useMemo(() => ({term: '', stack: []}), []);
  const [navigationState, setNavigationState] = useState(defaultNavigationState);

  const [showDrawer, toggleDrawer] = useState(false);
  const _showDrawer = useCallback(() => toggleDrawer(true), []);
  const hideDrawer = useCallback(() => {
    setNavigationState(defaultNavigationState);
    toggleDrawer(false);
  }, [defaultNavigationState]);

  useKeyboardShortcut({shortcut: KeyboardShortcuts.OpenNavigationDrawer, callback: _showDrawer});

  /**
   * Handles navigation when a quick link is clicked.
   */
  const onQuickLinkNavigation = useCallback((node) => {

    if (node.onClick) {
      // The node refers to a screen in the app.
      // All we need to do is navigate to the screen.
      node.onClick();
      return;
    }

    // Otherwise, we need to set the navigation state to point to the node and
    // open the drawer.

    const hasChildren = node.children?.length > 0;
    // noinspection JSCheckFunctionSignatures
    setNavigationState(prev => ({
      ...prev,
      stack: hasChildren ? [node.id] : [],
    }));

    toggleDrawer(hasChildren);

  }, []);

  return (<HtiNavigationBar>

    <Tooltip
        content={<KeyboardShortcutTooltipContent
            shortcut={KeyboardShortcuts.OpenNavigationDrawer}
        />}
        placement={"right"}
        wrapChildren
    >
      <NavigationBarCrest onClick={_showDrawer}>
        <img
            className={classes.logo}
            src={ImageAssets.PosIconThick.dark}
            alt={'pos'}
        />
      </NavigationBarCrest>
    </Tooltip>

    <NavigationQuickLinks
        tree={navigationTree}
        onQuickLinkClicked={onQuickLinkNavigation}
    />

    <NavigationDrawer
        open={showDrawer}
        onClose={hideDrawer}
        searchPlaceholder={t("App:Navigation.Drawer.Header.SearchPlaceholder")}
        navigationTree={navigationTree}
        navigationState={navigationState}
        onNavigationStateChanged={setNavigationState}
        header={<DrawerHeader/>}
        footer={<DrawerFooter/>}
    />

  </HtiNavigationBar>);

};

const useStyles = makeStyles({
  logo: {width: 42, height: 47},
});

export default NavigationBar;
