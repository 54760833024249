import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for the cost centre redux state.
 */
const CostCentreActionTypes = ActionTypeUtility
.createActionTypes('COST_CENTRES', {
    REQUEST: 'REQUEST',
    FAIL_REQUEST: 'FAIL_REQUEST',
    SET: 'SET',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
  });

export default CostCentreActionTypes;
