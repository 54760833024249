/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {
  CacheLifetime,
  LocalCache,
  LocalCacheRegistry
} from "@hti-ui/local-cache";
import CustomerActionTypes from "../constants/action-types/customerActionTypes";
import HttpManager from "../utils/httpManager";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * Fetches a list of customers. This should only ever be called if the user has
 * HTI access.
 *
 * @param {Object} [args] The request parameters.
 * @param {boolean} [args.force = false] Should cache be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchCustomers = (args) => (dispatch, getState) => {

  const defaultCustomerId = getState().session?.user?.customerId;
  const {customerId = defaultCustomerId, force = false} = args ?? {};
  const url = `customers/list/${customerId}`;
  if (!force && cache.has(url)) {
    return cache.get(url);
  }

  dispatch({type: CustomerActionTypes.REQUEST});
  const request = HttpManager.get(url);

  cache.clear();
  cache.set(url, CacheLifetime.LONG, request);

  return request
  .then(r => {
    dispatch({type: CustomerActionTypes.SET, data: r.data});
    return r;
  })
  .catch(e => {
    cache.delete(url);
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Fetches a single customer entity.
 * @param {number} [args.id=workspace.customer.id] The customer ID.
 * @param {boolean} [args.force=false] Should cached data be invalidated?
 * @return {function(*, *): Promise}
 */
export const fetchCustomer = (args) => (dispatch, getState) => {

  const defaultCustomerId = getState().workspace.customer?.id;
  const {id = defaultCustomerId, force = false} = args ?? {};
  const url = `customers/${id}`;

  if (!force && cache.has(url)) {
    return cache.get(url);
  }

  const request = HttpManager.get(url);
  cache.set(url, CacheLifetime.SHORT, request);

  dispatch({type: CustomerActionTypes.REQUEST});
  return request
  .then(r => {
    dispatch({type: CustomerActionTypes.MERGE, data: [r.data]});
    return r;
  })
  .catch(e => {
    cache.delete(url);
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Updates an existing customer.
 * @param {Object} args The updated customer.
 * @return {function(*,*): Promise}
 */
export const updateCustomer = (args) => (dispatch) => {
  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.put(`customers/${args.id}`, args)
  .then(r => {
    dispatch({type: CustomerActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Captures a base64image for the given customer.
 * @param {Object} args The updated customer.
 * @return {function(*,*): Promise}
 */
export const captureCustomerImage = (args) => (dispatch) => {
  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.put(`customers/image/capture`, args)
  .then(r => {
    dispatch({type: CustomerActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Deletes the image for the given customer.
 *
 * @param {String} args.uri The URI to query an image from cloud storage
 * @return {function(*,*): Promise}
 */
export const deleteCustomerImage = (args) => (dispatch) => {

  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.put(`customers/image/delete`, args)
  .then(r => {
    dispatch({type: CustomerActionTypes.UPDATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Creates a new customer and associates it with the parent customer.
 * @param {Object} args The new customer details.
 * @return {function(*,*): Promise}
 */
export const createAndAssociateCustomer = (args) => (dispatch) => {
  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.post('customers', args)
  .then(r => {
    dispatch({type: CustomerActionTypes.CREATE, data: r.data});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Fetches a single customer's associations.
 * @param {number} [args.customerId] The customer ID.
 * @return {function(*, *): Promise}
 */
export const fetchCustomerAssociations = (args) => (dispatch) => {

  const {customerId} = args ?? {};

  const request = HttpManager.get(`customers/association/${customerId}`);
  dispatch({type: CustomerActionTypes.REQUEST});
  return request
  .then(r => {
    dispatch({type: CustomerActionTypes.COMPLETE_REQUEST});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Fetches the password policy for a customer
 * @param {number} [args.customerId] The customer ID.
 * @return {function(*, *): Promise}
 */
export const fetchCustomerPasswordPolicy = (args) => (dispatch, getState) => {

  const {customerId = getState().workspace.customer?.id} = args ?? {};

  const request = HttpManager.get(`customers/password-policy/${customerId}`);
  dispatch({type: CustomerActionTypes.REQUEST});
  return request
  .then(r => {
    dispatch({type: CustomerActionTypes.COMPLETE_REQUEST});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });

};

/**
 * Updates a customer's password policy.
 * @param {Object} args The updated policy.
 * @return {function(*,*): Promise}
 */
export const updateCustomerPasswordPolicy = (args) => (dispatch) => {
  dispatch({type: CustomerActionTypes.REQUEST});
  return HttpManager.put(`customers/password-policy`, args)
  .then(r => {
    dispatch({type: CustomerActionTypes.COMPLETE_REQUEST});
    return r;
  })
  .catch(e => {
    dispatch({type: CustomerActionTypes.FAIL_REQUEST});
    throw e;
  });
};
