/*
 * Created by Paul Engelke on 06 October 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import TimezoneActionTypes from "../constants/action-types/timezoneActionTypes";

const DEFAULT_STATE = StateUtility.State({}, {async: true, asyncOptions: {useCounter: true}});

/**
 * A redux slice reducer for timezones.
 */
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TimezoneActionTypes.Request:
      return StateUtility.defaultRequest(state);
    case TimezoneActionTypes.FailRequest:
      return StateUtility.defaultFailRequest(state);
    case TimezoneActionTypes.CompleteRequest:
      return StateUtility.defaultCompleteRequest(state);
    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
