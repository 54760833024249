/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {Box} from "@hti-ui/react-web-material";
import PropTypes from 'prop-types';
import GoogleSignInOption from "./GoogleSignInOption";
import CreateAccountButton from "./CreateAccountButton";

/**
 * A form for alternative authentication options for the Login Page.
 */
const AuthOptionForm = props => {

  const {disabled} = props;

  return (<>

    {/* Google Sign-In Option */}
    <Box padding={1}>
      <GoogleSignInOption disabled={disabled}/>
    </Box>

    {/* New Account Option */}
    <Box padding={1}>
      <CreateAccountButton disabled={disabled}/>
    </Box>

  </>);
};

AuthOptionForm.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default AuthOptionForm;
