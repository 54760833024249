/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {ButtonBase, makeStyles} from "@material-ui/core";
import ImageAssets from "../../assets/images";

/**
 * The header component for the Login Page.
 */
const Header = () => {
  const classes = useStyles();
  return (<div className={classes.root}>

    <div>
      <a target={'_blank'} rel={'noopener noreferrer'}
         href={'https://www.htihospitality.tech'}>
        <img
            className={classes.htiLogo}
            src={ImageAssets.HtiIcon}
            alt={'hti-icon'}
        />
      </a>
    </div>

    <div className={classes.headerButtonWrapper}>
      <a className={classes.headerButtonAnchor}
         target={'_blank'} rel={'noopener noreferrer'}
         href={'https://helpdesk.htihospitality.tech/portal/en/kb/hospitality-technology-international/nebulapos'}
      >
        <ButtonBase className={classes.headerButton}>FAQ</ButtonBase>
      </a>
      <a className={classes.headerButtonAnchor}
         target={'_blank'} rel={'noopener noreferrer'}
         href={'https://helpdesk.htihospitality.tech/portal/en/kb/articles/contact-hti-support'}
      >
        <ButtonBase className={classes.headerButton}>Support</ButtonBase>
      </a>
    </div>

  </div>);
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.compliment['50%']}`,
  },
  htiLogo: {height: 40},
  headerButtonWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headerButtonAnchor: {
    height: '100%',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  headerButton: {
    height: '100%',
    borderBottom: '3px solid transparent',
    '&:hover': {
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    fontSize: theme.typography.pxToRem(15),
  },
}));

export default Header;
