/*
 * Created by Paul Engelke on 25 June 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import GlobalStockDeliveryActionTypes
  from "../constants/action-types/globalStockDeliveryActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A reducer for global stock deliveries.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case GlobalStockDeliveryActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case GlobalStockDeliveryActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalStockDeliveryActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case GlobalStockDeliveryActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case GlobalStockDeliveryActionTypes.CREATE:
      return create(state, action);

    case GlobalStockDeliveryActionTypes.UPDATE:
      return update(state, action);

    case GlobalStockDeliveryActionTypes.DELETE:
      return _delete(state, action);

    case GlobalStockDeliveryActionTypes.DELETE_LINE_ITEM:
      return _deleteLineItem(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new global stock delivery to the local set.
 *
 * @param state The current reducer state.
 * @param action The dispatched action.
 * @return {Object} The next state.
 */
const create = (state, action) => {
  const {data} = action;
  const next = [...state.data, data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates a global stock delivery in the local set.
 *
 * @param state The current reducer state.
 * @param action The dispatched action.
 * @return {Object} The next state.
 */
const update = (state, action) => {
  const {data} = action;
  const next = state.data.map(o => (o.id === data.id ? data : o));
  return StateUtility.defaultSet(state, next);
};

/**
 * Deletes a global stock delivery from the local set.
 *
 * @param state The current reducer state.
 * @param action The dispatched action.
 * @return {Object} The next state.
 */
const _delete = (state, action) => {
  const next = state.data.filter(d => d.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Deletes a line item from a locally stored global delivery.
 *
 * @param state The current reducer state.
 * @param action The dispatched action.
 * @return {Object} The next state.
 */
const _deleteLineItem = (state, action) => {
  const next = state.data.map(d => {
    if (d.id === action.data.id) {
      const nextLineItems = d.lineItems
      .filter(li => li.id !== action.data.lineItemId);
      d = {...d, lineItems: nextLineItems};
    }
    return d;
  });
  return StateUtility.defaultSet(state, next);
};
