/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockStoreActionTypes
  from "../constants/action-types/stockStoreActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of stock stores.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockStoreActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockStoreActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockStoreActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case StockStoreActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case StockStoreActionTypes.SET_PRODUCTS:
      return setProducts(state, action);

    case StockStoreActionTypes.CREATE:
      return create(state, action);

    case StockStoreActionTypes.UPDATE:
      return update(state, action);

    case StockStoreActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Sets a store's product list.
 */
const setProducts = (state, action) => {
  const {products, storeId} = action;
  const nextData = state.data.map(s => s.id === storeId ? {...s, products} : s);
  return StateUtility.defaultSet(state, nextData);
};

/**
 * Adds a new stock stores to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock stores in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted stock stores from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
