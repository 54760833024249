/*
 * Created by Paul Engelke on 06 October 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for the timezone redux slice.
 */
const TimezoneActionTypes = ActionTypeUtility.createActionTypes(
    "Timezone",
    {
      Request: "Request",
      FailRequest: "FailRequest",
      CompleteRequest: "CompleteRequest",
    },
);

export default TimezoneActionTypes;
