/*
 * Created by Stanton J Francis on 19 February 2024.
 */

const AppType = {

  WEB_APP: 1,
  MOBILE_APP: 2

};

export {AppType};
