/*
 * Created by Stanton J Francis on 22 August 2024.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the OTP Required Action redux state.
 */
const OTPRequiredActionTypes = ActionTypeUtility
.createActionTypes('OTP_REQUIRED_ACTION', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
});

export default OTPRequiredActionTypes;
