/*
 * Created by Paul Engelke on 06 April 2021.
 */

import {
  CacheLifetime,
  LocalCache,
  LocalCacheRegistry
} from "@hti-ui/local-cache";
import CountryActionTypes from "../constants/action-types/countryActionTypes";
import HttpManager from "../utils/httpManager";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * Fetches a list of countries.
 * @param {boolean} [args.force=false] Should cached data be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchCountries = args => dispatch => {

  const url = 'countries';
  if (!args?.force && cache.has(url)) {
    return cache.get(url);
  }

  const request = HttpManager.get(url);
  cache.set(url, CacheLifetime.VERY_LONG, request);

  dispatch({type: CountryActionTypes.REQUEST});
  return request
  .then(r => {
    dispatch({type: CountryActionTypes.SET, data: r.data});
    return r;
  })
  .catch(e => {
    cache.delete(url);
    dispatch({type: CountryActionTypes.FAIL_REQUEST});
    throw e;
  });

};
