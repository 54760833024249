/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {DashboardOutlined as DashboardIcon} from '@material-ui/icons';
import {useCallback, useMemo} from 'react';
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import RouteNames from "../../../../constants/routeNames";
import useUserAuthorizationCallback from "../../../../hooks/useUserAuthorizationCallback";
import SecurityUtility from "../../../../utils/securityUtility";
import DrawerMenuItem from "../DrawerMenuItem";
import AccountNode from "./accountNode";
import CustomerManagementNode from "./customerManagementNode";
import HtiAdministrationNode from "./htiAdministrationNode";
import InterfaceNode from "./interfaceNode";
import MarketPlaceNode from "./marketPlaceNode";
import ReportNode from "./reportNode";
import SetupNode from "./setupNode";
import StockNode from "./stockNode";
import {useTranslation} from "react-i18next";
import ARMNode from "./armNode";

/**
 * A hook that provides the navigation tree available to the current user.
 */
const useNavigationTree = () => {
  const fullTree = useFullTree();
  const pruneTree = useTreePruner();
  return useMemo(() => pruneTree(fullTree), [fullTree, pruneTree]);
};

export default useNavigationTree;

/**
 * A hook that provides the entire navigation tree.
 */
const useFullTree = () => {

  const history = useHistory();
  const goTo = useCallback((route) => () => history.push(route), [history]);
  const {t} = useTranslation(["glossary", "App", "core"]);

  return useMemo(() => ({
    children: [

      {
        id: RouteNames.DashboardPage,
        label: t("core:Term.Dashboard"),
        onClick: goTo(RouteNames.DashboardPage),
        quickLink: {IconComponent: DashboardIcon},
        renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
      },

      HtiAdministrationNode({goTo, t}),
      CustomerManagementNode({goTo, t}),
      AccountNode({goTo, t}),
      SetupNode({goTo, t}),
      StockNode({goTo, t}),
      ARMNode({goTo, t}),
      ReportNode({goTo, t}),
      InterfaceNode({goTo, t}),
      MarketPlaceNode({goTo, t}),

    ],
  }), [history, goTo, t]);

};

/**
 * Evaluates a navigation tree using security criteria and removes any
 * inaccessible nodes.
 */
const useTreePruner = () => {

  const user = useSelector(state => state.session.user);
  const isUserAuthorized = useUserAuthorizationCallback();

  /**
   * Checks if the user meets the requirements to access the node.
   */
  const isAuthorized = useCallback((node) => {

    const security = node?.security;

    if (security?.requireAdmin && !SecurityUtility.isAdmin(user)) {
      // The user is not in the node's superuser set.
      return false;
    }

    const rights = security?.rights ?? [];
    if (!rights.length) {
      // No user rights are required to access the node.
      return true;
    }

    return isUserAuthorized({rights, all: security?.requireAllRights ?? true});

  }, [user, isUserAuthorized]);

  return useCallback((root) => {

    /**
     * A recursive function for pruning nodes and/or their children where the
     * user cannot access those nodes.
     *
     * @param {Object} node The current node to be evaluated.
     * @return {Object|null} The node, pruned of inaccessible children,
     * or null if the entire node was pruned.
     */
    const evalNode = (node) => {

      const hasRoute = !!node?.onClick;

      const _children = [];
      node?.children?.forEach(c => {
        const proposed = evalNode(c);
        if (proposed) {
          _children.push(proposed);
        }
      });

      return isAuthorized(node) && (hasRoute || _children.length > 0)
          ? {...node, children: _children}
          : null;

    };

    return evalNode(root) ?? {children: []};

  }, [isAuthorized]);

};
