/*
 * Created by Paul Engelke on 11 January 2022.
 */

import {Box, Page, PageContent} from "@hti-ui/react-web-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {useImageAssets} from "../../assets/images";
import AppStatusCode from "../../constants/codes/appStatusCodes";
import {
  ArrowBackRounded as BackToAppIcon,
  ContactSupportRounded as SupportIcon,
  ErrorOutline as ErrorIcon
} from "@material-ui/icons";
import RouteNames from "../../constants/routeNames";

/**
 * A page for app error descriptions.
 */
const ErrorPage = () => {

  const {t} = useTranslation(["ErrorPage", "error"]);
  const ImageAssets = useImageAssets();
  const classes = useStyles();

  const history = useHistory();
  const {error} = useParams();

  const defaultName = t(`error:${AppStatusCode.Unknown}.Name`);
  const errorName = t(`error:${error}.Name`, defaultName);
  const defaultDescription = t(`error:${AppStatusCode.Unknown}.Description`);
  const errorDescription = t(`error:${error}.Description`, defaultDescription);

  const onBackToPosClicked = useCallback(() => {
    history.push(RouteNames.LoginPage);
  }, []);

  const onContactSupportClicked = useCallback(() => {
    window.open(
        "https://helpdesk.htihospitality.tech/portal/en/kb/articles/contact-hti-support",
        "_blank"
    );
  }, []);

  // noinspection JSCheckFunctionSignatures,JSValidateTypes
  return (<Page>

        <PageContent>
          <div className={classes.formRoot}>
            <img
                className={classes.productLogo}
                alt={'nebula-pos-logo'}
                src={ImageAssets.PosLogoHorizontal}
            />

            <Card className={classes.errorRoot}>

              <CardContent>
                <Box leftPadding={1} rightPadding={1} topPadding={1}
                     alignItems={'center'} justifyContent={'center'}>
                  <Box flexDirection={"row"} className={classes.errorHeader}>

                    <Avatar classes={{root: classes.errorAvatar}}>
                      <ErrorIcon/>
                    </Avatar>

                    <Typography
                        className={classes.errorName}>{errorName}</Typography>

                  </Box>

                  <Typography className={classes.errorDescription}>
                    {errorDescription}
                  </Typography>
                </Box>
              </CardContent>

              <CardActions>
                <Box bottomPadding={2} width={'100%'} flexDirection={"row"}
                     alignItems={"center"}
                     justifyContent={"space-evenly"}>
                  <Button variant={"outlined"} onClick={onBackToPosClicked}>
                    <BackToAppIcon/>
                    {t("ErrorPage:Button.NebulaPOS")}
                  </Button>
                  <Button variant={"outlined"}
                          onClick={onContactSupportClicked}>
                    <SupportIcon/>
                    {t("ErrorPage:Button.ContactSupport")}
                  </Button>
                </Box>
              </CardActions>

            </Card>
          </div>
        </PageContent>

      </Page>
  );

};

const useStyles = makeStyles(theme => ({
  productLogo: {
    width: 'calc(100vw / 4)',
    minWidth: 300,
    marginBottom: theme.spacing(3),
  },
  formRoot: {
    height: 'calc(100vh - 100px)',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
  errorRoot: {
    width: "100%",
    maxWidth: 500,
    alignItems: "center",
    justifyContent: "center",
  },
  errorAvatar: {
    backgroundColor: theme.palette.custom.red,
    marginRight: theme.spacing(),
  },
  errorHeader: {
    display: "flex",
    alignItems: "center",
  },
  errorName: {
    ...theme.typography.h6,
  },
  errorDescription: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
}));

export default ErrorPage;
