/*
 * Created by Kevin June 2021.
 */

import {Page, PageContent} from "@hti-ui/react-web-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";
import {CheckRounded as SuccessIcon} from "@material-ui/icons";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useImageAssets} from "../../assets/images";
import RouteNames from "../../constants/routeNames";

/**
 * A page for notifying the user that their account has been activated.
 */
const AccountActivationPage = () =>  {

  const {t} = useTranslation(["common", "AccountActivationPage"]);
  const ImageAssets = useImageAssets();
  const classes = useStyles();
  const history = useHistory();

  const onSignInClicked = useCallback(() => {
    history.push(RouteNames.LoginPage);
  }, []);

  // noinspection JSValidateTypes
  return (<Page>

    <PageContent classes={{wrapper: classes.pageContent}}>

      <img
          className={classes.productLogo}
          alt={'nebula-pos-logo'}
          src={ImageAssets.PosLogoHorizontal}
      />

      <Card className={classes.cardRoot}>

        <CardContent>

          <div className={classes.cardHeader}>

            <Avatar classes={{root: classes.cardAvatar}}>
              <SuccessIcon/>
            </Avatar>

            <Typography className={classes.cardTitle}>
              {t("AccountActivationPage:Title")}
            </Typography>

          </div>

          <Typography className={classes.cardDescription}>
            {t("AccountActivationPage:Description")}
          </Typography>

        </CardContent>

        <CardActions>
          <Button variant={"outlined"} onClick={onSignInClicked}>
            {t("common:Button.SignIn")}
          </Button>
        </CardActions>

      </Card>

    </PageContent>

  </Page>);

};

const useStyles = makeStyles(theme => ({
  pageContent: {
    paddingTop: theme.spacing(5),
    alignItems: "center",
  },
  productLogo: {
    width: "30%",
    minWidth: 300,
    marginBottom: theme.spacing(5),
  },
  cardRoot: {
    width: "100%",
    maxWidth: 500,
  },
  cardAvatar: {
    backgroundColor: theme.palette.custom.green,
    marginRight: theme.spacing(),
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
  },
  cardTitle: {...theme.typography.h5},
  cardDescription: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
}));

export default AccountActivationPage;
