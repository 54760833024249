/*
 * Created by Stanton J Francis on 9 February 2023.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the invoice redux state.
 */
const InvoiceActionTypes = ActionTypeUtility
.createActionTypes('INVOICES', {
  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default InvoiceActionTypes;
