import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import Root from "./components/Root";

import './styles/styles.css';
import 'tippy.js/dist/tippy.css';

import "./localization";

/*
 * Strict mode currently breaks theme injection after the first render.
 * Strict mode is only active in development.
 *
 * Set this value to false to get theme injection to behave correctly during
 * development.
 *
 * Set it to true to get the analytics provided by strict mode.
 */
const useStrictMode = false;

ReactDOM.render(
    useStrictMode
        ? (<StrictMode>
          <Root/>
        </StrictMode>)
        : (<Root/>),
    document.getElementById('root')
);
