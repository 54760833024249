/*
 * Created by Paul Engelke on 08 July 2021.
 */

import {Dialog} from "@hti-ui/react-web-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import PropTypes from "prop-types";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import useEnhancedHistory from "../../../../hooks/useEnhancedHistory";
import useUserAuthorization from "../../../../hooks/useUserAuthorization";

/**
 * A dialog for prompting the user to create their first property.
 */
const CreationDialog = props => {

  const {t} = useTranslation(["App", "common", "glossary"]);
  const {open, onClose, disabled} = props;
  const history = useEnhancedHistory();
  const canCreateProperties =
      useUserAuthorization([UserRight.ManageProperties]);

  /**
   * Takes the user to the property creation wizard.
   */
  const onCreateClicked = useCallback(() => {
    history.push(RouteNames.PropertyCreationWizardPage);
    onClose();
  }, [history, onClose]);

  /**
   * Signs the user out of the app.
   */
  const onSignOutClicked = useCallback(() => history.signOut(), [history]);

  return (<Dialog open={open}>

    <DialogTitle>
      {t("App:Workspace.Dialog.Property.Creation.Title")}
    </DialogTitle>

    <DialogContent>

      <DialogContentText>
        {t("App:Workspace.Dialog.Property.Creation.Description.Part1")}
        <br/>
        {canCreateProperties && <span>
          {t("App:Workspace.Dialog.Property.Creation.Description.Part2")}
        </span>}
        {!canCreateProperties && <span>
        {t("App:Workspace.Dialog.Property.Creation.Description.Part3")}
      </span>}
      </DialogContentText>

    </DialogContent>

    <DialogActions>

      {canCreateProperties
      && <Button
          variant={"outlined"}
          color={"secondary"}
          onClick={onCreateClicked}
          disabled={disabled}
      >{t("common:Button.CreateX", {x: t("glossary:Property")})}</Button>}

      {!canCreateProperties
      && <Button
          variant={"outlined"}
          color={"secondary"}
          onClick={onSignOutClicked}
          disabled={disabled}
      >{t("common:Button.SignOut")}</Button>}

    </DialogActions>

  </Dialog>);

};

CreationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CreationDialog;
