/*
 * Created by Paul Engelke on 23 March 2021.
 */

import {useActions} from "@hti-ui/redux-core";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {autoSignIn} from "../actions/authActions";

/**
 * A set of possible values returned by the {@link useSessionStatus} hook.
 */
export const SessionStatus = Object.freeze({
  Pending: 'Pending',
  Authenticated: 'Authenticated',
  NotAuthenticated: 'NotAuthenticated',
});

/**
 * Checks the user's session status and provides session initialization where
 * there exists a valid JWT token.
 *
 * @return {string}
 */
const useSessionStatus = () => {

  const authenticated = useSelector(state => state.auth.authenticated);
  const [signIn] = useActions([autoSignIn]);

  const initialStatus = authenticated
      ? SessionStatus.Authenticated
      : SessionStatus.Pending;

  const [sessionValid, setSessionValid] = useState(initialStatus);
  useEffect(() => {

    if (!authenticated) {
      signIn()
      .then(() => setSessionValid(SessionStatus.Authenticated))
      .catch(() => setSessionValid(SessionStatus.NotAuthenticated));
    }

  }, [authenticated]);

  return sessionValid;

};

export default useSessionStatus;
