/*
 * Created by Paul Engelke on 01 April 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the product option menu redux state.
 */
const ProductOptionMenuActionTypes = ActionTypeUtility
.createActionTypes('PRODUCT_OPTION_MENUS', {
  REQUEST: 'REQUEST',
  COMPLETE_REQUEST: 'COMPLETE_REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DELETE_OPTION: 'DELETE_OPTION',
});

export default ProductOptionMenuActionTypes;
