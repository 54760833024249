/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for the current user's session redux state.
 */
const SessionActionTypes = ActionTypeUtility.createActionTypes('SESSION', {

  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET_USER: 'SET_USER',
  SET_USER_ROLE: 'SET_USER_ROLE',
  SET_PROPERTY_ACCESS: 'SET_PROPERTY_ACCESS',
  SET_ACTIONABLE_TERMS_AND_CONDITIONS: 'SET_ACTIONABLE_TERMS_AND_CONDITIONS',

});

export default SessionActionTypes;
