/*
 * Created by Kevin May 2021.
 */
import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import GlExportSetupActionTypes
  from "../constants/action-types/glExportSetupActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux reducer for the license state.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case GlExportSetupActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case GlExportSetupActionTypes.REQUEST_WRITE:
      return StateUtility.defaultRequest(state);

    case GlExportSetupActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case GlExportSetupActionTypes.FAIL_REQUEST_WRITE:
      return StateUtility.defaultFailRequest(state);

    case GlExportSetupActionTypes.RECEIVED_DATA:
      return StateUtility.defaultSet(state, action.data || []);
      // return {...state, glSetups: action.data};

    case GlExportSetupActionTypes.SET:
      return StateUtility.defaultSet(state, action);
      // return {...state, glSetups: action}
    case GlExportSetupActionTypes.CREATE:
      return StateUtility.defaultSet(state, [...state.data, action.data]);

    case GlExportSetupActionTypes.UPDATE:
      return StateUtility.defaultSet(
          state,
          state.data.map(ur => ur.id === action.data.id ? action.data : ur)
      );

    case GlExportSetupActionTypes.DELETE:
      return StateUtility.defaultSet(
          state,
          state.data.filter(ur => ur.id !== action.id)
      );

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }
};

