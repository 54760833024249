/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CostCentreActionTypes
  from "../constants/action-types/costCentreActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of cost centres.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CostCentreActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CostCentreActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CostCentreActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case CostCentreActionTypes.CREATE:
      return create(state, action);

    case CostCentreActionTypes.UPDATE:
      return update(state, action);

    case CostCentreActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new cost centre to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing cost centre in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted cost centre from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
