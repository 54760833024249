/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the customer licence prototypes redux
 * state.
 */
const CustomerLicencePrototypeActionTypes = ActionTypeUtility
.createActionTypes('CUSTOMER_LICENCE_PROTOTYPES', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default CustomerLicencePrototypeActionTypes;
