/*
 * Created by Paul Engelke on 22 February 2021.
 */

import {useActions} from "@hti-ui/redux-core";
import {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {signOut} from "../actions/authActions";
import {
  dispatchErrorMessage,
  dispatchInfoMessage,
  dispatchSuccessMessage,
  dispatchWarningMessage
} from "../actions/globalMessengerActions";
import AppStatusCode from "../constants/codes/appStatusCodes";
import HttpStatusCode from "../constants/codes/httpStatusCodes";
import RouteNames from "../constants/routeNames";
import StdError from "../utils/error/stdError";
import HttpManager from "../utils/httpManager";

/**
 * A hook that provides easy access to the global message action creators,
 * as well as a useful error handler.
 *
 * @return {{
 *  handleError: (function(error: *, fallback: *?): void),
 *  dispatchInfoMessage: function(string): void,
 *  dispatchWarningMessage:function(string): void,
 *  dispatchSuccessMessage: function(string): void,
 *  dispatchErrorMessage: function(string): void,
 * }}
 */
const useGlobalMessenger = () => {

  const history = useHistory();
  const {t: translate} = useTranslation("error");

  const [
    dispatchSuccess,
    dispatchInfo,
    dispatchWarning,
    dispatchError,
    _signOut,
  ] = useActions([
    dispatchSuccessMessage,
    dispatchInfoMessage,
    dispatchWarningMessage,
    dispatchErrorMessage,
    signOut,
  ]);

  /**
   * Handles an error by considering some standard error cases. If no standard
   * case is met, the fallback is used.
   * @type {function(*,*): void}
   */
  const handleError = useCallback((e, fallback) => {

    // Wrap the error in the StdError interface.
    e = new StdError(undefined, e);

    if (HttpManager.isCancelled(e)) {
      // These errors should be suppressed as they are the result of aborted
      // requests, whose outcome is no longer of any concern to the application.
      return;
    }

    let errorCode = e.getCode();

    /**
     * Signs the user out and navigates them to the sign-in page.
     */
    const ejectUser = () => {
      _signOut();
      history.push(RouteNames.LoginPage);
      dispatchError(translate("error:Unauthenticated.Description"));
    };

    /**
     * A set of default handlers for errors. These are useful where errors do
     * not conform to standard error structure communicated by the NebulaPOS
     * server.
     */
    const defaultHandlers = {
      [HttpStatusCode.Unauthorized]: ejectUser,
      [AppStatusCode.Unauthenticated]: ejectUser,
    };

    /**
     * Tries to apply the error to the given handler.
     * Handlers can take the form of strings, functions, and maps.
     * @param {string|function|Object} handler
     * @return {boolean}
     */
    const handle = (handler) => {

      if (typeof handler === "string") {
        dispatchError(handler);
        return true;
      } else if (typeof handler === "function") {
        handler(e);
        return true;
      } else if (typeof handler === "object") {
        // The handler is a set of handlers mapped to error codes.
        const _handler = handler[errorCode];
        return !!_handler && handle(_handler);
      }

      return false;

    };

    let handlers = defaultHandlers;
    if (typeof fallback === "object"
        && typeof fallback !== "function") {
      // The fallback is a map of error handlers, and should be merged with
      // the default handlers.
      // This allows default behaviour for specific cases to be overridden and
      // prioritized in the error resolution process.
      handlers = {
        ...defaultHandlers,
        ...fallback,
      };
    }

    if (handle(handlers)) {
      // An explicit handler was provided for the error.
      return;
    }

    if (errorCode !== AppStatusCode.Unknown) {
      // Unknown errors should be handled via a fallback or handlers.default.
      const errorKey = `${errorCode}.Description`;
      const message = translate(`error:${errorKey}`);
      if (message !== errorKey) {
        // An appropriate localized error message was found.
        dispatchError(message);
        return;
      }
    }

    if (handle(handlers.default)) {
      // A default handler was explicitly provided.
      return;
    }

    if (handle(fallback)) {
      // The fallback was able to handle the the error.
      return;
    }

    // We have no idea what this error is at this point.
    // Dispatch a generic localized error message.
    dispatchError(translate("error:Unknown.Description"));

  }, [_signOut, dispatchError, history, translate]);

  return {
    dispatchSuccessMessage: dispatchSuccess,
    dispatchInfoMessage: dispatchInfo,
    dispatchWarningMessage: dispatchWarning,
    dispatchErrorMessage: dispatchError,
    handleError,
  };

};

export default useGlobalMessenger;
