/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CustomerLicencePrototypeActionTypes
  from "../constants/action-types/customerLicencePrototypeActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux reducer for the license state.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CustomerLicencePrototypeActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CustomerLicencePrototypeActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CustomerLicencePrototypeActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
