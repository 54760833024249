/*
 * Created by Paul Engelke on 29 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import CustomerLicenseActionTypes
  from "../constants/action-types/customerLicenceActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux reducer for the customer license state.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case CustomerLicenseActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case CustomerLicenseActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case CustomerLicenseActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case CustomerLicenseActionTypes.UPDATE:
      return update(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Updates an existing licence in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};
