/*
 * Created by Paul Engelke on 07 December 2021.
 */

import AppPreferenceActionTypes
  from "../constants/action-types/appPreferenceActionTypes";
import {LocalStorage} from "../utils/local-storage";

/**
 * Sets the user's preferences for the app.
 * @param {Object} args.preferences The app preference configuration.
 * @param {boolean} [args.persist=false] Should the preferences be remembered?
 * @return {function(*,*): void}
 */
export const setAppPreferences = args => (dispatch, getState) => {
  const {preferences, persist} = args;
  dispatch({type: AppPreferenceActionTypes.SET, preferences});
  if (persist) {
    const other = getState().appPreferences;
    LocalStorage.AppPreferences.set({...other, ...preferences});
  }
};

/**
 * Sets the user's preferred theme for the app.
 * @param {string} args.theme The user's chosen theme.
 * @param {boolean} [args.persist=false] Should the preference be remembered?
 * @return {function(*,*): void}
 */
export const setTheme = args => (dispatch, getState) => {
  const {theme, persist} = args;
  dispatch({type: AppPreferenceActionTypes.SET_THEME, theme});
  if (persist) {
    const preferences = getState().appPreferences;
    LocalStorage.AppPreferences.set({...preferences, theme});
  }
};

/**
 * Sets the user's preferred language for the app.
 * @param {string} args.language The user's chosen language.
 * @param {boolean} [args.persist=false] Should the preference be remembered?
 * @return {function(*,*): void}
 */
export const setLanguage = args => (dispatch, getState) => {
  const {language, persist} = args;
  dispatch({type: AppPreferenceActionTypes.SET_LANGUAGE, language});
  if (persist) {
    const preferences = getState().appPreferences;
    LocalStorage.AppPreferences.set({...preferences, language});
  }
};
