/*
 * Created by Paul Engelke on 16 March 2022.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import UserActionHistoryActionTypes
  from "../constants/action-types/userActionHistoryActionTypes";

const DEFAULT_STATE = StateUtility.State(
    {data: {}},
    {async: true, asyncOptions: {useCounter: true}},
);

/**
 * A redux state reducer for user action history data.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case UserActionHistoryActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case UserActionHistoryActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case UserActionHistoryActionTypes.SET:
      return StateUtility.defaultSet(state, action.data)

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};
