/*
 * Created by Paul Engelke on 24 June 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for global orders.
 */
const GlobalStockOrderActionTypes = ActionTypeUtility
.createActionTypes('GLOBAL_STOCK_ORDERS', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  UPDATE: 'UPDATE',
});

export default GlobalStockOrderActionTypes;
