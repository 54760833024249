/*
 * Created by Stanton J Francis on 4 May 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the print templates redux state.
 */
const PrintTemplateActionTypes = ActionTypeUtility.createActionTypes(
    'PRINT_TEMPLATES', {
      REQUEST: 'REQUEST',
      COMPLETE_REQUEST: 'COMPLETE_REQUEST',
      FAIL_REQUEST: 'FAIL_REQUEST',
      SET: 'SET',
      CREATE: 'CREATE',
      UPDATE: 'UPDATE',
      DELETE: 'DELETE',
    });

export default PrintTemplateActionTypes;
