/*
 * Created by Paul Engelke on 26 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the product group redux state.
 */
const GroupActionTypes = ActionTypeUtility.createActionTypes('GROUPS', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});

export default GroupActionTypes;
