/*
 * Created by Paul Engelke on 02 February 2022.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the forex rate redux state.
 */
const ForexRateActionTypes = ActionTypeUtility
.createActionTypes("FOREX_RATES", {
  REQUEST: "REQUEST",
  FAIL_REQUEST: "FAIL_REQUEST",
  SET: "SET",
  MERGE: "MERGE",
  DELETE: "DELETE",
});

export default ForexRateActionTypes;
