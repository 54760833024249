/*
 * Created by Paul Engelke on 22 Aug 2018.
 */

/**
 * An enumeration of all possible exceptions that occur within the application.
 */
const AppStatusCode = Object.freeze({

  /**
   * The request timed out and was aborted by Axios.
   *
   * This code is as it is given by Axios, do not change its value!
   */
  ConnectionTimeout: "ECONNABORTED",

  /**
   * The request could not be sent for one of many connection related reasons.
   */
  NetworkError: "NetworkError",

  /**
   * The user has not been authenticated or identified.
   */
  Unauthenticated: "Unauthenticated",

  /**
   * The user is not allowed to perform the requested action.
   */
  Unauthorized: "Unauthorized",

  /**
   * The request structure was invalid and thus unreadable.
   */
  MalformedRequest: "MalformedRequest",

  /**
   * One or more arguments passed in a request were invalid.
   */
  BadRequest: "BadRequest",

  /**
   * The target entity of some operation does not exist.
   */
  EntityNotFound: "EntityNotFound",

  /**
   * The target entity exists and the request is in a valid form, but the
   * request cannot be honoured due to the current state of the entity.
   */
  UnprocessableEntity: "UnprocessableEntity",

  /**
   * An attempt was made commit a duplicate user email address.
   */
  DuplicateUserEmailAddress: "DuplicateUserEmailAddress",

  /**
   * An attempt was made to commit a duplicate PIN for a user belonging to the
   * same customer as another user.
   */
  DuplicateUserPin: "DuplicateUserPin",

  /**
   * A user's account has been deactivated, disallowing access to NebulaPOS.
   */
  UserAccountDeactivated: "UserAccountDeactivated",

  /**
   * A customer account has been deactivated. This can happen for various
   * reasons, such as licence expiry or if the customer is no longer with HTI.
   */
  CustomerAccountDeactivated: "CustomerAccountDeactivated",

  /**
   * A user attempted to activate an account with an invalid token.
   */
  InvalidAccountActivationToken: "InvalidAccountActivationToken",

  /**
   * A user attempted to activate their account with an expired activation
   * token.
   */
  ExpiredAccountActivationToken: "ExpiredAccountActivationToken",

  /**
   * Covers any error that has not been accounted.
   */
  Unknown: "Unknown",

  /**
   * An attempt was made to set the invoice number on a stock delivery to a one that already exists
   * for that supplier on another delivery.
   */
  DuplicateStockDeliverySupplierInvoiceNumber: "DuplicateStockDeliverySupplierInvoiceNumber",

  /**
   * An attempt was made to add the same stock product more than once to a stock process. Stock
   * product line items must be unique.
   */
  DuplicateStockProcessLineItem: "DuplicateStockProcessLineItem",

  /**
   * A generic limit restriction exception, which can be communicated where a request would violate
   * the restriction.
   */
  LimitReached: "LimitReached",

});

export default AppStatusCode;
