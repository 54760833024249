/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockDeliveryActionTypes
  from "../constants/action-types/stockDeliveryActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for stock deliveries.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockDeliveryActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockDeliveryActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case StockDeliveryActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockDeliveryActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case StockDeliveryActionTypes.CREATE:
      return create(state, action);

    case StockDeliveryActionTypes.UPDATE:
      return update(state, action);

    case StockDeliveryActionTypes.DELETE:
      return _delete(state, action);

    case StockDeliveryActionTypes.DELETE_LINE_ITEM:
      return deleteLineItem(state, action);

    case StockDeliveryActionTypes.DELETE_LINE_ITEMS:
      return deleteLineItems(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new stock delivery to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing stock delivery in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted stock delivery from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted line item from an existing stock delivery entity.
 */
const deleteLineItem = (state, action) => {
  const {id, lineItemId} = action.data;
  const next = state.data.map(e => (
      e.id === id
          ? {...e, items: e.items?.filter(i => i.id !== lineItemId)}
          : e
  ));
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes all line items from an existing stock delivery entity.
 */
const deleteLineItems = (state, action) => {
  const {id} = action.data;
  const next = state.data.map(e => (e.id === id ? {...e, items: []} : e));
  return StateUtility.defaultSet(state, next);
};
