/*
 * Created by Stanton J Francis on 20 May 2023.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import ArmStatementTemplateActionTypes
  from "../constants/action-types/armStatementTemplateActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A state reducer for managing a list of arm statement templates.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case ArmStatementTemplateActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case ArmStatementTemplateActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case ArmStatementTemplateActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }
}
