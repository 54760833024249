/*
 * Created by Stanton J Francis on 03 November 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import PropertyAdminActionTypes
  from "../constants/action-types/propertyAdminActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of custom menus.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case PropertyAdminActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case PropertyAdminActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case PropertyAdminActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

