import SecurityUtility from "./securityUtility";
import {AppType} from "../constants/appType";
import store from "../store";

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const AuthResponseInterceptor = (axiosInstance) => (error) => {
  const endpointsToSkip = ['authentication', 'authentication/pin'];
  const _axios = axiosInstance;
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry
      && !endpointsToSkip.includes(originalRequest.url)) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({resolve, reject});
      })
      .then((token) => {
        originalRequest.headers["Authorization"] = "Bearer " + token;
        return _axios.request(originalRequest);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(async (resolve, reject) => {
      try {
        const response = await _axios.post("authentication/refresh-token",
            {
              userID: store.getState().session.user.id,
              refreshToken: SecurityUtility.getRefreshToken(),
              loginInfo: {appType: AppType.WEB_APP}
            });

        if (response?.data?.code === 'SessionExpired') {
          isRefreshing = false;
          failedQueue = [];
          alert('Your session has expired. Please sign in again.');
        }

        const accessToken = response?.data?.token;

        SecurityUtility.setToken(accessToken);
        SecurityUtility.setRefreshToken(response?.data?.refreshToken);

        _axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

        processQueue(null, accessToken);
        resolve(_axios(originalRequest));
      } catch (err) {
        processQueue(err, null);
        reject(err);
      } finally {
        isRefreshing = false;
      }
    });
  }

  return Promise.reject(error);
};

export default AuthResponseInterceptor;
