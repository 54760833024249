/*
 * Created by Paul Engelke on 11 November 2022.
 */

import {useTranslation} from "react-i18next";

/**
 * A wrapper for {@link useTranslation}.
 *
 * It changes the name `t` to `translate` to be more descriptive and helps suppress false-positive
 * warnings when using the template function form for `t`.
 *
 * @return {{
 * ready: boolean,
 * translate: function(string,Object?):string,
 * }}
 */
const useLocalization = () => {
  const {t, ready} = useTranslation();
  return {
    translate: t,
    ready: ready,
  };
};

export default useLocalization;
