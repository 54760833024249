/*
 * Created by Paul Engelke on 19 February 2020.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import StockTakeActionTypes
  from "../constants/action-types/stockTakeActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A state reducer for stock-takes.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case StockTakeActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case StockTakeActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case StockTakeActionTypes.COMPLETE_REQUEST:
      return StateUtility.defaultCompleteRequest(state);

    case StockTakeActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case StockTakeActionTypes.SET_ENTRIES:
      return setEntries(state, action);

    case StockTakeActionTypes.CREATE:
      return createDocument(state, action);

    case StockTakeActionTypes.UPDATE:
      return updateDocument(state, action);

    case StockTakeActionTypes.DELETE:
      return deleteDocument(state, action);

    case StockTakeActionTypes.UPDATE_ENTRY:
      return updateEntry(state, action);

    case StockTakeActionTypes.DELETE_ENTRY:
      return deleteEntry(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;
  }

};

/**
 * Sets a stock-take's product entries.
 *
 * @param state The current state.
 * @param action The dispatched action.
 * @return {Object} The new state.
 */
const setEntries = (state, action) => {

  const {stockTakeId, entries} = action;
  const updated = state.data.map(doc => {
    return doc.id === stockTakeId ? {...doc, entries} : doc;
  });

  return StateUtility.defaultSet(state, updated);
};

/**
 * Adds a newly created stock-take to the state.
 *
 * @param state The current state.
 * @param action The dispatched action.
 * @return {Object} The new state.
 */
const createDocument = (state, action) => {
  const {stockTake} = action;
  return StateUtility.defaultSet(state, [...state.data, stockTake]);
};

/**
 * Updates an existing stock-take.
 *
 * @param state The current state.
 * @param action The dispatched action.
 * @return {Object} The new state.
 */
const updateDocument = (state, action) => {
  const {data} = action;
  const newData = state.data
  .map(d => d.id === data.id ? {...d, ...data} : d);
  return StateUtility.defaultSet(state, newData);
};

/**
 * Deletes an existing stock-take from the state.
 *
 * @param state The current state.
 * @param action The dispatched action.
 * @return {Object} The new state.
 */
const deleteDocument = (state, action) => {
  const {stockTakeId} = action;
  return StateUtility
  .defaultSet(state, state.data.filter(d => d.id !== stockTakeId));
};

/**
 * Inserts or replaces an product quantity entry into a stock-take's entries.
 *
 * @param state The current state.
 * @param action The dispatched action.
 * @return {Object} The new state.
 */
const updateEntry = (state, action) => {

  const {entry} = action;
  const {stockTakeId, variantId} = entry;

  const newData = state.data.map((st) => {

    if (st.id === stockTakeId) {
      const {entries} = st;
      const _entries = (entries || []).filter(e => {
        const {variantId: otherVariantId} = e;
        return variantId !== otherVariantId;
      });
      _entries.push(entry);
      return {...st, entries: _entries};
    }

    return st;

  });

  return StateUtility.defaultSet(state, newData);
};

/**
 * Removes an existing product quantity entry from a stock-take.
 *
 * @param state The current state.
 * @param action The dispatched action.
 * @return {Object} The new state.
 */
const deleteEntry = (state, action) => {

  const {stockTakeId, variantId} = action;
  const newData = state.data.map(st => {

    if (st.id === stockTakeId) {
      const {entries} = st;
      const _entries = (entries || []).filter(e => {
        const {variantId: otherVariantId} = e;
        return variantId !== otherVariantId;
      });
      return {...st, entries: _entries};
    }

    return st;

  });

  return StateUtility.defaultSet(state, newData);

};
