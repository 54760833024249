/*
 * Created by Paul Engelke on 14 April 2021.
 */

import {useCallback} from "react";
import {useSelector} from "react-redux";
import SecurityUtility from "../utils/securityUtility";

/**
 * Provides a callback hook that checks if the current user has the necessary
 * authorization to perform some action or access some part of the app.
 * @return {function({rights: Object[], all?: boolean}): boolean}
 */
const useUserAuthorizationCallback = () => {

  const {user, userRole, licence, propertyId} = useSelector(state => ({
    user: state.session.user,
    userRole: state.session.userRole,
    licence: state.workspace.customerLicence,
    propertyId: state.workspace.property?.id,
  }));

  /**
   * Checks if the current user has the necessary authorization to perform
   * some action or access some part of the app.
   *
   * @param {Object[]} args.rights A list of required user rights.
   * @param {all=true} args.all Should every right be required or just at
   * least one?
   * @return {boolean}
   */
  return useCallback((args) => (
      SecurityUtility.isLicensedAndAuthorized({
        user, licence,
        userRole, rights: args.rights, all: args.all,
        propertyId, skipPropertyCheck: !propertyId,
      })
  ), [user, userRole, licence, propertyId]);

};

export default useUserAuthorizationCallback;
