/*
 * Created by Paul Engelke on 18 March 2021.
 */

import {makeStyles} from "@material-ui/core";
import React from 'react';

/**
 * The footer component for the Login Page.
 */
const Footer = () => {
  const classes = useStyles();
  return (<div className={classes.root}>
    <p>
      Copyright © 2017 - All Rights Reserved - <a
        className={classes.anchor}
        target={'_blank'} rel={'noopener noreferrer'}
        href={'https://www.htihospitality.tech/wp-content/uploads/2017/09/HTI_legal_disclaimer.pdf'}
    >Disclaimer</a> - <a
        className={classes.anchor}
        target={'_blank'} rel={'noopener noreferrer'}
        href="https://pos.nebulaone.io"
    >pos.nebulaone.io</a>
    </p>
  </div>);
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 50,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    borderTop: `1px solid ${theme.palette.compliment['50%']}`,
  },
  anchor: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default Footer;
