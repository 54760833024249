/*
 * Created by Paul Engelke on 13 August 2021.
 */

import {AssistantOutlined as HtiManagementIcon} from "@material-ui/icons";
import RouteNames from "../../../../constants/routeNames";
import UserRight from "../../../../constants/security/userRights";
import DrawerMenuItem from "../DrawerMenuItem";

/**
 * Creates a navigation node for the navigation drawer's tree that pertains to
 * functionality that only HTI administrators should have access to.
 */
const HtiAdministrationNode = ({goTo, t}) => ({
  id: "hti/admin",
  label: "HTI",
  quickLink: {IconComponent: HtiManagementIcon},
  renderItem: node => (<DrawerMenuItem key={node.id} node={node}/>),
  security: {requireAdmin: true},
  children: [
    {
      id: RouteNames.CustomerLicencePage,
      label: t("core:Term.CustomerLicence", {count: 2}),
      onClick: goTo(RouteNames.CustomerLicencePage),
      security: {rights: [UserRight.HtiFullAccess]},
    },
  ]
});

export default HtiAdministrationNode;
