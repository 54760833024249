/*
 * Created by Paul Engelke on 31 March 2021.
 */

import {StateUtility} from "@hti-ui/redux-core";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import PrinterActionTypes from "../constants/action-types/printerActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A redux state reducer for managing a list of printers.
 */
export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case PrinterActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case PrinterActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case PrinterActionTypes.SET:
      return StateUtility.defaultSet(state, action.data ?? []);

    case PrinterActionTypes.CREATE:
      return create(state, action);

    case PrinterActionTypes.UPDATE:
      return update(state, action);

    case PrinterActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

/**
 * Adds a new printer to the list.
 */
const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

/**
 * Updates an existing printer in the list.
 */
const update = (state, action) => {
  const updated = action.data;
  const next = state.data.map(e => e.id === updated.id ? updated : e);
  return StateUtility.defaultSet(state, next);
};

/**
 * Removes a deleted printer from the list.
 */
const _delete = (state, action) => {
  const next = state.data.filter(e => e.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
