/*
 * Created by Paul Engelke on 17 March 2021.
 */

import {ActionTypeUtility} from '@hti-ui/redux-core';

/**
 * A set of action types for managing the theme redux state.
 */
const ThemeActionTypes = ActionTypeUtility.createActionTypes('THEME', {
  INIT: 'INIT',
  SET_DARK_MODE: 'SET_DARK_MODE',
});

export default ThemeActionTypes;
