/*
 * Created by Paul Engelke on 20 January 2023.
 */

import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import {useCallback} from "react";
import GoogleSignInButton from "../common/button/GoogleSignInButton";
import {useActions} from "@hti-ui/redux-core";
import {registerWithGoogle} from "../../actions/registrationActions";
import useErrorHandler from "./useErrorHandler";
import RouteNames from "../../constants/routeNames";

/**
 * A button for signing up for NebulaPOS with Google Sign-In.
 */
const GoogleSignInOption = props => {

  const history = useHistory();

  const register = useActions(registerWithGoogle);
  const handlerError = useErrorHandler();

  const onSignInSuccessful = useCallback(args => {
    register(args)
    .then(() => history.push(RouteNames.Root))
    .catch(e => handlerError(e));
  }, [register, history, handlerError]);

  return (<GoogleSignInButton
      textType={"signup_with"}
      onSuccess={onSignInSuccessful}
      disabled={props.disabled}
  />);

};

GoogleSignInOption.propTypes = {
  disabled: PropTypes.bool,
};

export default GoogleSignInOption;
