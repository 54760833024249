/*
 * Created by Paul Engelke on 27 May 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing the subcategory redux state.
 */
const SubcategoryActionTypes = ActionTypeUtility
.createActionTypes("SUBCATEGORIES", {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
});

export default SubcategoryActionTypes;
